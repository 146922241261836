import { types } from '../types/types';

/*
    state
    { 
        uid: jajjajajajsafasd,
        name: 'Pablo'
    }
*/

const initialState = {
    isAuth: false,
}

export const authReducer = ( state = initialState, action ) => {

    switch( action.type ) {
        case types.login:
            return {
                isAuth: true,
                user: action.payload.user
            }
        case types.logout:
            localStorage.removeItem( 'token' );
            return {
                isAuth: false,
            }
        case types.userAuthAddOrder:
            return { 
                ...state,
                user: {
                    ...state.user,
                    order: [...state.user.order, action.payload.order] 
                }                                
            }
        case types.userAuthUpdateOrder:
            return {
                ...state,
                user: {
                    ...state.user,
                    order: state.user.order?.map( order => order.id === action.payload.order.id ? action.payload.order : order )
                }
            }
        
        default:
            return state;
    }
}