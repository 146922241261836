
import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getOrdersFilter, clearOrders } from '../../../actions/orderActions';
import { updateProductSelected } from '../../../actions/productsActions';
import DatePicker, { registerLocale } from 'react-datepicker';

import Select from 'react-select';

import es from "date-fns/locale/es";
import moment from 'moment';

registerLocale("es", es);

export const EstadisticaProdu = () => {

    const dispatch = useDispatch();

    const { orders } = useSelector( state => state.orders );

    const [ Filter, setFilter ] = useState('');
    const [ ordersFilter, setOrdersFilter ] = useState([]);
    const [ sortUpProduct, setSortUpProduct] = useState(true);
    const [ sortUpCliente, setSortUpCliente] = useState(true);
    
    //const [ total, setTotal ] = useState(0);
    let total = 0;
    let totalPrice = 0;

    const [ startDateProduct, setStartDateProduct ] = useState( new Date('01/01/2022') );
    const [ endDateProduct, setEndDateProduct ] = useState( new Date() );
    
    const { products } = useSelector( state => state.products );
    const { productSelect: product } = useSelector( state => state.products );

    useEffect(() => {
        return () => {
            dispatch( clearOrders() );
            dispatch( updateProductSelected( null ));
        };
    }, [ dispatch ]);

    //filtros
    useEffect(() => { 
        if(product){
            // 1 para que filtre por productos
            dispatch( getOrdersFilter({ id: product.id, datestart: startDateProduct, dateend: endDateProduct, type: '1' }) );
             setSortUpProduct(true);           
        }
    }, [ startDateProduct, endDateProduct, product, dispatch]);

    useEffect(() => { 

        setOrdersFilter( [...orders.filter(
                        order => ( order.user?.nameMarkets.toLowerCase().includes(Filter?.toLowerCase()) )
                        )]);        

    }, [ Filter, orders, setOrdersFilter, dispatch ]);

    const handleChange = ({ target }) => {
        setFilter(target.value);
    }

    const calculaTotal = () => {
        total = ordersFilter?.map(( p ) => p.productOrder?.find( e => e.productId === product?.id)?.quantity)
                                    .reduce((p, c) => p + c, 0) || 0;   
        totalPrice = ordersFilter?.map(( p ) => p.productOrder?.find( e => e.productId === product?.id)?.price)
        .reduce((p, c) => p + c, 0) || 0;
    }

    calculaTotal();

    const handleSelectProducts = (e) => { 
        const p = products.filter( p => p.id === e )[0];
        dispatch( updateProductSelected( p ) );
        setSortUpProduct(true);    
    }

    const handleSelectDateInit = (date) => {
        if( moment(date).isAfter( endDateProduct )){
            setStartDateProduct( moment(endDateProduct).startOf('month').toDate() );
            setEndDateProduct( moment(date).endOf('month').toDate() );
        } else {
            setStartDateProduct(moment(date).startOf('month').toDate());
        }
    }

    const handleSelectDateEnd = (date) => {
        if( moment(date).isBefore( startDateProduct )){
            setStartDateProduct( moment(date).startOf('month').toDate() );
            setEndDateProduct( moment(startDateProduct).endOf('month').toDate() );
        } else {
            setEndDateProduct(moment(date).endOf('month').toDate());
        }    
    }

    // función que ordena la table por cantidad
    const handleSortProduct = () => {
        setSortUpProduct(!sortUpProduct);
        if( !sortUpProduct ){
            setOrdersFilter( ordersFilter.sort( (a, b) => { 
                if( a.productOrder[0].quantity > b.productOrder[0].quantity ) {
                    return -1;
                }
                return 1;
            }));
        } else {
            setOrdersFilter( ordersFilter.sort( (a, b) => { 
                if( a.productOrder[0].quantity < b.productOrder[0].quantity ) {
                    return -1;
                }
                return 1;
            }));
        }
    }

    // función que ordena la table por cliente
    const handleSortCliente = () => {
        setSortUpCliente(!sortUpCliente);
        if( !sortUpCliente ){
            setOrdersFilter( ordersFilter.sort( (a, b) => { 
                if( a.user?.nameMarkets > b.user?.nameMarkets ) {
                    return -1;
                }
                return 1;
            }));
        } else {
            setOrdersFilter( ordersFilter.sort( (a, b) => { 
                if( a.user?.nameMarkets < b.user?.nameMarkets ) {
                    return -1;
                }
                return 1;
            }));
        }
    }

    const options =products.map( u => ({ label: u.name, value: u.id }));

  return (
    <>
        <div className='w75 d-flex align-items-center mb-3 mt-4 ms-1'>
            <h6>Cantidad vendida: </h6>
            { ( total !== 0 ) &&
                (<h5 className="estadisticas__text-total ms-2">{ total }</h5>)
            }
        </div>
        <div className="d-flex justify-content-center">

            <div className='w75'>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1"><i className='fas fa-search'></i></InputGroup.Text>
                    <FormControl
                        placeholder="Buscar...."
                        aria-label="Search"
                        aria-describedby="basic-addon1"
                        onChange={ handleChange }
                    />
                </InputGroup>
            </div>
        </div>
        <div className="offset-md-3 col-md-6">
            <Select options={options} 
                    onChange={ (e) => handleSelectProducts( e.value ) }
                    placeholder="Seleccione Cliente" />
            {/* <h6 className='estadisticas__text-clientes me-4'>Listado de Clientes</h6> */}
            {/* <DropdownButton className='estadisticas__cbo-clientes' title={ `${ product?.name || 'Seleccione Producto' }` } onSelect={ (e) => handleSelectProducts( e ) }>
                {
                    products?.map( (p, i) => {
                        return <div key={i}>
                                    <Dropdown.Item eventKey={ p.id } value>{ p.name }</Dropdown.Item>
                                </div>
                    })
                }
            </DropdownButton> */}
            </div>
            <div className="d-flex justify-content-center mt-3">
            <div className="row mb-3">
                <div className="col-md-6">                                
                    <DatePicker         
                        showPopperArrow={true}                          
                        locale = {es}                    
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        selected={startDateProduct} 
                        onChange={(date) => handleSelectDateInit(date)} />
                </div>
                <div className="col-md-6">
                    <DatePicker 
                        showPopperArrow={true}                                          
                        locale = {es}                    
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        selected={endDateProduct} 
                        onChange={(date) => handleSelectDateEnd(date)} />                                
                </div>
            </div>
        </div>
        <div>
            <Table striped bordered={false} hover>
                <thead>
                    <tr className="components__table-header">
                        <th>Nº de Orden</th>
                        <th className="w50">
                            Cliente
                            {
                                ( sortUpCliente )
                                ? <i className='fas fa-arrow-up ms-2 pointer' onClick={ handleSortCliente }></i>
                                : <i className='fas fa-arrow-down ms-2 pointer' onClick={ handleSortCliente }></i>
                            }
                        </th>
                        <th>
                            Cantidad
                            {
                                ( sortUpProduct )
                                ? <i className='fas fa-arrow-up ms-2 pointer' onClick={ handleSortProduct }></i>
                                : <i className='fas fa-arrow-down ms-2 pointer' onClick={ handleSortProduct }></i>
                            }
                        </th>
                        <th>Precio Total</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ordersFilter.map( (order, i) => { 
                            return <tr key={i}>
                                        <td>{ order.id }</td>
                                        <td>{ order.user.nameMarkets }</td>
                                        <td>{ order.productOrder?.find( e => e.productId === product?.id)?.quantity }</td>
                                        <td className='text-end pe-5'>{ order.productOrder?.find( e => e.productId === product?.id)?.price?.toLocaleString('es-Ar',{ minimumFractionDigits: 2, maximumFractionDigits: 2}) }</td>                                        
                                    </tr>
                        })
                    }
                    { (totalPrice > 0 ) && 
                        (<tr className="estadisticas__totales-table">
                            <td></td>
                            <td>TOTALES</td>
                            <td>{ total }</td>
                            <td className='text-end pe-5'>{ totalPrice.toLocaleString('es-Ar',{ minimumFractionDigits: 2, maximumFractionDigits: 2}) }</td>
                        </tr>)
                    }
                </tbody>
            </Table>    
        </div>
    </>
  )
}
