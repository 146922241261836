
export const types = {

    // login
    login: '[auth] Login',
    logout: '[auth] Logout',
    registerError: '[auth] Register Error',
    userAuthAddOrder: '[auth] User Auth Add Order',
    userAuthUpdateOrder: '[auth] User Auth Update Order',

    // ui
    uiStartLoading: '[ui] Start Loading',
    uiFinishLoading: '[ui] Finish Loading',
    uiSetError: '[ui] Set Error',
    uiRemoveError: '[ui] Remove Error',

    // users
    usersGetAll: '[users] Get All',
    userUpdate: '[users] Update',
    userUpdateStatus: '[users] Update Status',
    userUpdateStatusAll: '[users] Update Status All',
    userUpdateSelect: '[users] Update Select',
    userDelete: '[users] Delete',
    userDeleteWsp: '[users] Delete Wsp',
    userClear: '[users] Clear',
    userAddOrder: '[users] Add Order',

    // products
    productsGetAll: '[products] Get All',
    // productGet: '[products] Get',
    productsAdd: '[products] Add',
    productsUpdate: '[products] Update',
    productsDelete: '[products] Delete',
    productsStatus: '[products] Status',
    productsUpdateSelected: '[products] Update Selected',
    productsClear: '[products] Clear',
    productPrint: '[product] Print',

    // photos
    photoDelete: '[photos] Delete',

    // carts
    cartAddProduct: '[carts] Add Product',
    cartSubProduct: '[carts] Sub Product',
    cartRemoveProduct: '[carts] Remove Product',
    cartClear: '[carts] Clear',
    cartUpdateProduct: '[carts] Update Product',

    // orders
    ordersGetAll: '[orders] Get All',
    ordersUpdateState: '[orders] Update State',
    ordersUpdateStateAll: '[orders] Update State All',
    ordersSelectUpdate: '[orders] Select Update',
    orderDelete: '[orders] Delete',
    ordersClear: '[orders] Clear',
    ordersPrint: '[orders] Print',
    // orders in map
    orderInMap : '[orders] add in map',
    orderInMapClear : '[orders] clear one in map',
    ordersInMapClear : '[orders] clear all in map',

    // categories
    categoriesGetAll: '[categories] Get All',
    categoriesAdd: '[categories] Add',
    categoryUpdateSelected: '[categories] Update Selected',
    categoriesUpdate: '[categories] Update',

    // dolar
    updateDolarCustom: '[dolar] Update Dolar Custom',
    updateDolarNacion: '[dolar] Update Dolar Nacion',
}