import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { InputGroup, FormControl, Button } from 'react-bootstrap'
import { useForm } from '../hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { startLogin } from '../actions/authActions';

const initialValues = {
    email: '',
    password: ''
}

export const LoginScreen = () => {

    const { isLoading } =  useSelector( state => state.ui );

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [values, handleInputChange,] = useForm( initialValues );

    const { email, password } = values;

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch( startLogin( email.trim(), password.trim(), navigate ) ).then(
            () => clearCacheData
        );
    }

    const clearCacheData = () => {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });        
      };

  return (
      <div className="auth__container">
          <div className="auth__box-content col-md-7 col-lg-5 col-10">
              <div className="auth_login-form">
                  <h3 className="auth__title">Ingresar</h3>
                  <hr className="auth__divisor"/>
                  <form className="auth__form">
                      
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                                <i className="fa-solid fa-at auth__input-icon"></i>
                            </InputGroup.Text>
                            <FormControl
                            placeholder="Correo"
                            aria-label="Correo"
                            aria-describedby="basic-addon1"
                            name="email"
                            value={email}
                            onChange={handleInputChange}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon2">
                                <i className="fa-solid fa-shield-halved auth__input-icon"></i>
                            </InputGroup.Text>
                            <FormControl
                            placeholder="Password"
                            type="password"
                            aria-label="Password"
                            aria-describedby="basic-addon2"
                            name="password"
                            value={password}
                            onChange={handleInputChange}
                            />
                        </InputGroup>
                        <Button 
                            variant="primary" 
                            type="submit" 
                            onClick={ handleSubmit }
                            disabled={ isLoading }>
                            Acceder
                        </Button>
                            <small className="d-block mt-2 mb-2">¿Aún no tienes cuenta?</small>
                            <Link className="btn btn-primary mb-2" to="/auth/register">Click para registrarte</Link>
                            <br/>
                            <Link className="mb-2" to="/auth/forget-password">Olvidaste tu password</Link>
                  </form>
              </div>
          </div>
      </div>
  )
}
