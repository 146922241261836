import React, { useState } from 'react'
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import validator from 'validator';
import { forgetPassword } from '../actions/authActions';
import { removeError, setError } from '../actions/uiActions';

export const ForgetPassword = () => {

    const [email, setEmail] = useState('');
    const { error, isLoading } = useSelector( state => state.ui );
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleInputChange = ( { target } ) => {
        setEmail(target.value);
    }

    const handleSubmit = () => {
        if( !validator.isEmail(email) ){
            dispatch(setError('No es un email válido')); 
            return;
        } else {
            dispatch(removeError());
        }
        dispatch( forgetPassword( email )).then(()=> {
            navigate('/auth/login');
        });
    }

  return (
    <div className="auth__forget-container">
        <div className="auth__forget-content">
        <div className="auth__forget-title">
            <p>Fijamom</p>
        </div>
        <p>Si Olvido su contraseña, ingrese su email y aprete el botón</p>
            {
                    error && 
                    (<div className='auth__alert-error'>
                        { error }
                    </div>)
                }
            <form className="auth__form">
                      
                    <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                              <i className="fa-solid fa-at auth__input-icon"></i>
                          </InputGroup.Text>
                          <FormControl
                          placeholder="Correo"
                          aria-label="Correo"
                          aria-describedby="basic-addon1"
                          name="email"
                          value={email}
                          onChange={handleInputChange}
                          />
                    </InputGroup>
                    <Button 
                            className="mb-3 w-100"
                            variant="primary" 
                            onClick={ handleSubmit }
                            disabled={ isLoading }>
                            Enviar Email
                    </Button><br/>
                    <div className="row">
                        <div className="col-md-6">
                            <Link to="/auth/register">Click para registrarte</Link>
                        </div>
                        <div className="col-md-6 text-end">
                            <Link to="/auth/login">Click para login</Link>  
                        </div>
                    </div>
            </form>
        </div>  
    </div>
  )
}
