import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup, Table } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersFilter, clearOrders } from '../../../actions/orderActions';

import es from "date-fns/locale/es";
import moment from 'moment';
registerLocale("es", es);

export const EstadisticaTodos = () => {

    const dispatch = useDispatch();
    let total = 0;
    let totalPrice = 0.0;

    const { orders } = useSelector( state => state.orders );

    const [ Filter, setFilter ] = useState('');
    const [ ordersFilter, setOrdersFilter ] = useState([]);
    const [ sortUp, setSortUp] = useState(true);  
    const [ sortUpCliente, setSortUpCliente] = useState(true);

    const [ startDateUser, setStartDateUser ] = useState( new Date('01/01/2022') );
    const [ endDateUser, setEndDateUser ] = useState( new Date() );

    //const { productSelect: product } = useSelector( state => state.products );

    useEffect(() => {
        return () => {
            dispatch( clearOrders() );
        };
    }, [ dispatch ]);

    useEffect(() => { 

        setOrdersFilter( [...orders.filter(
                        order => ( order.productOrder.find(p => p.product.name.toLowerCase().includes(Filter?.toLowerCase()) ))
                        )]);        

    }, [ Filter, orders, setOrdersFilter, dispatch ]);

    useEffect(() => {       
        // 0 para que filtre por clientes
        dispatch( getOrdersFilter({ datestart: startDateUser, dateend: endDateUser, type:'2' }) );

    } , [ startDateUser, endDateUser, dispatch ]);

    const handleChange = ({ target }) => {
        setFilter(target.value);
    }

    const calculaTotal = () => {
        total = ordersFilter?.map(
            ( p ) => p.productOrder?.map( e => e.quantity).reduce((p, c) => p + c, 0))
            .reduce( (p, c) => p + c, 0);      
        totalPrice = ordersFilter?.map(
            ( p ) => p.productOrder?.map( e => e.price).reduce((p, c) => p + c, 0))
            .reduce( (p, c) => p + c, 0);      
    }

    calculaTotal();

    const handleSelectDateInit = (date) => {
        if( moment(date).isAfter( endDateUser )){
            setStartDateUser( moment(endDateUser).startOf('month').toDate() );
            setEndDateUser( moment(date).endOf('month').toDate() );
        } else {
            setStartDateUser(moment(date).startOf('month').toDate());
        }
    }

    const handleSelectDateEnd = (date) => {
        if( moment(date).isBefore( startDateUser )){
            setStartDateUser( moment(date).startOf('month').toDate() );
            setEndDateUser( moment(startDateUser).endOf('month').toDate() );
        } else {
            setEndDateUser(moment(date).endOf('month').toDate());
        }
    }

    const handleSort = () => {  
        setSortUp(!sortUp);
        let produ = ordersFilter.productOrder;
        if( !sortUp ){
            orders.map( order => {
                produ = order.productOrder.sort((a, b) => {
                if (a.quantity > b.quantity) {
                    return -1;
                }
                return 1
                });     
                return produ;
            });   
        } else {
            orders.map( order => {
                produ = order.productOrder.sort((a, b) => {
                if (a.quantity < b.quantity) {
                    return -1;
                }
                return 1
                });  
                return produ;
            });
        }        
        const ord = [ ...ordersFilter ];
        ord[0].productOrder = produ;
        setOrdersFilter( ord );
    }

     // función que ordena la table por cliente
     const handleSortCliente = () => {
        setSortUpCliente(!sortUpCliente);
        if( !sortUpCliente ){
            setOrdersFilter( ordersFilter.sort( (a, b) => { 
                if( a.user?.nameMarkets > b.user?.nameMarkets ) {
                    return -1;
                }
                return 1;
            }));
        } else {
            setOrdersFilter( ordersFilter.sort( (a, b) => { 
                if( a.user?.nameMarkets < b.user?.nameMarkets ) {
                    return -1;
                }
                return 1;
            }));
        }
    }

    const calcularCantidad =( productOrder ) => {
        let cantidad = 0;
        for( let po of productOrder ){
            cantidad += po.quantity;
        }
        return cantidad;
    }

    const calcularTotales =( productOrder ) => {
        let cantidad = 0;
        for( let po of productOrder ){
            cantidad += po.price;
        }
        return cantidad;
    }


  return (
    <>
        <div className='w75'>
            <br/>
            <h5 className="ms-3">Cantidad Vendida: { total || '' }</h5>
            <h5 className="ms-3">Total Vendido: { totalPrice?.toLocaleString('es-Ar', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }</h5>
            <br/>
        </div>
        <div className="d-flex justify-content-center">
            <div className='w75'>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1"><i className='fas fa-search'></i></InputGroup.Text>
                    <FormControl
                        placeholder="Buscar...."
                        aria-label="Search"
                        aria-describedby="basic-addon1"
                        onChange={ handleChange }
                    />
                </InputGroup>
            </div>
        </div>

        <div className="d-flex justify-content-center mt-3">
            <div className="row mb-3">
                <div className="col-md-6">                                
                    <DatePicker         
                        showPopperArrow={true}                          
                        locale = {es}                    
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        selected={startDateUser} 
                        onChange={(date) => handleSelectDateInit( date )} />
                </div>
                <div className="col-md-6">
                    <DatePicker 
                        showPopperArrow={true}                                          
                        locale = {es}                    
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        selected={endDateUser} 
                        onChange={(date) => handleSelectDateEnd(moment(date).add(1, 'months').subtract(1, 'days').toDate())} />                                
                </div>
            </div>
        </div>
        <div>
            <Table striped bordered={false} hover>
                <thead>
                    <tr className="components__table-header">
                        <th>Cliente
                            {
                                ( sortUpCliente )
                                ? <i className='fas fa-arrow-up ms-2 pointer' onClick={ handleSortCliente }></i>
                                : <i className='fas fa-arrow-down ms-2 pointer' onClick={ handleSortCliente }></i>
                            } 
                        </th>
                        <th>Pedido
                            {
                                ( sortUp )
                                ? <i className='fas fa-arrow-up ms-2 pointer' onClick={ handleSort }></i>
                                : <i className='fas fa-arrow-down ms-2 pointer' onClick={ handleSort }></i>
                            }     
                        </th>
                        {/* <th>Mes - Año</th> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        ordersFilter.map( (order, i) => { 
                        return (
                        <tr key={i}>
                            <td>{ order.user?.nameMarkets }</td>
                            <td>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th className='w75'>Producto</th>
                                            <th>
                                                Cantidad                                                                           
                                            </th>
                                            <th>Precio Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {   order.productOrder?.map( (p, i ) => {
                                                return ( 
                                                    <tr key={i}>

                                                        <td>{ p.product.name }</td>
                                                        <td>{ p.quantity }</td>
                                                        <td className='text-end pe-5'>{ p.price?.toLocaleString('es-Ar', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        <tr className="estadisticas__totales-table">
                                            <td>Totales</td>
                                            <td>{ calcularCantidad(order.productOrder) }</td>
                                            <td>{ calcularTotales(order.productOrder)?.toLocaleString('es-Ar', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </td>                                    
                        </tr>)
                        })
                    }
                </tbody>
            </Table>    
        </div>
    </>
  )
}
