

import React, { useEffect, useMemo, useState } from 'react';
import { DropdownButton, Table, Dropdown, InputGroup, FormControl, Form, Button } from 'react-bootstrap';
import * as moment from 'moment';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import es from "date-fns/locale/es"; // the locale you want
import { useDispatch, useSelector } from 'react-redux';
import { updateOrderSelect, ordersPrintStore, clearOneOrdersInMap, deleteOrder } from '../../../actions/orderActions';
import { OrderDetail } from './OrderDetail';
import { useNavigate } from 'react-router-dom';
import { OrderSelects } from './OrderSelects';
import { OrderTicket } from './OrderTicket';

import Swal from 'sweetalert2';
import { setProductPrint } from '../../../actions/productsActions';


registerLocale("es", es);

export const OrderList = React.memo(({ orders, handleSelectListado, handleSelectListados, status }) => {    

    const currentDate = useMemo(() => new Date(), []);
    const [startDate, setStartDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
    const [endDate, setEndDate] = useState(currentDate);

    useEffect(() => {
        let firstDayOfMonth;
        if (status !== 'TODOS' ) {
          firstDayOfMonth = new Date("01/01/2022");
        } else {
          firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        }
        setStartDate(firstDayOfMonth);
        }, [ status, currentDate ]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector( state => state.auth );

    const [ filter, setFilter ] = useState('');    
    const [ ordersFilter, setOrdersFilter ] = useState([]);
    const [ print, setPrint ] = useState(false);
    const [ printTicket, setPrintTicket ] = useState(false);

    // const [ pdfViewStatus, setPdfViewStatus ] = useState(false);

    const [ statusSum, setStatusSum ] = useState(0);

    const [ selected, setSelected ] = useState([]);
    const [ tipoEnvio, setTipoEnvio ] = useState('');

    const [showDetailOrder, setShowDetailOrder] = useState(false);
    const [showDetailTicket, setShowDetailTicket] = useState(false);
    // const [showPrintOrders, setShowPrintOrders] = useState(false);
    const [ showSelectOrders, setShowSelectOrders ] = useState(false);

    // Cierra el Print
    // const handleShowPrintClose = () => { 
    //     setSelected([]);
    //     dispatch( ordersPrintStore([]));
    //     setShowPrintOrders(false);                
    // }
    // const { orderSelect: order } = useSelector( state => state.orders ); 

    // Cuando cierra el modal de detalle
    const handleShowDetailClose = () => { 
        dispatch( updateOrderSelect( null ));
        setPrint(false);
        setShowDetailOrder(false);
        setTipoEnvio('');
    }

    // Cuando cierra el modal del ticket cliente del interior
    const handleShowTicketClose = () => {
        dispatch( updateOrderSelect( null ));
        setPrintTicket(false);
        setShowDetailTicket(false);
        handleSelectEnvio('1');
        // setTipoEnvio('');
    }

    // Cuando cierra el modal de seleccion multiple
    const handleShowSelectOrdersClose = () => { 
        setShowSelectOrders(false);
        setSelected([]);
        setTipoEnvio('');
    }

    // Cuando muestra el modal de detalle
    const handleShowDetail = ( order ) => { 
        dispatch( updateOrderSelect( order ));
        setShowDetailOrder(true);       
        // setPdfViewStatus(true);
    }

    const handleShowSelectOrders = () => {
        setShowSelectOrders(true);
    }

    // Mandar a imprimir pedido
    const handlePrint = (order) => { 
        dispatch( updateOrderSelect( order ));
        setShowDetailOrder(true);
        setPrint(true);
    }

    // Imprimir el ticket de clientes del interior
    const handlePrintTicket = ( order ) => {        
        dispatch( updateOrderSelect( order ));
        setShowDetailTicket(true);
        setPrintTicket(true);
        setTipoEnvio('');
    }

    // Fitrla por campo de búsqueda y fecha
    useEffect(() => {
        if( status === 'MAPA') {
            setOrdersFilter( orders );
        }else {
            setOrdersFilter( [...orders.filter(
                        order => ( order.user?.nameMarkets?.toLowerCase().includes(filter?.toLowerCase()) && 
                                   moment(order.createdAt).isBetween(startDate, endDate, 'day', '[]')  )
                        )] );
        }
    },[ filter, orders, setOrdersFilter, endDate, startDate, status ]);


    useEffect(() => { 
        handleSumSales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ ordersFilter ]);

    const handleChange = (e) => { 
        setFilter( e.target.value );
    }
    
    const handleSumSales = () => {
        let sum = 0;
        ordersFilter.map( order => sum += order.priceTotal );
        setStatusSum( sum );
    }

    const handleWhatsapp = ( order ) => {
        
        const num = order.user.whatsapp[0].num;
        const text = `Hola, tu pedido ${ String(order.id).padStart(6, '0') } fue ENVIADO
        %0A*Pedido*%0A
        %0A${ order.productOrder.map( product => `${product.quantity} x ${product.product.name} - $${ product.price }` ).join('%0A') }
        %0A*Precio*%0A
        $${ order.priceTotal }    
        `;

        window.open(`https://api.whatsapp.com/send?phone=+549${ num }&text= ${ text }`, '_blank');
    }

    const handleSelectEnvio = (e) => {
        setTipoEnvio(e);
        if( e === '0' ) {
            setOrdersFilter( [...orders.filter( o => o.user.send.length === 0 )] );     
        } else {
            setOrdersFilter( [...orders.filter( o => o.user.send.length > 0 )] );
        }
    }

    const handleSelect = ({ target }, order) => {
        
        if( target.checked) {
            setSelected([...selected, order]);
        } else {
            setSelected(selected.filter( o => o.id !== order.id ));
        }
    }

    const handleImprimirAll = () => { 
        if( status !== 'MAPA') { 
            dispatch( ordersPrintStore(selected) );
        } else {
            dispatch( ordersPrintStore(ordersFilter) );
        }
        //setShowPrintOrders( !showDetailOrder );
        navigate('/orders-print', { state: { status: true }});
    }

    const handleImprimirProductAll = () => {

        let produInAllOrder = ordersFilter.map( order => order.productOrder );
        produInAllOrder = produInAllOrder.flat();
        
        let produAImprimir = [];

        for( let p of produInAllOrder ) {
            let produTemp = produAImprimir.find(pro => pro.product.id === p.product.id);
            if ( produTemp ) {
                produTemp.quantity += p.quantity;
            } else {
                produAImprimir.push( {...p} );
            }
        }
        
        dispatch( setProductPrint( produAImprimir ) );
        
        //setShowPrintOrders( !showDetailOrder );
        navigate('/product-print', { state: { status: true }});
    }

    const handleSends = () => {
        if( status !== 'MAPA') { 
            handleSelectListados('ENVIADO', selected);        
        } else {
            handleSelectListados('ENVIADO', ordersFilter);
        }
    }

    const handleDelete = ( order ) => {
        Swal.fire({
            title: 'Eliminar',
            text: `Seguro desea eliminar el pedido Nº${ order.id }`,
            icon: 'question',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar',
        }).then( resp => {
            if(resp.isConfirmed){
                dispatch( deleteOrder(Number(order.id)) );
            }
        })
    }

    // Saca el id de OrdersInMapa
    const handleSacarSeleccionado = (id) => {
        dispatch( clearOneOrdersInMap(id) );
    }

    return (
        <>
            {/* {(pdfViewStatus) ?
                (<PDFViewer style={{ width: '100%', height: '90vh'}}> 
                    <OrderDetailPdf order={ order } /> 
                </PDFViewer>)
                :
                <div> */}
            <div className='no-print'>
                {( status !== 'MAPA' ) && (
                    <div className="d-flex justify-content-center">
                        <div className='w75'>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"><i className='fas fa-search'></i></InputGroup.Text>
                                <FormControl
                                    placeholder="Buscar...."
                                    aria-label="Search"
                                    aria-describedby="basic-addon1"
                                    onChange={ handleChange }
                                />
                            </InputGroup>
                        </div>
                    </div>
                )}
                {
                    ( status === 'MAPA' ) && (
                        <div className='row justify-content-between ms-2 me-2'>
                            <div className='col-md-2'>
                                Pedidos a enviados
                                <div className="components__small-box pointer ml-3 d-flex justify-content-center align-items-center" 
                                    onClick={ handleSends }>
                                    <i className="fas fa-file-import fa-3x"></i>
                                </div>


                            </div>
                            <div className="col-md-2">
                                <div className="row components__small-box pointer ms-3 d-flex justify-content-center align-items-center" onClick={handleImprimirAll}>
                                    <i className="fas fa-print fa-3x"></i>                                
                                </div>
                                <div className="row components__small-box pointer ms-3 d-flex justify-content-center align-items-center" onClick={handleImprimirProductAll}>
                                    <i className="fab fa-product-hunt fa-3x"></i>                                
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    (status === 'TODOS' ) && (
                        <div className="d-flex justify-content-center">
                            <div className="row mb-3">
                                <div className="col-md-6">                                
                                    <DatePicker         
                                        showPopperArrow={true}                          
                                        locale = {es}                    
                                        dateFormat="dd MMMM yyyy"
                                        selected={startDate} 
                                        onChange={(date) => setStartDate(date)} />
                                </div>
                                <div className="col-md-6">
                                    <DatePicker 
                                        showPopperArrow={true}                                          
                                        locale = {es}                    
                                        dateFormat="dd MMMM yyyy"
                                        selected={endDate} 
                                        onChange={(date) => setEndDate(date)} />                                
                                </div>
                            </div>
                        </div>
                    )
                }
                
                <div className="d-flex justify-content-center mb-3">
                    <div className='col-md-10'>
                        <div className="row">
                            <div className="mb-3">
                                <h5 className="ms-5"> Total de Ventas: $ { statusSum?.toLocaleString('es-Ar') }</h5>
                            </div>
                        </div>
                        <div className='row'>
                             {/* MUESTRA EL MÉTODO DE ENVÍO SI ESTOY EN PENDIENTE */}
                                { (status === 'PENDIENTE') && (
                                    
                                    (status === 'PENDIENTE') && (
                                        <div className='d-flex justify-content-start ms-5 mb-3 col-md-4'>
                                            {/* <DropdownButton title={ tipoEnvio } onSelect={ (e) => handleSelectEnvio(e) }>
                                                        <Dropdown.Item eventKey='0' value>Sin Envío</Dropdown.Item>
                                                        <Dropdown.Item eventKey='1'>Con Envío</Dropdown.Item>
                                                    </DropdownButton> */}
                                            <Button className= { (tipoEnvio === '0' )? 'product__botonera-select product__botonera' : 'product__botonera'}
                                                    onClick={ () => handleSelectEnvio('0') }>
                                                        Buenos Aires
                                            </Button>
                                            <Button className= { (tipoEnvio === '1' )? 'product__botonera-select product__botonera ms-1' : 'product__botonera ms-1'}                                                    
                                                    onClick={ () => handleSelectEnvio('1') }>
                                                    Interior
                                            </Button>
                                        </div>
                                    )
                                )}    
                        </div>
                    </div>
                    <div className='col-md-2'>
                    {/* SI ESTOY EN APROBADOS Y HAY ALGÚN CHECKED SELECCIONADO MUESTRO PARA IMPRIMIR */}
                    {
                        (status === 'PENDIENTE' && selected.length > 0) && ( 
                            <div className='row'>
                                <div className="col-md-4 components__small-box pointer ms-3 d-flex justify-content-center align-items-center" onClick={handleImprimirAll}>
                                    <i className="fas fa-print fa-3x"></i>                                
                                </div>
                                <div className="col-md-4 components__small-box pointer ms-3 d-flex justify-content-center align-items-center" onClick={handleShowSelectOrders}>
                                    <i className="far fa-object-ungroup fa-3x"></i>                               
                                </div>
                            </div>
                        )
                    }
                    </div>
                </div>
                       
                <Table striped bordered={false} hover>
                    <thead>
                        <tr className="components__table-header">
                            <th>Nº</th>
                            {/* EL TITULO DE LA TABLA PARA EL CHECKED */}
                            {
                                (status === 'PENDIENTE') && (
                                    <th></th>
                                )
                            }
                            <th style = {{ width: "50%"}}>Nombre Cliente</th>
                            <th>Fecha</th>
                            <th>Total</th>
                            {
                                (status !== 'TODOS')
                                    ? <th>Estado</th>
                                    : <th>Acción</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            (ordersFilter?.length > 0) 
                                ? ordersFilter.map( (order, i) => 
                                (<tr key={i}>
                                    <td>
                                        { String(order.id).padStart(6, '0') }
                                    </td>
                                    {/* EL CHECKED SE MUESTRA SI ESTOY EN PENDIENTE O APROBADO */}
                                    { (status === 'PENDIENTE' ) && (
                                        <td>
                                            <Form.Check 
                                                id={`default-${i}`}
                                                label={''}
                                                checked = { selected.some(o => o.id === order.id) }
                                                onChange = { (e) => handleSelect(e, order) }
                                            />
                                        </td>
                                    )}
                                    <td>
                                        <h6>{ order.user.nameMarkets }</h6>

                                        {/* TABLA DE SUBTITULO */}                                        
                                        <Table className="mb-0 table-b table-borderless">
                                            <thead>
                                                <tr className='order__table-head-tr'>
                                                    <th className="mb-0 mt-0 pb-0 pt-0 order__table-head w10"><small>Cantidad</small></th>
                                                    <th className="mb-0 mt-0 pb-0 pt-0 order__table-head w20"><small>Cant x pack</small></th>
                                                    <th className="mb-0 mt-0 pb-0 pt-0 order__table-head"><small>Producto</small></th>
                                                    <th className="mb-0 mt-0 pb-0 pt-0 order__table-head w10"><small>Precio</small></th>
                                                    <th className="mb-0 mt-0 pb-0 pt-0 order__table-head w10"><small>Total</small></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                        {
                                            order.productOrder
                                            .sort( (a,b) => a.product.priority - b.product.priority)
                                            .map( (product, i) => (                                                
                                                <tr key={i}>
                                                    <td className="mb-0 mt-0 pb-0 pt-0"><small>{ product.quantity }</small></td>
                                                    <td className="mb-0 mt-0 pb-0 pt-0"><small>{ product.product.quantity }</small></td>
                                                    <td className="mb-0 mt-0 pb-0 pt-0"><small>{ product.product.name }</small></td>
                                                    <td className="mb-0 mt-0 pb-0 pt-0 text-end"><small>{ (product.price / product.quantity / product.product.quantity )?.toLocaleString('es-Ar', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }</small></td>
                                                    <td className="mb-0 mt-0 pb-0 pt-0 text-end"><small>{ (product.price )?.toLocaleString('es-Ar', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }</small></td>                                                    
                                                </tr>
                                            ))
                                        }
                                            </tbody>
                                        </Table>    
                                    </td>
                                    <td>{ moment(order.createdAt).format('DD/MM/YYYY') }</td>
                                    <td className="text-end">{ order.priceTotal?.toLocaleString('es-Ar', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }</td>
                                    <td className='text-center'>
                                    {
                                        (status !== 'TODOS' && status !== 'ENVIADO' &&  status !== 'MAPA' && status !== 'CANCELADO') && 
                                                    (<DropdownButton title={ `${ order.status}` } onSelect={ (e) => handleSelectListado( e, order.id) }>
                                                        {
                                                            (status === 'PENDIENTE') && (
                                                                <>                                                                
                                                                    <Dropdown.Item eventKey='CANCELADO' value>CANCELAR</Dropdown.Item>
                                                                    <Dropdown.Item eventKey='ENVIADO'>ENVIADO</Dropdown.Item>
                                                                </>
                                                            )                                                            
                                                        }                                                    
                                                        {/* {
                                                            (status === 'CANCELADOS') && (
                                                                <Dropdown.Item eventKey='PENDIENTE'>PENDIENTE</Dropdown.Item>
                                                            )
                                                        } */}
                                                    </DropdownButton>)                                        
                                    }
                                    {
                                        (status !== 'TODOS' && status !== 'ENVIADO' &&  status === 'MAPA') && (                                            
                                            <Button className='d-block' onClick={ () => handleSacarSeleccionado(order.id) }>Sacarlo de Seleccionado</Button> )
                                    }
                                            <i 
                                                className='fas fa-eye fa-2x order__action-order pointer' 
                                                onClick = { () => handleShowDetail( order ) }></i>
                                            <i 
                                                className="fa-brands fa-whatsapp order__action-order fa-2x pointer ms-3"
                                                onClick = { () => handleWhatsapp( order ) }></i>
                                                
                                            <i 
                                                className="fas fa-print fa-2x order__action-order pointer ms-3"
                                                onClick = { () => handlePrint( order ) }></i>
                                            { (user.role === 'superadmin') &&
                                            <i 
                                                className="fas fa-trash fa-2x order__action-order pointer ms-3"
                                                onClick = { () => handleDelete( order ) }></i>
                                            }
                                            { order?.user?.send.trim().length > 0 && 
                                                (  
                                                    <i 
                                                    className="fas fa-ticket fa-2x order__action-order pointer ms-3"
                                                    onClick = { () => handlePrintTicket( order ) }></i>  
                                                ) 
                                            }
                                                                                     
                                                
                                    </td>
                                    
                                </tr>))
                                : (<></>)
                        }
                    </tbody>
                </Table>
            </div>

                {/* <OrderDetailsPrint className="print" show={showPrintOrders} onHide={handleShowPrintClose}/> */}
                <OrderDetail show={showDetailOrder} onHide={handleShowDetailClose} print={ print }/>     
                <OrderSelects show={showSelectOrders} 
                              onHide={ handleShowSelectOrdersClose } 
                              handleSelectListado={ handleSelectListado }
                              handleSelectListados = {handleSelectListados} 
                              selected={ selected }
                              setSelected = { setSelected }/> 
                <OrderTicket show={ showDetailTicket } onHide={ handleShowTicketClose } print= { printTicket } /> 
                {/* </div>         
        }*/}
        </> 
    )
});
