import { types } from "../types/types";


const initialState = {
    dolarCustom: {},
    dolarNacion: 0,
};

export const dolarReducer = (state = initialState, action) => { 

    switch( action.type ) {
        case types.updateDolarCustom:
            return {
                ...state,
                dolarCustom: action.payload.dolarCustom
            }
        case types.updateDolarNacion:
            return {
                ...state,
                dolarNacion: action.payload.dolarNacion
            }
        default:
            return state;
    }

};