import { fetchConToken, fetchConTokenImg } from "../helpers/fetch"
import { types } from '../types/types';
import Swal from 'sweetalert2';
import { finishLoading, startLoading } from './uiActions';

// export const getDolar = () => { 
//     return async( dispatch ) => {
//         const body = await fetch('https://apiarg.herokuapp.com/api/dolaroficial');
//         const { venta } = await body.json();
//         dispatch(setDolarStore( venta ));
//     }
// }


export const getAllProducts = () => { 
    return async( dispatch ) => { 

        try {
            
            const resp = await fetchConToken(`products/`);
            const body = await resp.json();            
            if( body.ok) {
                dispatch( getAllProductsStore(body.products) );
            } else {
                Swal.fire({
                    title: 'Error',
                    text: body.error,
                    icon: 'error',
                });
            }

        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: `Hubo un error al obtener los productos`,
                icon: 'error',
            })
        }

    }
}

// export const getProduct = (id) => {
//     return async( dispatch ) => {
//         try {
            
//             const resp = await fetchConToken(`products/${id}`);
//             const body = await resp.json();
//             console.log( body );
//             if( body.ok) {
//                 dispatch( getProductStore(body.product) );
//             } else {
//                 Swal.fire({
//                     title: 'Error',
//                     text: body.error,
//                     icon: 'error',
//                 });
//             }


//         } catch (error) {
//             Swal.fire({
//                 title: 'Error',
//                 text: `Hubo un error al obtener los productos`,
//                 icon: 'error',
//             })
//         }
//     }
// }


export const getAllCategories = () => { 
    return async( dispatch ) => { 

        try {
            
            const resp = await fetchConToken(`categories/`);
            const body = await resp.json();
            if( body.ok ) {
                dispatch( getAllCategoriesStore(body.categories) );
            }

        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: `Hubo un error al obtener los productos`,
                icon: 'error',
            })
        }

    }
}

export const addProduct = ( product ) => { 
    return async( dispatch ) => { 

        try{

            const resp = await fetchConToken(`products/`, product, 'POST');
            const body = await resp.json();

            if( body.ok ) {
                dispatch( addProductStore(body.product) );
    
                Swal.fire({ 
                    title: 'Producto',
                    text: 'Se ha agregado el producto',
                    icon: 'success',
                });
            } else {
                Swal.fire({
                    title: 'Error',
                    text: `Hubo un error al agregar el producto`,
                    icon: 'error',
                })
            }

        }catch( error ) {
            Swal.fire({
                title: 'Error',
                text: `Hubo un error al agregar el producto ${error}`,
                icon: 'error',
            })
        }
    }
}

export const addStock = ( id, stock, onHide ) => { 
    return async( dispatch ) => { 

        try{

            const resp = await fetchConToken(`products/stock/${ id }`, { stock }, 'PUT');
            const body = await resp.json();
            if( body.ok ) {
                dispatch( updateProductStore(body.product) );
                onHide();
                Swal.fire({ 
                    title: 'Producto',
                    text: 'Se ha agregado el stock al producto',
                    icon: 'success',
                });
            } else {
                Swal.fire({
                    title: 'Error',
                    text: `Hubo un error al agregar stock al producto`,
                    icon: 'error',
                })
            }

        }catch( error ) {
            Swal.fire({
                title: 'Error',
                text: `Hubo un error al agregar el producto ${error}`,
                icon: 'error',
            })
        }
    }
}

export const subStock = ( id, stock, onHide ) => { 
    return async( dispatch ) => { 

        try{

            const resp = await fetchConToken(`products/stock/sub/${ id }`, { stock }, 'PUT');
            const body = await resp.json();
            if( body.ok ) {
                dispatch( updateProductStore(body.product) );
                onHide();
                Swal.fire({ 
                    title: 'Producto',
                    text: 'Se ha resto el stock al producto',
                    icon: 'success',
                });
            } else {
                Swal.fire({
                    title: 'Error',
                    text: `Hubo un error al restar stock al producto`,
                    icon: 'error',
                })
            }

        }catch( error ) {
            Swal.fire({
                title: 'Error',
                text: `Hubo un error al restar el producto ${error}`,
                icon: 'error',
            })
        }
    }
}

export const addCategory = ( category ) => { 
    return async( dispatch ) => { 

        try{
            const resp = await fetchConToken(`categories/`, category, 'POST');
            const body = await resp.json();
           
            if( body.ok ) {
                dispatch( addCategoryStore(body.category) );
                Swal.fire({ 
                    title: 'Categoria',
                    text: 'Se ha agregado la categoria',
                    icon: 'success',
                });
            }


        }catch( error ) {
            Swal.fire({
                title: 'Error',
                text: `Hubo un error al agregar la categoria ${error}`,
                icon: 'error',
            })
        }
    }
}

export const updateProduct = ( id,product, onHide ) => { 
    return async( dispatch ) => { 
        try{
            const resp = await fetchConToken(`products/${id}`, product, 'PUT');
            const producto = await resp.json();
                
            dispatch( updateProductStore(producto) );
            onHide();

            Swal.fire({ 
                title: 'Producto',
                text: 'Se ha actualizado el producto',
                icon: 'success',
            });

        }catch( error ){
            Swal.fire({
                title: 'Error',
                text: `Hubo un error al actualizar el producto ${error}`,
                icon: 'error',
            })
        }
    }
}

export const deleteProduct = ( product ) => { 
    return async( dispatch ) => { 
        try{
            const resp = await fetchConToken(`products/${product.id}`, {}, 'DELETE');
            const producto = await resp.json();
                
            dispatch( deleteProductStore(product) );

            Swal.fire({ 
                title: 'Producto',
                text: 'Se ha Eliminado el producto',
                icon: 'success',
            });

        }catch( error ){
            Swal.fire({
                title: 'Error',
                text: `Hubo un error al eliminar el producto ${error}`,
                icon: 'error',
            })
        }
    }
}

export const updateCategory = ( id,category, onHide ) => { 
    return async( dispatch ) => { 
        try{
            
            const resp = await fetchConToken(`categories/${id}`, category, 'PUT');
            const body = await resp.json();
            
            if( body.ok ) {
                dispatch( updateCategoryStore(body.category) );
                onHide();

                Swal.fire({ 
                    title: 'Producto',
                    text: 'Se ha actualizado el producto',
                    icon: 'success',
                });
            }

        }catch( error ){
            Swal.fire({
                title: 'Error',
                text: `Hubo un error al actualizar el producto ${error}`,
                icon: 'error',
            })
        }
    }
}

export const updateCateInProduct = ( id, data ) => { 
    return async( dispatch ) => { 
        try{
            dispatch(startLoading());
            const resp = await fetchConToken(`products/category/${id}`, data, 'PUT');
            const body = await resp.json();
            
            if( body.ok ) {
                dispatch( updateProductStore(body.product) );
            }
            
            dispatch( finishLoading());
        }catch( error ){
            Swal.fire({
                title: 'Error',
                text: `Hubo un error al actualizar el producto ${error}`,
                icon: 'error',
            });
            dispatch( finishLoading);
        }
    }
} 

export const savePhoto = ( file, id ) => { 
    return async( dispatch ) => { 
        try{
            const resp = await fetchConTokenImg(`products/upload/${id}`, file, 'POST');
            const { product } = await resp.json();
            
            dispatch( updateProductStore(product) );
            dispatch( updateProductSelected(product) );
            Swal.fire({ 
                title: 'Producto',
                text: 'La foto se ha subido correctamente',
                icon: 'success',
            });

        }catch( error ){
            Swal.fire({
                title: 'Error',
                text: `Hubo un error al subir la foto ${error}`,
                icon: 'error',
            })
        }
    }
}

export const deletePhoto = ( id ) => { 
    return async( dispatch ) => { 
        try {
            const resp = await fetchConToken(`products/photo/${id}`, '', 'DELETE');
            await resp.json();

            dispatch( deletePhotoStore(id) );

            Swal.fire({ 
                title: 'Producto',
                text: 'La foto se elimino correctamente',
                icon: 'success',
            });
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: `Hubo un error al eliminar la foto ${error}`,
                icon: 'error',
            })
        }
    }
}

export const getAllProductsStore = ( products ) => { 
    return {
        type: types.productsGetAll,
        payload: {
            products
        }
    }
}

// export const getProductStore = ( product ) => { 
//     return {
//         type: types.productGet,
//         payload: {
//             product
//         }
//     }
// }

const getAllCategoriesStore = ( categories ) => {
    return {
        type: types.categoriesGetAll,
        payload: {
            categories
        }
    }
}

const addProductStore = ( product ) => { 
    return {
        type: types.productsAdd,
        payload: {
            product
        }
    }
}

const addCategoryStore = ( category ) => {
    return {
        type: types.categoriesAdd,
        payload: {
            category
        }
    }
}

const updateProductStore = ( product ) => { 
    return {
        type: types.productsUpdate,
        payload: {
            product
        }
    }
}

const deleteProductStore = ( product ) => { 
    return {
        type: types.productsDelete,
        payload: {
            product
        }
    }
}

export const updateProductSelected = ( product ) => { 
    return {
        type: types.productsUpdateSelected,
        payload: {
            product
        }
    }
}

const updateCategoryStore = ( category ) => {
    return {
        type: types.categoriesUpdate,
        payload: {
            category
        }
    }
}

export const updateCategorySelected = ( category ) => {
    return {
        type: types.categoryUpdateSelected,
        payload: {
            category
        }
    }
}

export const deletePhotoStore = ( id ) => {
    return {
        type: types.photoDelete,
        payload: {
            id
        }
    }
}

export const setProductPrint = (products) => {
    return {
        type: types.productPrint,
        payload: {
            products
        }
    }
}