import React from 'react';
import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthRouter } from './AuthRouter';
import { HomeRouter } from './HomeRouter';
import { useDispatch } from 'react-redux';
import { startChecking } from '../actions/authActions';
import { Policy } from '../pages/dashboard/Policy';
import { HomeScreen } from '../pages/dashboard/HomeScreen';

export const AppRouter = () => {

  const dispatch = useDispatch();

  // const navigate = useNavigate();

  useEffect(() => {
   
    dispatch(startChecking() )    

  }, [ dispatch]);

  // if( loading ) { 
  //   return <p>Cargando........</p>
  // }

  return (
    <BrowserRouter>
        <Routes>
            <Route path="/auth/*" element={<AuthRouter />} />   
            <Route path="/policy" element={<Policy />} />        
            <Route path="/" element={<HomeScreen />} />
            <Route path="/*" element={<HomeRouter />} />            
        </Routes>

      

    </BrowserRouter>
  )
}
