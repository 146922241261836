import React from 'react'
import { Button, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const ProductPrint = ({show, onHide, style}) => {
    const { state } = useLocation();

    const { productsPrint: products } = useSelector( state => state.products );

    const handlePrint = async() => { 
        window.print();
        
        // veo si existe porque puedo entrar por el mapa que si va a existir o por la página pedidos
        if( onHide ){
            onHide();
        }
    }
    

  return (
      <div style = {{ display: (show || state?.status ) ?'block': 'none' }} className="print">
            <h3 className="ms-4">Productos: </h3>
            <div className='no-print'>
                <Button
                    className='no-print ms-4'
                    onClick={ handlePrint }
                >Imprimir</Button>
            </div>
            
                <div className="print">
                    <div>
                        <div className='p-3'>
                            <Table className="mb-0 table-b table-borderless">
                                <thead>
                                    <tr className='order__table-head-tr'>
                                        <th className="mb-0 mt-0 pb-0 pt-0 order__table-head w10"><small>Cantidad</small></th>
                                        <th className="mb-0 mt-0 pb-0 pt-0 order__table-head"><small>Producto</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products?.map((product, id) => (
                                    <tr key = {id}>                                        
                                        <td className="mb-0 mt-0 pb-0 pt-0"><small>{ product.quantity }</small></td>
                                        <td className="mb-0 mt-0 pb-0 pt-0"><small>{ product.product.name }</small></td>
                                    </tr>                                
                                    ))}
                                </tbody>
                            </Table> 
                        </div>   
                    </div>
                </div>    
    </div>
  )
}
