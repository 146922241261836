import React, { useEffect, useState } from 'react'
import { Modal, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { finishLoading, startLoading } from '../../../actions/uiActions';


export const OrderDetail = ({show, onHide, print}) => {

    const { orderSelect: order } = useSelector( state => state.orders );
    const { isLoading } = useSelector( state => state.ui );

    // Manejo una variable noPrint para saber si esta viendo el detalle o viene por impresion directa
    // si viene por detalle le permito imprimir totales sino no
    const [noPrint, setNoPrint] = useState(true);

    const dispatch = useDispatch();

    // const handlePrint = () => {
    //     window.print();
    // }

    const handleLoadPrint = () => { 
        setNoPrint(true);
        if( print ) {
            setTimeout(() => {
                console.log('entra en loadPrint');
                window.print();
                onHide();
            }, 600);
        }
    }

    useEffect(() => { 
        handleLoadPrint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ print ] );

    const handlePrint = () => {        
        dispatch( startLoading())        
        
        setNoPrint(false);
        setTimeout(() => {
            window.print();
            onHide();
            dispatch( finishLoading())
        }, 600);
    }


  return (
    <Modal show={show} onHide={onHide} size="lg" style={{ width: '100vw', maxWidth: '90% !important' }}>
        <Modal.Header closeButton className='no-print'>
            <Modal.Title>Pedido</Modal.Title>
        </Modal.Header>
        {/* <div>
            <Button className='ms-2 mt-2 no-print' onClick= { handlePrint }>
                Imprimir
            </Button>
        </div> */}
        <Modal.Body>
            <div className="row ms-4 mt-4 me-4">
                <div className="col-9">
                    <h5 className='order-detail__text-markets'>{ order?.user.nameMarkets }</h5>
                </div>
                {/* <div className={(noPrint)? "col-3 no-print": "col-3"}>
                    <h5 className='order-detail__text-markets'>$ { (order?.priceTotal)?.toLocaleString('es-Ar', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }</h5>
                </div> */}
            </div>
            <div className="d-flex justify-content-end no-print">
                <div className="detail-product__button-print components__small-box pointer ml-3 d-flex justify-content-center align-items-center me-3"                     
                     data-bs-toggle="tooltip" 
                     data-bs-placement="top" 
                     title="Nuevo Producto"                     
                     onClick={ (!isLoading)? handlePrint : null }>
                    <i className="fas fa-print fa-3x"></i>
                </div>
            </div>
            <hr className='align-self-center order-detail__hr'/>

            <h5 className="ms-4">Datos del contacto: </h5>
            <div className="row ms-4 mt-1">
                <div className="col-6">
                    <div className='row'>
                        <h6 className='order-detail__text-name'>Nombre: { order?.user.nameContact }</h6>
                    </div>
                    <div className='row'>
                        <h6 className='order-detail__text-name'>Email: <small className="order-detail__text-name-small">{ order?.user.email }</small></h6>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                        <h6 className='order-detail__text-name'>Direccción:  { order?.user.street } - { order?.user.num }</h6>
                    </div>
                    <div className="row">
                        <h6 className='order-detail__text-name'>Localidad: { order?.user.city }</h6>
                    </div>
                    {( order?.user.send.length > 0 ) && (
                        <div className="row">
                            <h6 className='order-detail__text-name'>Envio: { order?.user.send }</h6>
                        </div>
                    )}
                </div>
            </div>
            <div className='p-3'>
                <Table className="mb-0 table-b table-borderless">
                    <thead>
                        <tr className='order__table-head-tr'>
                            <th className="mb-0 mt-0 pb-0 pt-0 order__table-head w10"><small>Cantidad</small></th>
                            <th className="mb-0 mt-0 pb-0 pt-0 order__table-head w10"><small>Cant x Pack</small></th>
                            <th className="mb-0 mt-0 pb-0 pt-0 order__table-head"><small>Producto</small></th>
                            <th className={(noPrint)?"mb-0 mt-0 pb-0 pt-0 order__table-head w10 no-print text-end": "mb-0 mt-0 pb-0 pt-0 order__table-head w10 text-end"}>
                                <small>Precio</small>
                            </th>
                            <th className={(noPrint)?"mb-0 mt-0 pb-0 pt-0 order__table-head w20 no-print text-end": "mb-0 mt-0 pb-0 pt-0 order__table-head w10 text-end"}>
                                <small>Total</small>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            order?.productOrder.map( (product, i) => (
                                <tr key={i}>
                                    <td className="mb-0 mt-0 pb-0 pt-0"><small>{ product.quantity }</small></td>
                                    <td className="mb-0 mt-0 pb-0 pt-0"><small>{ product.product.quantity }</small></td>
                                    <td className="mb-0 mt-0 pb-0 pt-0"><small>{ product.product.name }</small></td>
                                    <td className={(noPrint)? "mb-0 mt-0 pb-0 pt-0 no-print text-end": "mb-0 mt-0 pb-0 pt-0 text-end"}>
                                        <small>$ { (product.price / product.quantity / product.product.quantity)?.toLocaleString('es-Ar', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</small>
                                    </td>
                                    <td className={(noPrint)? "mb-0 mt-0 pb-0 pt-0 no-print text-end": "mb-0 mt-0 pb-0 pt-0 text-end"}>
                                        <small>$ { (product.price)?.toLocaleString('es-Ar', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }</small>
                                    </td>
                                </tr>
                            ))
                        }
                        <tr className={(noPrint)? "estadisticas__totales-table mb-0 mt-0 pb-0 pt-0 no-print text-end": "estadisticas__totales-table mb-0 mt-0 pb-0 pt-0 text-end"}>
                            <td className="order__print-total-negrita">TOTAL</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='order__print-total-negrita'>                                
                                    $ { (order?.priceTotal)?.toLocaleString('es-Ar', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }                                
                            </td>
                        </tr>
                    </tbody>
                </Table> 
            </div>   
        
        </Modal.Body>
    </Modal>
  )
}
