import React, { useState } from 'react'
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { renewPassword } from '../actions/authActions';
import { removeError, setError } from '../actions/uiActions';

export const NewPassword = () => {

    const { token } = useParams();
    const navigate = useNavigate();

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const [showPass1, setshowPass1] = useState(false);
    const [showPass2, setshowPass2] = useState(false);

    const { error, isLoading } = useSelector( state => state.ui );
    const dispatch = useDispatch();

    const handlePasswordChange = ({target}) => {
        setPassword( target.value );
    }

    const handlePasswordChange2 = ({target}) => {
        setPassword2( target.value );        
    }

    const handleSubmit = () => { 
        if(password !== password2 || password.trim().length < 5) {
            dispatch(setError('Los password deben coincidir y un mínimo de 5 caracteres'));
        } else {
            dispatch(removeError());
        }
        dispatch( renewPassword(password, token)).then( () =>
            navigate('auth/login')
        );
    }

  return (
    <div className="auth__forget-container">
        <div className="auth__forget-content">
        <div className="auth__forget-title">
            <p>Fijamom</p>
        </div>
        <p>Ingrese la nueva password</p>
            {
                    error && 
                    (<div className='auth__alert-error'>
                        { error }
                    </div>)
                }
            <form className="auth__form">
                    
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon4">
                        <i className="fa-solid fa-shield-halved auth__input-icon"></i>
                    </InputGroup.Text>
                    <FormControl
                        placeholder="Password"
                        type={ (showPass1) ? "text" : "password" }
                        aria-label="Password"
                        aria-describedby="basic-addon4"
                        className='auth__input-placeholder'
                        name="password"   
                        value={password}
                        onChange={ handlePasswordChange }                                           
                    />
                    <InputGroup.Text id="basic-addon4" className="pointer" onClick={ () => setshowPass1( !showPass1 ) }>
                        <i className="fa-solid fa-eye auth__input-icon"></i>
                    </InputGroup.Text>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon5">
                        <i className="fa-solid fa-shield-halved auth__input-icon"></i>
                    </InputGroup.Text>
                    <FormControl
                        placeholder="Re-Password"
                        type={ (showPass2) ? "text" : "password" }
                        aria-label="Password2"
                        aria-describedby="basic-addon5"
                        className='auth__input-placeholder'
                        name="password2"   
                        value={password2}
                        onChange={ handlePasswordChange2 }                                           
                    />
                    <InputGroup.Text id="basic-addon5" className="pointer" onClick={ () => setshowPass2( !showPass2 ) }>
                        <i className="fa-solid fa-eye auth__input-icon"></i>
                    </InputGroup.Text>
                </InputGroup>
                    
                <Button 
                        className="mb-3 w-100"
                        variant="primary" 
                        onClick={ handleSubmit }
                        disabled={ isLoading }>
                        Cambiar Password
                </Button><br/>
            </form>
        </div>  
    </div>
  )
}
