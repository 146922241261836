/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Modal } from 'react-bootstrap';

// eslint-disable-next-line import/no-webpack-loader-syntax
import Map from '!react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { useDispatch } from 'react-redux';
import { updateUser } from '../../../actions/usersActions'; // eslint-disable-line
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl/dist/mapbox-gl-csp';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker'; // Load worker code separately with worker-loader
import { Marker } from 'react-map-gl';
mapboxgl.workerClass = MapboxWorker;


//mapboxgl.accessToken = 'pk.eyJ1Ijoic3VwZXJwZXBlMjAwMyIsImEiOiJjazZzZTV1bzAwZGI2M2Zxc2lnbHJyOHNzIn0._kjleu2w1mfxn6gOGQuJlg';

const base_url = process.env.REACT_APP_ARCGIS;

export const UpdateLocation = ({show, onHide }) => {  

  const mapRef = useRef( null );
  const dispatch = useDispatch();

  const { userSelect: user } = useSelector( state => state.users );
  
  const [viewState, setViewState] = useState({
    longitude: -100,
    latitude: 40,
    zoom: 14
  });

  useEffect( () => {
    loadUbication();
    
    
  }, [ mapRef.current ]);

  const pins = useMemo(
    () =>
        <Marker
          color='red'
          longitude={user?.longitude}
          latitude={user?.latitude}
          anchor="bottom"
          onClick={e => {
            // If we let the click event propagates to the map, it will immediately close the popup
            // with `closeOnClick: true`
            
            e.originalEvent.stopPropagation();
          }}
        >
        </Marker>,
    []
  );

  const loadUbication = async() => {
    let address = `${ user?.street } ${ user?.num }`;

    if (user?.latitude && user?.longitude) {
    
      setViewState({
        longitude: user?.longitude,
        latitude: user?.latitude,
        zoom: 15
      });

      setTimeout(() => {
          // new mapboxgl.Marker().setLngLat([ user?.longitude, user?.latitude ]).addTo(mapRef.current?.getMap());
        
      }, 500);

    } else if ( user ) {
      
      const url = `${ base_url }&Address=${ address }&City=${ user?.city }`;

      const result = await fetch( url );
      const data = await result.json();

      const loca = data.candidates[0];

      setViewState({
        longitude: loca.location.x,
        latitude: loca.location.y,
        zoom: 15
      });
    }

  }

  const handleMapClick = (e) => {
    
    user.latitude = e.lngLat.lat;
    user.longitude = e.lngLat.lng;

    dispatch( updateUser( user.id, user, onHide ) );
    
  }

  

  return (
    <Modal show={ true } onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Localizar cliente</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          
            <Map
                ref={ mapRef }
                {...viewState }
                style={{width: '100%', height: 400}}
                onMove={evt => setViewState(evt.viewState)}
                mapStyle="mapbox://styles/mapbox/streets-v9"
                mapboxAccessToken='pk.eyJ1Ijoic3VwZXJwZXBlMjAwMyIsImEiOiJjazZzZTV1bzAwZGI2M2Zxc2lnbHJyOHNzIn0._kjleu2w1mfxn6gOGQuJlg'
                onDblClick={ handleMapClick }
            >
                
                { pins }
            </Map>
          
        </Modal.Body>

    </Modal>
  )
};
