/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState, useMemo } from 'react';


import 'mapbox-gl/dist/mapbox-gl.css';

import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl/dist/mapbox-gl-csp';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker'; // Load worker code separately with worker-loader
// eslint-disable-next-line import/no-webpack-loader-syntax
import Map, { FullscreenControl, GeolocateControl, Marker, NavigationControl, Popup, ScaleControl } from '!react-map-gl';
import { Button } from 'react-bootstrap';
import PinMap from './components/PinMap';
import { getUsersOrders } from '../../actions/usersActions';
import { clearOneOrdersInMap, setOrdersInMap } from '../../actions/orderActions';
import { useNavigate } from 'react-router-dom';


mapboxgl.workerClass = MapboxWorker;



export const MapaAdmin = () => {
  
    const { users } = useSelector( state => state.users );
  
    const [popupInfo, setPopupInfo] = useState(null);

    // Ordenes Seleccionas para mostrar e imprimir
    // const { ordersPrint } = useSelector( state => state.orders );
    let { ordersInMap } = useSelector( state => state.orders );

    const mapMedidas = {
        width: '100%',
        height: '800px',
    }

    const navigate = useNavigate();

    const mapRef = useRef( null );
    const dispatch = useDispatch();
    
    const [viewState, setViewState] = useState({
        longitude: -58.5139717,
        latitude: -34.6548815,
        zoom: 10
      });

    useEffect(() => { 
      dispatch( getUsersOrders('PENDIENTE') );      
    }, []);
    

    useEffect(() => { 
      if( users.length > 0 ) {
       
        let latitude = users?.reduce( ( a, value) => a + value.latitude || 0  , 0 );
        let longitude = users?.reduce( ( a, value) => a + value.longitude || 0  , 0 );

        const countLatitude = users?.filter( u => u.latitude ).length;
        const countLongitude = users?.filter( u => u.longitude ).length;

        latitude = latitude / countLatitude;
        longitude = longitude / countLongitude;
            
        setViewState({ ...viewState, latitude: latitude, longitude: longitude, zoom: 5 });
      }
    }, [users]);


    const handleClickMarker = (e, u) => {  
      e.originalEvent.stopPropagation();

      //Miro si ya esta seleccionado
      const order = u.order?.find( o => o.status === 'PENDIENTE' );

      const exist = ordersInMap?.find( o => o.user?.id === u.id );
      
      if( exist ) { 
        dispatch( clearOneOrdersInMap(order.id))
      }
      else {         
        dispatch( setOrdersInMap(order) );
      }
      setPopupInfo(u);
      
    }

    let pins = useMemo(
        () => {
          return users?.map((u, index) => {    
            return (u?.order?.find( o => o.status ==='PENDIENTE')) &&
              (<Marker
              key={`marker-${index}`}
              longitude={u.longitude}
              latitude={u.latitude}
              anchor="bottom"
              onClick= { (e) => handleClickMarker(e, u) }
              
            >
              <PinMap 
                size={30}
                style= {{cursor: 'pointer', 
                        fill: (ordersInMap?.find( m => m.user?.id === u.id )) ? 'darkcyan' : 'blue', 
                        // fill: (mapData?.find( m => m.id === u.id )?.color === 1) ? 'darkcyan' : 'blue', 
                        stroke: 'none'}}
              />
            </Marker>)
          } 
          )},
        [ ordersInMap, users ]
    );
  

  // Muestra el modal que muestra las ordenes seleccionadas
  const handleShowSelectOrders = () => {

    navigate('/pedidos', { state: { status: 'Mapa' } });

  }




  return (
    <div  className="no-print">
      <div className="mt-1 mb-1 me-4 d-flex justify-content-end no-print">
        <p className='no-print'>Debe seleccionar en el mapa y dar click al botón</p>
        <Button
          className="no-print ms-4"
          onClick={ handleShowSelectOrders }
          // onClick={ handleSelect }
        >
         Ver Seleccionado en el Mapa
        </Button>
      </div>      
        <Map
            ref={ mapRef }
            {...viewState }
            style={{ width: mapMedidas.width, height: mapMedidas.height }}
            onMove={evt => setViewState(evt.viewState)}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            mapboxAccessToken='pk.eyJ1Ijoic3VwZXJwZXBlMjAwMyIsImEiOiJjazZzZTV1bzAwZGI2M2Zxc2lnbHJyOHNzIn0._kjleu2w1mfxn6gOGQuJlg'                                 
        >
            <GeolocateControl position="top-left" />
            <FullscreenControl position="top-left" />
            <NavigationControl position="top-left" />
            <ScaleControl />
            { pins }
            {popupInfo && (
                <Popup

                  anchor="top"
                  longitude={Number(popupInfo.longitude)}
                  latitude={Number(popupInfo.latitude)}
                  onClose={() => setPopupInfo(null)}
                >
                  <div>
                    <p>Nº Cliente: { popupInfo.numCliente }</p>
                    <h6>{popupInfo.nameMarkets} |{' '}</h6>
                    <p>{ popupInfo.street } { popupInfo.num }, { popupInfo.city } </p>
                    
                  </div>
                </Popup>
            )}
        </Map>

    </div>
  )
}
