
import React, { useEffect } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { OrderList } from './components/OrderList';

import { updateStateOrder, getAllOrders, clearOrders, updateOrdersStatusAll } from '../../actions/orderActions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

export const PedidosAdmin = () => {

    const { state } = useLocation();

    
    const dispatch = useDispatch();
    const { orders } = useSelector( state => state.orders );
    const { ordersInMap } = useSelector( state => state.orders );
    
    if( state?.status  === 'Mapa' && ordersInMap.length <= 0 ) {
        state.status = null; 
    }

    useEffect(() => { 
        
        dispatch( getAllOrders() ); 

        return () => {
            dispatch( clearOrders() );
        }
        
    }, [ dispatch ] );



    const handleSelectListado = async ( e, id ) => { 

        dispatch( updateStateOrder(e, id) );

    }

    const handleSelectListados = async ( e, orders, setSelected ) => {   
        
        
        const ids = orders.map( o => o.id);

        dispatch( updateOrdersStatusAll(ids, e))

        if( setSelected) { setSelected( [] ); }

    }

    return (
        <div className='no-print'>
            <h1 className="usuarios_title">Listado de Pedidos</h1>
            <hr/>

            <Tabs defaultActiveKey={ state?.status || 'Pendientes' } id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="Pendientes" title="Pedientes" style={{ color: 'black !important' }}>
                    <OrderList 
                        key="Pendientes"
                        orders = { orders.filter( order => order.status === 'PENDIENTE' ) }
                        handleSelectListado = { handleSelectListado }
                        status = { 'PENDIENTE' }
                        handleSelectListados = { handleSelectListados }
                    />
                </Tab>
                <Tab eventKey="Enviados" title="Enviados" style={{ color: 'black !important'}}>
                    <OrderList 
                        key="Enviados"
                        orders = { orders.filter( 
                            order => order.status === 'ENVIADO' 
                                     && moment(order.updatedAt).month() === moment(new Date()).month()
                                     && moment(order.updatedAt).year() === moment(new Date()).year()
                                     )
                                     ?.sort( (x,y) => new Date(y.updatedAt) - new Date(x.updatedAt) ) }
                        handleSelectListado = { handleSelectListado }
                        status = { 'ENVIADO' }
                    />
                </Tab>
                <Tab eventKey="Todos" title="Todos" style={{ color: 'black !important'}}>
                    <OrderList 
                        key="Todos"
                        orders = { orders }
                        handleSelectListado = { handleSelectListado }
                        status = { 'TODOS' }
                    />
                </Tab>
                <Tab eventKey="Cancelados" title="Cancelados" style={{ color: 'black !important'}}>
                    <OrderList 
                        key="Cancelados"
                        orders = { orders.filter( order => order.status === 'CANCELADO' )
                                            ?.sort( (x,y) => new Date(y.updatedAt) - new Date(x.updatedAt) ) }
                        handleSelectListado = { handleSelectListado }
                        status = { 'CANCELADO' }
                    />
                </Tab>
                {
                    ( ordersInMap?.length > 0 ) && (
                        <Tab eventKey="Mapa" title="Seleccionado en Mapa" style={{ color: 'black !important'}}>
                            <OrderList 
                                key="Mapa"
                                orders = { ordersInMap }
                                handleSelectListado = { handleSelectListado }
                                handleSelectListados = { handleSelectListados }
                                status = { 'MAPA' }
                            />
                        </Tab>
                    )
                }
            </Tabs>
            <hr/>

            
            </div>
    )
}
