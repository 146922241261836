
import { useDispatch, useSelector } from 'react-redux';

import { useForm } from '../hooks/useForm';
import { removeError, setError } from '../actions/uiActions';
import { createUser } from '../actions/usersActions';
import { FormUsuario } from '../components/FormUsuario';
import { isFormValidRegistro } from '../helpers/validarRegistro';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const initialState = {
    nameMarkets: '',
    nameContact: '',
    email: '',
    password: '',
    password2: '',
    send: '',
    street: '',
    num: '',
    city: '',
    cp: '',
    province: '',
    openingsDayHours: '',
    whatsapp: [{id: undefined, userId: null, num: ''}]
}

const base_url = process.env.REACT_APP_ARCGIS;

export const RegisterScreen = () => {    

    const { error, isLoading } = useSelector( state => state.ui ); 
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [ values, 
            handleInputChange, 
            ,
            handleArrayChange, 
            handleArrayAdd, 
            handleArrayRemove ] = useForm(initialState);

   
    const handleSubmit = async (e) => { 
        e.preventDefault();

        
        const valida = isFormValidRegistro( dispatch, values, setError, removeError, true );
        if( valida ) {

            const address = `${ values.street } ${ values.num }`;
            const url = `${ base_url }&Address=${ address }&City=${ values.city }&Region=${ values.province }`;

            const result = await fetch( url );
            const data = await result.json();

            const loca = data.candidates[0];

            values.latitude = loca.location.y;
            values.longitude = loca.location.x;

            dispatch( createUser(values) ).then( () => {                 
                navigate('/auth/login');
                navigate(0);
            });
        } 
    }

    


    return (

    <div className="auth__container">
        <div className="auth__box-content col-md-8 col-lg-7 col-10">
            <div className="auth_login-form">
                <h3 className="auth__title">Registro</h3>
                <hr className='auth__divisor'/>
                {
                    error && 
                    (<div className='auth__alert-error'>
                        { error }
                    </div>)
                }
                <FormUsuario 
                    values = {values} 
                    handleInputChange = {handleInputChange} 
                    handleArrayChange = {handleArrayChange}
                    handleArrayAdd = {handleArrayAdd}
                    handleArrayRemove = {handleArrayRemove}
                    isRegistro = { true }
                />

                <div className="row">
                    <small>* Solo para clientes del interior.</small>
                    <small>** Registre el Whatsapp sin el 0 y sin el 15.</small>
                    <Button variant="primary" 
                            type="submit" 
                            onClick=  { handleSubmit } 
                            disabled={ isLoading }>
                        Registrar
                    </Button>
                    <small className="d-block mt-2 mb-2">¿Tienes cuenta?</small>
                    <Link className="pointer" to="/auth/login">Inicia Sesión</Link>
                </div>
            </div>
        </div>
    </div>
    )
}