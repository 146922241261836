import React, { useEffect, useState } from 'react';

import { Modal, Button, Form, InputGroup, FormControl, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { useForm } from '../../../hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { removeError, setError } from '../../../actions/uiActions';
import { addProduct, updateProduct } from '../../../actions/productsActions';

let initialValue = {
    name: '',
    description: '',
    stock: 0,
    minStock: 0,
    maxStock: 0,
    quantity: 0.0,
    price0: 0.0,
    price1: 0.0,
    price2: 0.0,
    price3: 0.0,
    price4: 0.0,
    bill: "0",
    priority: 1.0,
    categoryId: null,
    isDolar: false,
}


export const AddUpdateProduct = ({ show, onHide }) => {

    const [values, handleInputChange, reset] = useForm( initialValue );
    const [titleAddUpdate, setTitleAddUpdate] = useState('Agregar nuevo Producto');
    const { productSelect } = useSelector( state => state.products );

    useEffect(() => { 
        if( productSelect ) {            
            let { name, description, quantity, stock, minStock, maxStock, price0, price1, price2, price3, price4, bill, priority, categoryId, isDolar } = productSelect;    
                           
            reset({ name, description, quantity, stock, minStock, maxStock, price0, price1, price2, price3, price4, bill, priority, categoryId, isDolar });
            setTitleAddUpdate('Editar Producto');
        } else {
            reset({name: '',
            description: '',
            quantity: 0.0,
            stock: 0,
            minStock: 0,
            maxStock: 0,
            price0: 0.0,
            price1: 0.0,
            price2: 0.0,
            price3: 0.0,
            price4: 0.0,
            bill: "0",
            priority: 1.0,
            isDolar: false,
            });
            setTitleAddUpdate('Agregar nuevo Producto');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ productSelect ]);

    const dispatch = useDispatch();
    const { isLoading, error }= useSelector( state => state.ui ); 

    const { name, description, quantity, stock, minStock, maxStock, price0, price1, price2, price3, price4, bill, priority, isDolar } = values;

    // REGISTRA EL EVENTO KEYDOWN PARA SABER SI APRETO ENTER
    // useEffect(() => {
    //     const listener = event => {
    //       if (event.code === "Enter" || event.code === "NumpadEnter") {
           
    //         handleSave();
    //         event.preventDefault();
    //         // callMyFunction();
    //       }
    //     };
    //     document.addEventListener("keydown", listener);
    //     return () => {
    //       document.removeEventListener("keydown", listener);
    //     };
    //   }, []);

    const handleSave = () => {         
        if( isFormValid()) {
            if( productSelect ) { 
                dispatch( updateProduct( productSelect.id, values, onHide ) );
            } else {
                dispatch( addProduct( values ) );
                reset();
            }
        }
    }

    const handleChangeDolar = (e) => {
        
        const payload = { target: { name:'isDolar', value: e, type: 'radio' }};
        handleInputChange(payload);
    }

    const isFormValid = () => { 
        
        if( name.trim().length === 0 ){            
            dispatch(setError( 'Nombre del producto es requerido' ));
            return false;
        } 
        // if( quantity.length === 0 ){            
        //     dispatch(setError( 'La cantidad del producto es requerida' ));
        //     return false;
        // } 
        dispatch(removeError());
        return true;
    }

  return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{ titleAddUpdate }</Modal.Title>
        </Modal.Header>


        <Modal.Body>
            {
                error && 
                (<div className='auth__alert-error'>
                    { error }
                </div>)
            }
            <Form className='auth__form'>                
                <h6 className="products__add-title">Nombre del Producto</h6>
                <InputGroup className="mb-3">                    
                    <InputGroup.Text id="basic-addon1">
                        <i className="fa-solid fa-cart-plus"></i>
                    </InputGroup.Text>
                    <FormControl
                        tabIndex={1}
                        placeholder="Nombre del Producto"
                        aria-label="Nombre-Producto"
                        aria-describedby="basic-addon1"      
                        className='auth__input-placeholder'
                        name='name'                          
                        value={ name }
                        onChange={ handleInputChange }                                    
                    />
                </InputGroup>
                <h6 className="products__add-title">Detalle del Producto</h6>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon2">
                        <i className="fa-solid fa-file-medical"></i>
                    </InputGroup.Text>
                    <FormControl
                        tabIndex={2}
                        placeholder="Descripción del Producto"
                        aria-label="Descripcion-Producto"
                        aria-describedby="basic-addon2"      
                        className='auth__input-placeholder'
                        name="description"                          
                        value={ description || '' }
                        onChange={ handleInputChange }                                    
                    />
                </InputGroup>
                <h6 className="products__add-title">¿El Precio es en dolares?</h6>
                <p>No lo pone en pesos argentinos</p>
                 <ToggleButtonGroup 
                    type="radio" 
                    name="isDolar" 
                    value = { isDolar }
                    onChange={ handleChangeDolar }
                    className="mb-2">
                        <ToggleButton
                            id={`radio-1`}
                            // type="radio"
                            variant='outline-success'
                            value={ true }>
                            Si
                        </ToggleButton>
                        <ToggleButton
                                id={`radio-2`}
                            // type="radio"
                            variant='outline-danger'
                            value={ false }>
                            No
                        </ToggleButton>
                </ToggleButtonGroup>
                <hr/>
                <InputGroup className="mb-3">
                    <small className="products__add-title">Cantidad por pack: </small>
                    <InputGroup.Text id="basic-addon3">
                        <i className="fas fa-box-open"></i>
                    </InputGroup.Text>
                    <FormControl
                        tabIndex={3}
                        placeholder="Cantidad por paquete"
                        aria-label="Cantidad-Producto"
                        aria-describedby="basic-addon2"      
                        className='auth__input-placeholder d-inline'
                        type="number"
                        name="quantity"
                        typevalue="plata"
                        value={ quantity }
                        onChange={ handleInputChange }                                    
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <small className="products__add-title">Stock de pack: </small>                        
                    <InputGroup.Text id="basic-addon3">
                        <i className="fa-solid fa-file-medical"></i>
                    </InputGroup.Text>
                    <FormControl
                        tabIndex={3}
                        placeholder="Stock de paquetes"
                        aria-label="Stock-Producto"
                        aria-describedby="basic-addon2"      
                        className='auth__input-placeholder'
                        type="number"
                        name="stock"
                        typevalue="number"
                        value={ stock }
                        onChange={ handleInputChange }                                    
                    />
                </InputGroup>
                <div className="row">
                    <div className="col-6">
                        <small className="products__add-title">Stock mínimo: </small>                        
                        <InputGroup.Text id="basic-addon3">
                            <i className="fa-solid fa-file-medical"></i>
                        </InputGroup.Text>
                        <FormControl
                            tabIndex={3}
                            placeholder="Stock mínimo de paquetes"
                            aria-label="Stock-min-Producto"
                            aria-describedby="basic-addon2"      
                            className='auth__input-placeholder'
                            type="number"
                            name="minStock"
                            typevalue="number"
                            value={ minStock }
                            onChange={ handleInputChange }                                    
                        />
                    </div>
                    <div className="col-6">
                        <small className="products__add-title">Stock ideal: </small>                        
                        <InputGroup.Text id="basic-addon3">
                            <i className="fa-solid fa-file-medical"></i>
                        </InputGroup.Text>
                        <FormControl
                            tabIndex={3}
                            placeholder="Stock máximo de paquetes"
                            aria-label="Stock-max-Producto"
                            aria-describedby="basic-addon2"      
                            className='auth__input-placeholder'
                            type="number"
                            name="maxStock"
                            typevalue="number"
                            value={ maxStock }
                            onChange={ handleInputChange }                                    
                        />
                    </div>
                </div>
                <hr/>
                <InputGroup className="mb-3">                    
                    <small className="products__add-title">Facturación del producto: </small>
                    <InputGroup.Text id="basic-addon3">
                        <i className="fas fa-file-invoice-dollar"></i>
                    </InputGroup.Text>
                    <FormControl
                        tabIndex={3}
                        placeholder="Facturación del producto"
                        aria-label="Facturacion-producto"
                        aria-describedby="basic-addon3"      
                        className='auth__input-placeholder'
                        name="bill"
                        value={ bill }
                        onChange={ handleInputChange }  
                        pattern = "^[1-9][0-9]?$|^100$" 
                    />
                </InputGroup>            
                <InputGroup className="mb-2">
                    <small className="products__add-title"> Prioridad del producto: </small>
                    <InputGroup.Text id="basic-addon4">
                        <i className="fas fa-upload"></i>
                    </InputGroup.Text>
                    <FormControl
                        tabIndex={4}
                        placeholder="Prioridad"
                        aria-label="Prioridad-Producto"
                        type="number"
                        aria-describedby="basic-addon4"      
                        className='auth__input-placeholder'
                        step="0.1"
                        name="priority"  
                        value={ priority }
                        onChange={ handleInputChange }                                    
                    />
                </InputGroup>
                <small>La prioridad del producto es un campo decimal, para ubicación del mismo en las listas.</small>
                <hr/>
                <h5 className="products__listado-precios">Listados de Precio</h5>
                <div className="row">
                    <div className="col-md-6">
                        <InputGroup className="mb-3">
                            <small className="products__add-title"> 0: </small>
                            <InputGroup.Text id="basic-addon3">
                                <i className="fa-solid fa-money-check-dollar"></i>
                            </InputGroup.Text>
                            <FormControl
                                tabIndex={4}
                                placeholder="Precio lista 0"
                                aria-label="Precio-Producto1"
                                type="number"
                                aria-describedby="basic-addon3"      
                                className='auth__input-placeholder'
                                step="2"
                                name="price0"  
                                value={ price0 }
                                onChange={ handleInputChange }                                    
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <small className="products__add-title"> 1: </small>
                            <InputGroup.Text id="basic-addon4">
                                <i className="fa-solid fa-money-check-dollar"></i>
                            </InputGroup.Text>
                            <FormControl
                                tabIndex={5}                               
                                placeholder="Precio lista 1"
                                aria-label="Precio-Producto2"
                                type="number"
                                aria-describedby="basic-addon4"      
                                className='auth__input-placeholder'
                                step="2"
                                name="price1"  
                                value={ price1 }
                                onChange={ handleInputChange }                                    
                            />
                        </InputGroup>
                    </div>                    
                    <div className="col-md-6">
                        <InputGroup className="mb-3">
                            <small className="products__add-title"> 2: </small>
                            <InputGroup.Text id="basic-addon5">
                                <i className="fa-solid fa-money-check-dollar"></i>
                            </InputGroup.Text>
                            <FormControl
                                tabIndex={6}
                                placeholder="Precio lista 2"
                                aria-label="Precio-Producto"
                                type="number"
                                aria-describedby="basic-addon5"      
                                className='auth__input-placeholder'
                                step="2"
                                name="price2"  
                                value={ price2 }
                                onChange={ handleInputChange }                                      
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <small className="products__add-title"> 3: </small>
                            <InputGroup.Text id="basic-addon6">
                                <i className="fa-solid fa-money-check-dollar"></i>
                            </InputGroup.Text>
                            <FormControl
                                tabIndex={7}
                                placeholder="Precio lista 3"
                                aria-label="Precio-Producto"
                                type="number"
                                aria-describedby="basic-addon6"      
                                className='auth__input-placeholder'
                                step="2"
                                name="price3"  
                                value={ price3 }
                                onChange={ handleInputChange }                                      
                            />
                        </InputGroup>                        
                    </div>
                    <div className="col-md-6">
                        <InputGroup className="mb-3">
                            <small className="products__add-title"> 4: </small>
                            <InputGroup.Text id="basic-addon5">
                                <i className="fa-solid fa-money-check-dollar"></i>
                            </InputGroup.Text>
                            <FormControl
                                tabIndex={6}
                                placeholder="Precio lista 2"
                                aria-label="Precio-Producto"
                                type="number"
                                aria-describedby="basic-addon5"      
                                className='auth__input-placeholder'
                                step="2"
                                name="price4"  
                                value={ price4 }
                                onChange={ handleInputChange }                                      
                            />
                        </InputGroup>
                    </div>
                </div>
               
            </Form>
        </Modal.Body>


        <Modal.Footer>
          <Button variant="info" onClick={onHide} tabIndex={9}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleSave} tabIndex={8} type="submit" disabled={isLoading}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
  )
}
