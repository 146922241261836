

// import React, { useEffect } from 'react'
// import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const HomeScreen = () => {
    const navigate = useNavigate();
    //const { isAuth } = useSelector(state => state.auth);
  
    // Verificar si el usuario está autenticado y redirigir si es necesario
    // useEffect(() => {
    //   // Verificar si el usuario está autenticado y redirigir si es necesario
    //   if (isAuth) {
    //     navigate('/productos');
    //   }
    // }, [isAuth, navigate]);


    return (
        <div className="welcome-page">
            <div className="welcome-content">
                <h1 className="welcome-heading">Bienvenido a Fijamom</h1>
                <div className="welcome-logo">
                    <img className="welcome-img" src="/assets/images/icon-fijamom.png" alt="logo" />
                </div>
                <p className="welcome-text">Por favor, selecciona una opción:</p>
                <div className="options">
                <button className="action-button" data-text="El acceso con credenciales permite hacer uso de todas las funcionalidades del Portal." onClick={redirectToLogin}>Acceder con correo y contraseña</button>
                <button className="action-button" data-text="Le permite registrarse en el Portal, para poder ingresar con credenciales." onClick={redirectToRegister}>Registrarse</button>
                <button className="action-button" data-text="Puede acceder al Portal con funciones limitadas (Ver Productos)" onClick={enterAsGuest}>Entrar como invitado</button>
                </div>
            </div>
            <footer className="contact-info">
                <p>Contacto:</p>
                <a href="mailto: fijamom@gmail.com">Correo: fijamom@gmail.com</a>
                <p>Teléfono: (11) 5893-8080</p>
            </footer>
        </div>
      );
    
      function redirectToLogin() {
        navigate( '/auth/login' );
      }
    
      function redirectToRegister() {
        navigate( '/auth/register' );
      }
    
      function enterAsGuest() {
        navigate( '/productos' );
      }
}
