
import { fetchConToken } from '../helpers/fetch';
import { types } from '../types/types';
import Swal from 'sweetalert2';
import { finishLoading, startLoading } from './uiActions';
import { addUserAuthOrder, updateUserAuthOrder } from './authActions';

export const confirmBuy = ( order, user ) => {
    return async( dispatch) => {

        try {
            dispatch( startLoading() )    
            const resp = await fetchConToken(`orders/`, order, 'POST');
            const body = await resp.json(); 
            if( body.ok === true ) {
                const exist = user?.order.find( order => order.id === body.order.id );
                
                if( exist ) { 
                    dispatch( updateUserAuthOrder( body.order ) );
                } else { 
                    dispatch( addUserAuthOrder( body.order ));
                }                
                dispatch( clearCart() );
                Swal.fire({
                    title: 'Compra',
                    text: `Se ha realizado la compra, su numero de orden es: ${ String(body.order.id).padStart(6, '0') }`,
                    icon: 'success',
                }); 
            } else {
                Swal.fire({
                    title: 'Compra',
                    html: `Error al comprar. <br>${ body.error }`,
                    icon: 'error',
                });
            }

            dispatch( finishLoading() );

        } catch (error) {
            Swal.fire({
                title: 'Error',
                html: `Hubo un error al realizar la compra.<br>${error}`,
                icon: 'error',
            })
            dispatch( finishLoading() );
        }


    }
}

export const addProductCart = (product) => { 
    return {
        type: types.cartAddProduct,
        payload: { product }
    }
}

export const subProductCart = (product) => {
    return {
        type: types.cartSubProduct,
        payload: { product }
    }
}

export const updateProductCart = ( product ) => {
    return {
        type: types.cartUpdateProduct,
        payload: {
             product
        }
    }
}

export const removeProductCart = (product) => {
    return {
        type: types.cartRemoveProduct,
        payload: { product }
    }
}

export const clearCart = () => { 
    return {
        type: types.cartClear
    }
}
