import React, { useCallback, useEffect, useState } from 'react'
import { Modal, Button, Table, Form, DropdownButton, Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getAllProducts } from '../../../actions/productsActions';
import { updateUserProductList } from '../../../actions/usersActions';

export const UpdateUserPrice = ({ show, onHide }) => {

    // LISTADO QUE CONTIENEN LOS DATOS DE P ASIGNADO Y FACTURACIÓN POR CADA PRODUCTO EN EL USUARIO
    const [ listadoDePrecios, setListadoDePrecios ] = useState({});
    const [ listadoDeBill, setListadoDeBill ] = useState({});

    // ESTADO DEL COMBOBOX Y LA CAJA DE TEXTO PARA ASIGNAR GENERAL
    // const [ billGral, setBillGral ] = useState('100');
    
    const { error, isLoading } = useSelector( state => state.ui ); 
    const { userSelect: user } = useSelector( state => state.users );
    const { products } = useSelector( state => state.products );
    const [ listadoActual, setListadoActual ] = useState( '4' );
    const dispatch = useDispatch();
    

     // FUNCIÓN QUE RECORRE LOS PRODUCTOS Y LOS CARGA AL LISTADO DE PRECIOS
     const reset = useCallback( ( value ) => {
        for( const product of products) { 
            setListadoDePrecios( c => {
                return {
                    ...c,
                    [product.id]: value
                }
            } );
        };
    }, [products] );

    // FUNCIÓN QUE RECORRE LOS PRODUCTOS Y LOS CARGA AL LISTADO DE FACTURACIÓN
    const resetBill = useCallback( ( bill ) => {

        for( const product of products) {
            setListadoDeBill( c => {
                return {
                    ...c,
                    [product.id]: bill
                }
            });
        }

    }, [products] );


    // RESETEO TODOS LOS PRECIOS A 1 CUANDO CAMBIO EL USUARIO O EL PRODUCTO Y CARGO LOS DE LA DB
     useEffect(() => { 
         reset( user?.listaDefault || 0 );
         resetBill('0');
         setListadoActual( user?.listaDefault || '4');
         if( user?.productPrice?.length > 0 ) {
            for( let u of user?.productPrice) {
                setListadoDePrecios( c => {
                    return {
                        ...c,
                        [u.productId]: u.price
                    }                    
                } );

                setListadoDeBill( c => {
                    return {
                        ...c,
                        [u.productId]: u.bill
                    }
                } );
            }
        }
     }, 
     [ reset, products, user, resetBill ]);


    // TRAIGO TODOS LOS PRODUCTOS CUANDO CARGO
    useEffect(  ( ) => {
        dispatch( getAllProducts() );        
        
      }, [ dispatch ] );

    // GRABO EN LA BASE DE DATOS LOS PRECIOS
    const handleSubmit = () => { 
        let listChanged = [];

        for( let l in listadoDePrecios ) { 
            listChanged.push( { id: l, lista: listadoDePrecios[l], bill: listadoDeBill[l] } );
        }

        dispatch( updateUserProductList( user.id, listadoActual, listChanged, onHide ) );
        
    }

    // CUANDO SELECCIONO UNA LISTA DE PRECIOS, LO CARGO POR LA FUNCIÓN RESET A TODOS LOS PRODUCTOS
    const handleSelectListado = async ( e ) => { 
        setListadoActual( e );        
        reset(e);
     }

    // const handleSelectBill = async ( {target} ) => {
    //     if( !target.value.match('^[1-9][0-9]?$|^100$')) {
    //         return;
    //     }
    //     setBillGral( target.value );
    //     resetBill( target.value );
    // }

    // CUANDO CAMBIO 1 PRECIO DE PRODUCTO ASIGNADO LO ASIGNO AL LISTADO DEL USUARIO
    const handleArrayChange = async ( id, e ) => { 

        let { value } = e.target;

        if( !value.match('^([0-4]){1}$') ) {
            return;
        }

        listadoDePrecios[id] = value;

        setListadoDePrecios({ ...listadoDePrecios });
    }

    // CUANDO CAMBIO 1 FACTURACION DE PRODUCTO ASIGNADO LO ASIGNO AL LISTADO DEL USUARIO
    const handleBillChange = ( id, e ) => {        
        let { value } = e.target;

        if( !value.match('^[0-9][0-9]?$|^100$') ) {
            return;
        }

        listadoDeBill[id] = value;

        setListadoDeBill({ ...listadoDeBill });

    }


  return (
    <Modal show={show} onHide={onHide} size="xl" style={{ width: '100%', maxWidth: '90% !important' }}>
    <Modal.Header closeButton>
      <Modal.Title>Editar usuario</Modal.Title>
    </Modal.Header>
        <div className="text-end m-2">
            <Button variant="primary" 
                    type="submit" 
                    onClick=  { handleSubmit } 
                    disabled={ isLoading }>
                Guardar
            </Button>
            <Button variant="info" onClick={onHide} tabIndex={9} className='ms-2'>
                Cerrar
            </Button>
        </div>

    <Modal.Body>
        <h5>{ user?.nameMarkets }</h5>
            {
                    error && 
                    (<div className='auth__alert-error'>
                        { error }
                    </div>)
                }
            <br/>
            <div className='row'>
                <div className='col-md-6'>
                    <p>
                    Seleccione el listado por default que tendrá el cliente. Recuerde que si el valor en la lista de producto es de 0 
                    va a tomar este valor como referencia, en caso contrario sera la lista asignada que figura en ese producto.
                    </p>
                </div>
                <div className='col-md-6 ps-3 pe-3'>
                    <DropdownButton title={ `Listado de Precios ${ listadoActual }` } onSelect={ handleSelectListado }>
                        <Dropdown.Item eventKey='0'>Listado de Precios 0</Dropdown.Item>
                        <Dropdown.Item eventKey='1'>Listado de Precios 1</Dropdown.Item>
                        <Dropdown.Item eventKey='2'>Listado de Precios 2</Dropdown.Item>
                        <Dropdown.Item eventKey='3'>Listado de Precios 3</Dropdown.Item>
                        <Dropdown.Item eventKey='4'>Listado de Precios 4</Dropdown.Item>
                    </DropdownButton>
                </div>
                <div className='col-md-6 ps-3 pe-3'>
                    {/* <Form>
                        <Form.Control 
                            type="number"
                            placeholder="Asignar Facturación Gral"
                            name= 'bill-gral'
                            value = { billGral }
                            onChange={ handleSelectBill }
                            pattern="^[1-9][0-9]?$|^100$"
                        />
                    </Form> */}
                </div>
            </div>

            <hr/>
            <p>Si la facturación se encuentra en 0 va a tomar la facturación de ese producto para el cliente.</p>
            <Table striped bordered={false} hover>
                <thead>
                    <tr className="components__table-header">
                        <th style = {{ width: "30%"}}>Nombre</th>
                        <th>P. lista 0</th>
                        <th>P. lista 1</th>
                        <th>P. lista 2</th>
                        <th>P. lista 3</th>
                        <th>P. lista 4</th>
                        <th>Facturación</th>
                        <th style = {{ width: "10%"}}>Lista Asig.</th>
                        <th style = {{ width: "10%"}}>Factura</th>
                    </tr>
                </thead>
                <tbody>
                    { 
                        (products?.length > 0) 
                            ? products.map( (p, i) => 
                            ( <tr key={i}>
                                <td>
                                <h6 className='useradmin__name'>{ p.name }</h6>
                                </td>
                                <td>{(p.isDolar)? 'USD ' : '$'}{ p.price0 }</td>
                                <td>{(p.isDolar)? 'USD ' : '$'}{ p.price1 }</td>
                                <td>{(p.isDolar)? 'USD ' : '$'}{ p.price2 }</td>
                                <td>{(p.isDolar)? 'USD ' : '$'}{ p.price3 }</td>
                                <td>{(p.isDolar)? 'USD ' : '$'}{ p.price4 }</td>    
                                <td>{ p.bill }</td>                    
                                <td>
                                    <Form>
                                        <Form.Control 
                                            type="number"
                                            placeholder="P. asignado"
                                            name= 'listaPrecios'
                                            value = { listadoDePrecios[p.id] || -1 }
                                            onChange={ (e) => handleArrayChange(p.id, e) }
                                            pattern="[0-4]{1}"
                                        />
                                    </Form>
                                </td>
                                <td>
                                    <Form>
                                        <Form.Control 
                                            type="number"
                                            placeholder="Facturación"
                                            name= 'bill'
                                            value = { listadoDeBill[p.id] ||'0' }
                                            onChange={ (e) => handleBillChange(p.id, e) }
                                            pattern="^[1-9][0-9]?$|^100$"
                                        />
                                    </Form>
                                </td>    
                            </tr>
                                
                            ))
                            : (<></>)
                    }
                </tbody>
            </Table>
    </Modal.Body>


    <Modal.Footer className="flex-column">
        <div>
            <Button variant="primary" 
                    type="submit" 
                    onClick=  { handleSubmit } 
                    disabled={ isLoading }>
                Guardar
            </Button>
            <Button variant="info" onClick={onHide} tabIndex={9} className='ms-2'>
                Cerrar
            </Button>
        </div>
    </Modal.Footer>
    </Modal>
  )
}
