import React, { useEffect } from 'react'
import { Tabs, Tab } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getAllProducts } from '../../actions/productsActions';

import { EstadisticaProdu } from './components/EstadisticaProdu';
import { EstadisticaCliente } from './components/EstadisticaCliente';
import { getUsersAll } from '../../actions/usersActions';
import { EstadisticaTodos } from './components/EstadisticaTodos';



export const EstadisticasAdmin = () => {

    const dispatch = useDispatch();

    useEffect( ()=> {

        dispatch( getUsersAll());
        dispatch( getAllProducts() );

    }, [ dispatch ])


  return (
      <>
        <h1 className="usuarios_title">Listado de Pedidos</h1>
        <hr/>

        <Tabs defaultActiveKey="Todos" id="uncontrolled-tab-example" className="mb-3" unmountOnExit={ true }>
            <Tab eventKey="Clientes" title="Clientes" style={{ color: 'black !important' }}>
                <EstadisticaCliente/>
            </Tab>
            <Tab eventKey="Productos" title="Productos" style={{ color: 'black !important'}}>
                <EstadisticaProdu />
            </Tab>
            <Tab eventKey="Todos" title="Todos" style={{ color: 'black !important' }}>
                <EstadisticaTodos/>
            </Tab>
        </Tabs>
      </>
  )
}
