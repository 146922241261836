import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { NavBarC } from '../pages/dashboard/components/NavBar';
import { ProductosScreen } from '../pages/dashboard/ProductosScreen';
import { ProductosAdmin } from '../pages/admin/ProductosAdmin';
import { UsuarioAdmin } from '../pages/admin/UsuarioAdmin';
import { PedidosAdmin } from '../pages/admin/PedidosAdmin';
import { EstadisticasAdmin } from '../pages/admin/EstadisticasAdmin';
import { MapaAdmin } from '../pages/admin/MapaAdmin';
import { OrderDetailsPrint } from '../pages/admin/components/OrdersDetailPrint';
import { CategoriasAdmin } from '../pages/admin/CategoriasAdmin';
import { useSelector, useDispatch } from 'react-redux';
import { getDolarCustom, getDolarNacion } from '../actions/dolarActions';
import { DashboardRoute } from './DashboardRoute';
import { PrivateRoute } from './PrivateRoute';
import { HomeScreen } from '../pages/dashboard/HomeScreen';

export const HomeRouter = () => {

  const dispatch = useDispatch();

  const { user, isAuth } = useSelector( state => state.auth );
  
  dispatch( getDolarNacion());    
  dispatch( getDolarCustom());

  // if( isAuth ) {
  //   return <Navigate to="/productos" />
  // }

  return (
      // <div className="no-print">
      <div>
        <NavBarC />
          <Routes>
              {/* <Route path="/productosdestacados" element={<ProductosDescatadosScreen />} /> */}              
              <Route path="/productos" element={<ProductosScreen />} />
              <Route index element={<HomeScreen />} />     
              <Route path="/*" element={ 
                    <PrivateRoute>
                        <DashboardRoute />
                     </PrivateRoute>
                } /> 
          </Routes>        
      </div>
  )
}