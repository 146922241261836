import React from 'react';
import ReactDOM from 'react-dom';
import { Fijamon } from './Fijamon';

import './styles/sass-style.scss';
import 'mapbox-gl/dist/mapbox-gl.css';

ReactDOM.render(
    <Fijamon />,
  document.getElementById('root')
);


// "devDependencies": {
//   "@babel/preset-react": "^7.16.7"
// }