
import { useState, useEffect } from 'react';
import { Button, FormControl, InputGroup, Modal, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateDolarCustom } from '../../../actions/dolarActions';

export const OptionDolar = ({ show, onHide}) => {

    // const { isDolar } = useSelector( state => state.products );
    
    const dispatch = useDispatch();
    
    const dolar = useSelector( state => state.dolar );
    const [ valorPersonalizado, setValorPersonalizado ] = useState(dolar.dolarCustom?.dolar || dolar.dolarNacion);    
    
    const [ isDolar, setIsDolar ] = useState(false);

    const handleChangeDolar = (e) => {
      setIsDolar( e );
    }

    useEffect(() => {
      const value = dolar.dolarCustom?.isDolar || false;
      setIsDolar(value);
      setValorPersonalizado(dolar.dolarCustom?.dolar || dolar.dolarNacion);
    }, [dolar]);

    const handleChangeValorPersonalizado = ({ target }) => {
      setValorPersonalizado(Number(target.value));
    }

    const onSave = () => {
      
      const dolar = {
        isDolar: isDolar,
        dolar: Number(parseFloat(valorPersonalizado).toFixed(2))
      }
      dispatch( updateDolarCustom( dolar ));
      onHide();
    }

  return (
    <Modal show={show} onHide={onHide} size="md">
      <Modal.Header closeButton>
            <Modal.Title>Dolar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className='text-align-right'>Dolar Nación ${ dolar.dolarNacion }</h6>
          <hr/>
          <ToggleButtonGroup 
              type="radio" 
              name="isDolar" 
              value = { isDolar }
              onChange={ handleChangeDolar }
              className="mb-2">
                  <ToggleButton
                      id={`radio-1`}
                      // type="radio"
                      variant='outline-secondary'
                      value={ true }>
                      Dolar Personalizado
                  </ToggleButton>
                  <ToggleButton
                      id={`radio-2`}
                      // type="radio"
                      variant='outline-info'
                      value={ false }>
                      Dolar Banco Nación
                  </ToggleButton>

          </ToggleButtonGroup>
          <br/>
          <h6 style={{ backgroundColor: '#e38421', padding: '0.6em', color: 'white'}}>{(isDolar)? 'El dolar asignado es el personalizado': 'El dolar asignado es el banco nación'}</h6>
          <br/>
          <h6 className="products__add-title">Detalle del Producto</h6>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon2">
                        <i className="fa-solid fa-file-medical"></i>
                    </InputGroup.Text>
                    <FormControl
                        tabIndex={2}
                        placeholder="Valor Personalizado"
                        aria-label="Valor Personalizado"
                        aria-describedby="basic-addon2"      
                        className='auth__input-placeholder'
                        type='number'
                        step='.01'
                        min='0'
                        name="valor"                          
                        value={ valorPersonalizado || '' }
                        onChange={ handleChangeValorPersonalizado }           
                        pattern="^\d*(\.\d{1,2})?$"                         
                    />
                </InputGroup>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onSave}>Guardar</Button>
        </Modal.Footer>
    </Modal>
    
  );
};