import { Carousel, Modal } from 'react-bootstrap'
import React from 'react'
import { useSelector } from 'react-redux';

const baseUrl = process.env.REACT_APP_PHOTO_URL;

export const DetailProduct = ({ show, onHide, price}) => {

    const { productSelect: product} = useSelector( state => state.products );
    
    const { isAuth } = useSelector( state => state.auth );
    return (
        <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
        <Modal.Title>Producto</Modal.Title>
        </Modal.Header>


        <Modal.Body>

            <Carousel fade variant="dark">
                {
                    ( product?.photo.length > 0) 
                    ? product?.photo.map( (image, index) => ( 
                        <Carousel.Item key={index} className="d-flex justify-content-center">
                            {( image.url.includes('.mp4') )
                                ? ( <video width="100%" height="300px" autoPlay loop>
                                        <source src={ baseUrl + '/' + image.url } type="video/mp4"/>
                                    </video> )
                                : (<img src = { baseUrl + '/' + image.url } alt= { image.id } height="300px"/>)
                            }
                        </Carousel.Item>
                    ))
                    : <Carousel.Item>
                        <img src = 'assets/images/no-available.jpg' alt = { product?.id}/>
                      </Carousel.Item>
                }
            </Carousel>

            <div>
                <h3 className="detail-product__name-product">{ product?.name }</h3>
                <p className="detail-product__description">{ product?.description }</p>
                <p className="detail-product__quantity">Cantidad por bolsa: { product?.quantity }</p>

                {
                    ( isAuth ) && ( 
                        <p className="detail-product__price">Precio: $ { (price / product?.quantity)?.toLocaleString('es-Ar') }</p>
                    )
                }
            </div>

        </Modal.Body>
        <Modal.Footer>
                <button className='btn btn-outline-danger' onClick={ onHide }>Cerrar</button>
        </Modal.Footer>        
        </Modal>
    )
}
