import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { removeProductCart, updateProductCart } from '../../../actions/cartActions';
import { updateProductSelected } from '../../../actions/productsActions';
import { DetailProduct } from './DetailProduct';
import { fetchConToken } from '../../../helpers/fetch';
import Swal from 'sweetalert2';

export const ListProducto = ({ product, title, subtitle, price, pack, image, bill, dolar }) => {
    const { isAuth } = useSelector( state => state.auth );
    const { products } = useSelector( state => state.carts );

    const [showDetailProduct, setShowDetailProduct] = useState(false);

    const dispatch = useDispatch();

    const handleShowDetailClose = () => { 
        dispatch(updateProductSelected(null));
        setShowDetailProduct(false);
    }

    const handleShowDetail = ( product ) => {
        dispatch(updateProductSelected(product));
        setShowDetailProduct(true);
    }

    const handleAddProduct = async() => {
        try {                        
            const resp = await fetchConToken(`products/${ product.id }`);
            const { product: productDB } = await resp.json();
            // TODO: ver el productDB que es el que viene de la Base de DAtos
            const productCart = calcularProducto( product, +1);
            // if( productDB.stock < productCart.cant) {
            //     throw new Error('No se puede agregar el producto porque no hay stock')
            // }
                //dispatch( addProductCart( productCart ) );            
            if( productCart.product.isDolar && productCart.price <= 0) {
                Swal.fire({
                    title: 'Error',
                    text: 'No se puede leer el precio del dolar, comuníquese con nosotros.',
                    icon: 'error'
                })        
            } else {
                dispatch( updateProductCart( productCart));       
            }
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error,
                icon: 'error'
            })
        }
    }

    const handleSubProduct = ( product ) => {

        const productCart = calcularProducto( product, -1 );

        if( productCart.cant <= 0) {
            dispatch( removeProductCart( productCart));
        } else {
            //dispatch( subProductCart( productCart))
            dispatch( updateProductCart( productCart))
        }
        
    }

    const calcularProducto = ( product, cant ) => { 
        let productCart = {};
        let productInCart = products?.find( p => p.product.id === product.id );
        if( productInCart ) {
            productCart = { ...productInCart,  cant: parseInt( productInCart.cant) + cant, bill: '0%' };
        } else {
            productCart = { product, cant: 1, price: price, bill: '0%'}
        }

        return productCart
    }

  return (
    <>
        <div className="list__main pointer">
          <div className="list__movie-img pointer" onClick={ () => handleShowDetail( product ) }>            
            {( image.includes('.mp4')) 
                ? <video className="list__backimg" autoPlay>
                    <source src={image} type="video/mp4" />
                  </video>
                : (<div className="list__backimg" style={{ 
                                backgroundImage: `url("${ image }")`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                }}>

                </div>)
            }
            </div>
            <div className='list__init-card'>
                <h6>{title}</h6>
                {/* <p className="card__description">{ subtitle }</p>  */}
                {(isAuth) && (
                    <>                    
                        <small className='list__prices-price ms-2 mt-2'>$ { (Math.round(Number(price / pack )))?.toLocaleString('es-Ar') }</small>
                    </>
                )}        
                {/* <small className="list__show-detail-text pointer" onClick={ () => handleShowDetail( product ) }>más info ...</small>                  */}
                <p className="card__description mt-2">Pack por { pack } unidades</p>
                {
                    (isAuth) && (
                        <div className="card__price-total-contenedor">
                            <p className="card__description mt-2"> Precio: </p>     
                            <p className="card__price-total"> ${ price?.toLocaleString('es-Ar') }</p>   
                        </div>
                    )
                }       
            </div>            
            <div className="list__botonera mb-2">
                {( isAuth ) && (
                    <>
                        <h6 className="card__description mt-2 text-center"> cant de cajas</h6>    
                        <div className='d-flex justify-content-center align-items-center flex-column'>
                            <div className="row">
                                <i className="list__boton-add-sub fa-solid fa-circle-arrow-up mb-1"
                                onClick= {() => handleAddProduct( product ) }></i>
                            </div>
                            <div className="row">
                                { (products?.find( p => p.product?.id === product.id ))?.cant || 0 }
                            </div>
                            <div className="row">
                                <i className="list__boton-add-sub fa-solid fa-circle-arrow-down mt-1"
                                onClick = { () => handleSubProduct( product ) } ></i>
                            </div>
                        </div>
                    </>
                )}
            </div>
            { (isAuth ) && (
                <div className="mt-3 list__facturacion">
                    <p className="list__facturacion">Facturación</p>
                    <p className="list__facturacion">{ bill }%</p>
                </div>
            )}
        </div>

        <DetailProduct show={showDetailProduct} onHide={handleShowDetailClose} price = { price }/>
    </>
  )
}
