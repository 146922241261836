
import React from 'react'

export const Policy = () => {
  return (
    <div class="m-3 pb-5 pt-2">
        <h1 class="text-center">Fijamom</h1>
        <h2 class="text-center mb-3">TÉRMINOS Y CONDICIONES</h2>
<p class="text-muted">Agustín Rodriguez, persona jurídica de derecho privado describe, a través de este documento, las normas de uso del sitio https://www.fijamom.com.ar y de la app Fijamon.

Al navegar por este sitio web o usar la aplicación movil, consideramos que está de acuerdo con las condiciones de uso que figuran a continuación.

Si no está de acuerdo con los términos de este acuerdo, le pedimos que no haga ningún otro uso de este sitio web, mucho menos que se registre o envíe sus datos personales.

Si cambiamos nuestras condiciones de uso, publicaremos el nuevo texto en este sitio web, con una fecha de revisión actualizada. Podemos modificar este documento en cualquier momento.

Si hay un cambio significativo en los términos de este acuerdo, podemos informarle utilizando la información de contacto que tenemos en nuestra base de datos o mediante una notificación.

El uso de este sitio web después de los cambios significa que usted acepta las condiciones de uso revisadas. Si, después de leer la versión revisada, no está de acuerdo con sus términos, por favor, termine su acceso.
</p>

<h3>Usuario: </h3>
<p class="text-muted">
El uso de este sitio web o la aplicación, le otorga automáticamente la condición de Usuario e implica su plena aceptación de todas las directrices y condiciones incluidas en estas Condiciones.
</p>

<h3>Adhesión junto con la política de privacidad: </h3>
<p class="text-muted">
El uso de este sitio web o la aplicación, implica la adhesión a estas Condiciones de Uso y a la versión más actualizada de la Política de Privacidad de Fijamom.
</p>

<h3>Condiciones de acceso: </h3>
<p class="text-muted">
En general, el acceso al sitio web de Fijamom es gratuito y no requiere registro previo.
Sin embargo, para hacer uso de algunas funcionalidades y la aplicación, el usuario necesitara registrarse, creando una cuenta de usuario con un nombre de usuario y una contraseña de acceso.
Es responsabilidad del usuario proporcionar únicamente información correcta, auténtica, válida, completa y actualizada, así como no revelar su nombre de usuario y contraseña a terceros.
</p>

<h3>Propiedad intelectual: </h3>
<p class="text-muted">
Todos los elementos de Fijamom son propiedad intelectual de la misma o de sus licenciantes. Estos términos o el uso del sitio web no le otorgan ninguna licencia o derecho de uso de los derechos de propiedad intelectual de Fijamom o de cualquier tercero.
</p>

<h3>Enlaces a sitios web de terceros: </h3>
<p class="text-muted">
Este sitio web puede contener, de vez en cuando, enlaces de hipertexto que le redirigirán a sitios web de nuestros socios, anunciantes, proveedores, etc. Si hace clic en uno de estos enlaces a cualquiera de estos sitios, recuerde que cada sitio tiene sus propias prácticas de privacidad y que nosotros no somos responsables de estas políticas. Por favor, consulte esas políticas antes de enviar cualquier dato personal a esos sitios.
</p>
<p class="text-muted">No somos responsables de las políticas y prácticas de recopilación, uso y divulgación (incluidas las prácticas de protección de datos) de otras organizaciones, como Facebook, Apple, Google, Microsoft, o cualquier otro desarrollador de software o proveedor de aplicaciones, tienda de medios sociales, sistema operativo, proveedor de servicios de internet o fabricante de dispositivos, incluidos los Datos Personales que usted divulgue a otras organizaciones a través de las aplicaciones, en relación con dichas aplicaciones, o publicados en nuestras redes sociales. Le recomendamos que se informe sobre la política de privacidad y las condiciones de uso de cada sitio web visitado o proveedor de servicios utilizado.
</p>

<h3>Exclusión de Responsabilidad:</h3>
<p class="text-muted">
    El titular de Fijamom se esfuerza por proporcionar información precisa y actualizada en el sitio web. Sin embargo, no garantizamos ni asumimos responsabilidad por la exactitud, integridad o actualidad de la información presentada. El uso del sitio web es bajo su propio riesgo. El titular no se hace responsable por cualquier pérdida, daño, inconveniente o consecuencia que pueda surgir del uso de este sitio web, incluyendo, pero no limitado a, la confiabilidad de la información presentada, la interrupción del acceso, errores u omisiones.
</p>
<p class="text-muted">
    Le recomendamos que ejerza su propio juicio y discreción al confiar en la información proporcionada en este sitio web. Antes de tomar decisiones basadas en dicha información, le sugerimos verificarla a través de fuentes adicionales y consultar a profesionales calificados cuando sea necesario.
</p>

<h3>Plazos y modificaciones: </h3>
<p class="text-muted">
El funcionamiento de este sitio web y la aplicación es por tiempo indefinido.
La totalidad del sitio web o cada una de sus secciones podrá ser cerrada, suspendida o interrumpida unilateralmente por Fijamom, en cualquier momento y sin previo aviso.
</p>

<h3>Datos personales: </h3>
<p class="text-muted">
Durante el uso de este sitio web, ciertos datos personales serán recogidos y procesados por Fijamom y/o los Socios. Las normas relativas al tratamiento de los datos personales de Fijamom están estipuladas en la Política de Privacidad.
</p>

<h3>Jurisdicción y Ley Aplicable: </h3>
<p class="text-muted">
    Estos Términos y Condiciones se rigen e interpretan de acuerdo con las leyes de la República Argentina, excluyendo cualquier disposición sobre conflictos de leyes. Al acceder y utilizar el sitio web, usted acepta que cualquier disputa o acción legal relacionada con estos Términos y Condiciones estará sujeta a la jurisdicción exclusiva de los tribunales competentes de la República Argentina. Además, al utilizar Fijamom o comunicarse con nosotros directamente, acepta incondicionalmente esta jurisdicción y la aplicación de las leyes de la República Argentina.
</p>

<h3>Contacto: </h3>
<p class="text-muted">
Si tiene alguna pregunta sobre las condiciones de uso, por favor póngase en contacto con nosotros por correo electrónico <a href="mailto:fijamom@gmail.com">fijamom@gmail.com</a> .
</p>

<h3>Derecho de Modificación: </h3>
<p class="text-muted">
El titular de Fijamom se reserva el derecho de realizar modificaciones y actualizaciones a estos Términos y Condiciones en cualquier momento y a su propia discreción. Cualquier cambio realizado será publicado en este sitio web y tendrá efecto inmediato a partir de la fecha de publicación. Los usuarios serán notificados sobre los cambios mediante notificaciones en el sitio web o a través de comunicaciones por correo electrónico proporcionadas por el usuario. Es responsabilidad del usuario revisar periódicamente estos Términos y Condiciones para mantenerse informado sobre las actualizaciones.
</p>
<p class="text-muted">
El uso continuado del sitio web después de la implementación de los cambios implica la aceptación de las versiones revisadas de los Términos y Condiciones. Si un usuario no está de acuerdo con las modificaciones realizadas, se le recomienda no continuar utilizando el sitio web y, en su lugar, terminar su acceso.
</p>
    </div>
  )
}
