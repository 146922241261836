
import { types } from '../types/types';
const initialState = { 
    products: [],
    // product: null,
    productSelect: null,
    categories: [],
    categorySelect: null,
    dolar: null,
    productsPrint: null,
};

export const productReducer = (state = initialState, action) => { 

    switch( action.type ) {
        case types.productsGetAll:
            return {
                ...state,
                products: [ ...action.payload.products]
            }
        // case types.productGet:
        //     return {
        //         ...state,
        //         product: action.payload.product
        //     }
        case types.productsAdd:
            return {
                ...state,
                products: [
                    ...state.products,
                    action.payload.product
                ]
            }
        case types.productsUpdate:
            return {
                ...state,
                products: state.products.map( product => {
                    if( product.id === action.payload.product.id ) {
                        return action.payload.product;
                    }
                    return product;
                })
            }
        case types.productsDelete:
            return {
                ...state,
                products: state.products.filter( product => product.id !== action.payload.product.id)
            }
        case types.productsUpdateSelected:
            return {
                ...state,
                productSelect: action.payload.product
            }
        case types.photoDelete:
            return {
                ...state,                
                productSelect: {
                    ...state.productSelect,
                    photo: state.productSelect.photo.filter( photo => photo.id !== action.payload.id )
                },
                products: state.products.map( product => {
                    if( product.id === state.productSelect.id ) {
                        return {
                            ...product,
                            photo: product.photo.filter( photo => photo.id !== action.payload.id )
                        }
                    }
                    return product;
                })
            }
        case types.categoriesGetAll:
            return {
                ...state,
                categories: [ ...action.payload.categories]
            }
        case types.categoriesAdd:
            return {
                ...state,
                categories: [ ...state.categories, action.payload.category ]
            }
        case types.categoriesUpdate:
            return {
                ...state,
                categories: state.categories.map( category => ( category.id === action.payload.category.id ) ? action.payload.category : category )
            }
        case types.categoryUpdateSelected:
            return {
                ...state,
                categorySelect: action.payload.category
            }
        case types.dolarSet:
            return {
                ...state,
                dolar: action.payload.dolar
            }
        case types.productPrint:
            return {
                ...state,
                productsPrint: action.payload.products
            }
        default:
            return state;
    }

}