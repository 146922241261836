import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProducts, updateProductSelected } from '../../actions/productsActions';
import { InputGroup, FormControl, Table } from 'react-bootstrap';
import { AddStockProduct } from './components/AddStockProduct';
import { MaxMinStockProduct } from './components/MaxMinStockProduct';

export const StockAdmin = () => {
    const [ productsFilter, setProductsFilter ] = useState([]);
    const [ sortUp, setSortUp] = useState(false);
    // add or sub stock
    const [ isAdd, setIsAdd] = useState(true);

    const dispatch = useDispatch();

    const { products } = useSelector( state => state.products);
    // const { isLoading } = useSelector( state => state.ui);

    const [showAddStock, setShowAddStock] = useState(false);
    const [showMaxMin, setShowMaxMin] = useState(false);

    const handleClose = () => { 
        dispatch( updateProductSelected(null) );
        setShowAddStock(false); 
    }

    const handleCloseMaxMin = () => {
        dispatch( updateProductSelected(null) );
        setShowMaxMin(false); 
    }

    // const handleShow = () => setShowAddStock(true);

    useEffect(  ( ) => {
        dispatch( getAllProducts() );
    }, [ dispatch ] );

    useEffect( ( ) => {
        setProductsFilter( products.sort( (a,b) => { 
            if(a.stock < b.stock) {
                return -1;
            }
            return 1;
        }));
      } , [ products ] );
      
    const handleAddStock = ( product ) => { 
        dispatch( updateProductSelected(product) );
        setIsAdd(true);
        setShowAddStock(true);
    } 

    const handleSubStock = ( product ) => { 
        dispatch( updateProductSelected(product) );
        setIsAdd(false);
        setShowAddStock(true);
    }

    const handleMaxMin = ( product ) => { 
        dispatch( updateProductSelected(product) );
        setShowMaxMin(true);
    }

    const filterList = ({ target }) => {
        const { value } = target;
        if( value?.length > 0 ) {
            setProductsFilter( products.filter( p => p.name.toLowerCase().includes(value.toLowerCase()) ) );
            handleSort();
        } else {
            setProductsFilter( products );
            handleSort();
        }
    }

    const handleSort = () => {
        setSortUp(!sortUp);
        if( !sortUp ){
            productsFilter.sort((a, b) => {
                if (a.stock > b.stock) {
                    return -1;
                }
                return 1 
            });
        } else {
            productsFilter.sort((a, b) => {
                if (a.stock < b.stock) {
                    return -1;
                }
                return 1
            });
        }        
    }

    return (
        <>
        <h1 className="usuarios_title">Listado de Productos</h1>
            <hr/>
            <div className="d-flex justify-content-center">
                <div className='w75'>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><i className='fas fa-search'></i></InputGroup.Text>
                        <FormControl
                            placeholder="Buscar...."
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            onChange={ filterList }
                        />
                    </InputGroup>
                </div>
            </div>
            <hr/>
            <div>
                <div className="d-inline d-flex justify-content-start align-items-center mb-1 ms-2">
                    <div className="product__color-box" style={{ backgroundColor: '#7FDDD5'}}></div>
                    <small className='ms-1'>Stock entre mínimo e ideal</small>
                </div>
                <div className="d-inline d-flex justify-content-start align-items-center ms-2">
                    <div className="product__color-box" style={{ backgroundColor: '#EBADA1'}}></div>
                    <small className='ms-1 me-2'>Stock debajo del mínimo   </small>
                </div>
            </div>
            <hr/>

            <Table bordered={false} hover>
                <thead>
                    <tr className="components__table-header">
                        <th style = {{ width: "50%"}}>
                        Nombre y detalle                         
                        </th>
                        <th>
                            Stock
                            {
                                ( sortUp )
                                ? <i className='fas fa-arrow-up ms-2 pointer text-end' onClick={ handleSort }></i>
                                : <i className='fas fa-arrow-down ms-2 pointer text-end' onClick={ handleSort }></i>
                            }
                        </th>
                        <th>Stock mínimo</th>
                        <th>Stock ideal</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    { 
                        (productsFilter?.length > 0) 
                            ? productsFilter.map( (p, i) => 
                            (<tr key={i} 
                                 style={{ backgroundColor: (p.stock < p.maxStock)? ((p.stock < p.minStock ) ? '#EBADA1' : '#7FDDD5') : 'transparent' }}>
                                <td>
                                    <h6>{ p.name }</h6>
                                    <p>{ p.description }</p>
                                </td>
                                <td>
                                    <div className="row">
                                        <div className="col-6 text-end">{ p.stock }</div>                                                   
                                    </div>
                                </td>                   
                                <td>{ p.minStock}</td>
                                <td>{ p.maxStock}</td>
                                <td>
                                    <div 
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Editar Producto"
                                        className="components__boton-action components__boton-action-edit"
                                        onClick = { () => handleAddStock( p )}>
                                        <i className="fas fa-plus text-inverse"></i>
                                    </div>
                                    <div 
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Editar Producto"
                                        className="components__boton-action components__boton-action-edit"
                                        onClick = { () => handleSubStock( p )}>
                                        <i className="fas fa-minus text-inverse"></i>
                                    </div>
                                    <div 
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Editar Producto"
                                        className="components__boton-action components__boton-action-edit"
                                        onClick = { () => handleMaxMin( p )}>
                                        <i className="fas fa-up-right-from-square"></i>
                                    </div>
                                </td>    
                            </tr>))
                            : (<></>)
                    }
                </tbody>
            </Table>
            <AddStockProduct show={showAddStock} onHide={handleClose} isAdd={ isAdd } />
            <MaxMinStockProduct show={showMaxMin} onHide={handleCloseMaxMin} />
        </>
    )
}
