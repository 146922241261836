
import Swal from 'sweetalert2';
import { fetchConToken } from '../helpers/fetch';
import { types } from '../types/types';

export const getDolarNacion = () => { 
    return async( dispatch ) => {
        try{            
            // const body = await fetch('https://apiarg.herokuapp.com/api/dolaroficial');
            // const { venta } = await body.json();            
            // dispatch(updateDolarNacionStore( venta ));
        } catch (error) {
            // Swal.fire({
            //     title: 'Error',
            //     text: `Hubo un error al obtener el dolar nación`,
            //     icon: 'error',
            // })
        }
    }
};

export const getDolarCustom = () => { 
    return async( dispatch ) => {
        try{
            const resp = await fetchConToken(`products/preference/`);
            const body = await resp.json();                  
            dispatch(updateDolarCustomStore( body.dolar ));
        } catch (error) {
            // Swal.fire({
            //     title: 'Error',
            //     text: `Hubo un error al obtener el dolar personalizado`,
            //     icon: 'error',
            // })
        }
    }
};

export const updateDolarCustom = ( dolar ) => {
    return async( dispatch ) => {
        try{
            const resp = await fetchConToken(`products/preference/dolar`, dolar, 'PUT');
            const body = await resp.json();
            dispatch(updateDolarCustomStore( body.dolar ));
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: `Hubo un error al actualizar el dolar personalizado`,
                icon: 'error',
            })
        }
    }
};

export const updateDolarNacionStore = (dolarNacion) => ({ 
    type: types.updateDolarNacion,
    payload: { dolarNacion }
});

export const updateDolarCustomStore = ( dolarCustom ) => ({ 
    type: types.updateDolarCustom,
    payload: { dolarCustom }
});
