
import { fetchSinToken, fetchConToken, fetchConResetToken } from '../helpers/fetch';
import { types } from '../types/types';
import { finishLoading, startLoading } from './uiActions';

import Swal from 'sweetalert2';

export const startLogin = ( email, password, navigate ) => { 
    return async( dispatch ) => { 
        dispatch( startLoading() );
        const endpoint = 'auth/login';
        
        try{
            const resp = await fetchSinToken( endpoint, { email, password }, 'POST' );
            const body = await resp.json();            
    
            if( body.ok === true ) { 

                if( !body.user.status ) {
                    Swal.fire(
                        'Error', 
                        'El usuario aún no esta habilitado', 
                        'error'
                    );
                    return;
                }

                localStorage.setItem( 'token', body.token );
                dispatch( login( body.user ) );
                // dispatch(navigate( '/' ));
            } else {
                Swal.fire(
                    'Error', 
                    'Usuario o contraseña incorrectos', 
                    'error'
                );
            }
            dispatch( finishLoading() );
            dispatch( startChecking() );
        } catch( error) {
            Swal.fire(
                'Error',
                `${error.message}`,
                'error'
            )
            dispatch( finishLoading() );
        }

    }
}

export const forgetPassword = ( email ) => { 
    return async( dispatch ) => { 
        dispatch( startLoading() );
        const endpoint = 'auth/forget-password';
        
        try{
            const resp = await fetchSinToken( endpoint, { email }, 'PUT' );
            const body = await resp.json();            
    
            if( body.ok === true ) { 

                Swal.fire(
                    'Cambio de password', 
                    'Chequee su email, si no encuentra un mail revise la casilla de spam, siga los pasos, recuerde que tiene 10 minutos para hacer el cambio.', 
                    'info'
                );
                
            } else {
                Swal.fire(
                    'Error', 
                    'Hubo un error en el cambio de password, chequee estar registrado', 
                    'error'
                );
            }
            dispatch( finishLoading() );
        } catch( error) {
            Swal.fire(
                'Error',
                `${error.message}`,
                'error'
            )
            dispatch( finishLoading() );
        }

    }
}

export const renewPassword = ( password, resetToken ) => { 
    return async( dispatch ) => { 
        dispatch( startLoading() );
        const endpoint = 'auth/new-password';
        
        try{
            const resp = await fetchConResetToken( endpoint, { password }, resetToken, 'PUT' );
            const body = await resp.json();            
    
            if( body.ok === true ) { 

                Swal.fire(
                    'Su password fue cambiada', 
                    'Ya puede loguear con su nueva password.', 
                    'info'
                );
                
            } else {
                Swal.fire(
                    'Error', 
                    'Hubo un error en el cambio de password.', 
                    'error'
                );
            }
            dispatch( finishLoading() );
        } catch( error) {
            Swal.fire(
                'Error',
                `${error.message}`,
                'error'
            )
            dispatch( finishLoading() );
        }

    }
}

export const startChecking = ( navigate ) => { 
    return async( dispatch ) => { 
        
        try {
            
            dispatch( startLoading() );
            const resp = await fetchConToken( 'auth/renew' );
            const body = await resp.json();
            if( body.ok === true ) {
                localStorage.setItem( 'token', body.token );
                dispatch( login( body.user ) );
            } else {
                throw new Error( body.msg );
            }
            dispatch( finishLoading() );
        } catch (error) {
            localStorage.removeItem( 'token' );
            // navigate('auth/login');
            dispatch( finishLoading() );
        }
    }
}

export const login = ( user ) => { 
    return {
        type: types.login,
        payload: { user }
    }
}

export const logout = ( user ) => { 
    return {
        type: types.logout,
    }
}

export const addUserAuthOrder = ( order ) => ({ 
    type: types.userAuthAddOrder,
    payload: {
        order  
    }
})

export const updateUserAuthOrder = ( order ) => ({ 
    type: types.userAuthUpdateOrder,
    payload: {
        order
    }
})