import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup, Table } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersFilter, clearOrders } from '../../../actions/orderActions';
import { updateUserSelect } from '../../../actions/usersActions';

import Select from 'react-select';

import es from "date-fns/locale/es";
import moment from 'moment';
registerLocale("es", es);

export const EstadisticaCliente = () => {

    const dispatch = useDispatch();
    let total = 0;
    let totalPrice = 0;

    const { orders } = useSelector( state => state.orders );

    const [ Filter, setFilter ] = useState('');
    const [ ordersFilter, setOrdersFilter ] = useState([]);
    const [ sortUp, setSortUp] = useState(true);

    const { users } = useSelector( state => state.users );
    const { userSelect: user} = useSelector( state => state.users );    

    const [ startDateUser, setStartDateUser ] = useState( new Date('01/01/2022') );
    const [ endDateUser, setEndDateUser ] = useState( new Date() );

    //const { productSelect: product } = useSelector( state => state.products );

    useEffect(() => {
        return () => {
            dispatch( clearOrders() );
            dispatch( updateUserSelect( null ));
        };
    }, [ dispatch ]);

    useEffect(() => { 

        setOrdersFilter( [...orders.filter(
                        order => ( order.productOrder.find(p => p.product.name.toLowerCase().includes(Filter?.toLowerCase()) ))
                        )]);        

    }, [ Filter, orders, setOrdersFilter, dispatch ]);

    useEffect(() => {
        if(user){
            // 0 para que filtre por clientes
            dispatch( getOrdersFilter({ id: user.id, datestart: startDateUser, dateend: endDateUser, type:'0' }) );
        }
    } , [ startDateUser, endDateUser, dispatch, user ]);

    const handleChange = ({ target }) => {
        setFilter(target.value);
    }

    const calculaTotal = () => {
        total = ordersFilter?.map(
            ( p ) => p.productOrder?.map( e => e.quantity).reduce((p, c) => p + c, 0))
            .reduce( (p, c) => p + c, 0);       
        totalPrice = ordersFilter?.map(
            ( p ) => p.productOrder?.map( e => e.price).reduce((p, c) => p + c, 0))
            .reduce( (p, c) => p + c, 0);       
    }

    calculaTotal();

    const handleSelectListado = (e) => {    
        const u = users.filter( user => user.id === e )[0];
        dispatch( updateUserSelect( u ) );
    }

    const handleSelectDateInit = (date) => {
        if( moment(date).isAfter( endDateUser )){
            setStartDateUser( moment(endDateUser).startOf('month').toDate() );
            setEndDateUser( moment(date).endOf('month').toDate() );
        } else {
            setStartDateUser(moment(date).startOf('month').toDate());
        }
    }

    const handleSelectDateEnd = (date) => {
        if( moment(date).isBefore( startDateUser )){
            setStartDateUser( moment(date).startOf('month').toDate() );
            setEndDateUser( moment(startDateUser).endOf('month').toDate() );
        } else {
            setEndDateUser(moment(date).endOf('month').toDate());
        }
    }

    const handleSort = () => {  
        setSortUp(!sortUp);
        let produ = ordersFilter.productOrder;
        if( !sortUp ){
            produ = orders[0].productOrder.sort((a, b) => {
                if (a.quantity > b.quantity) {
                    return -1;
                }
                return 1
                });        
        } else {
            produ = orders[0].productOrder.sort((a, b) => {
                if (a.quantity < b.quantity) {
                    return -1;
                }
                return 1
                });  
        }        
        const ord = [ ...ordersFilter ];
        ord[0].productOrder = produ;
        setOrdersFilter( ord );
    }
    
    const options = users.map( u => ({ label: u.nameMarkets, value: u.id }));

  return (
    <>
        <div className='w75'>
            <h5>Cantidad vendida: { total || '' }</h5>
        </div>
        <div className="d-flex justify-content-center">
            <div className='w75'>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1"><i className='fas fa-search'></i></InputGroup.Text>
                    <FormControl
                        placeholder="Buscar...."
                        aria-label="Search"
                        aria-describedby="basic-addon1"
                        onChange={ handleChange }
                    />
                </InputGroup>
            </div>
        </div>

        <div className="offset-md-3 col-md-6">
            <Select options={options} 
                    onChange={ (e) => handleSelectListado( e.value ) }
                    placeholder="Seleccione Cliente" />
            {/* <h6 className='estadisticas__text-clientes me-4'>Listado de Clientes</h6> */}

            {/* <DropdownButton className='estadisticas__cbo-clientes' 
                            title={ `${ user?.nameMarkets || 'Seleccione Cliente' }` } 
                            onSelect={ (e) => handleSelectListado( e ) }>
                {
                    users?.map( (u, i) => {
                        return <div key={i}>
                                    <Dropdown.Item eventKey={ u.id } value>{ u.nameMarkets }</Dropdown.Item>
                                </div>
                    })
                }
            </DropdownButton> */}
        </div>

        <div className="d-flex justify-content-center mt-3">
            <div className="row mb-3">
                <div className="col-md-6">                                
                    <DatePicker         
                        showPopperArrow={true}                          
                        locale = {es}                    
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        selected={startDateUser} 
                        onChange={(date) => handleSelectDateInit( date )} />
                </div>
                <div className="col-md-6">
                    <DatePicker 
                        showPopperArrow={true}                                          
                        locale = {es}                    
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        selected={endDateUser} 
                        onChange={(date) => handleSelectDateEnd(moment(date).add(1, 'months').subtract(1, 'days').toDate())} />                                
                </div>
            </div>
        </div>
        <div>
            <Table striped bordered={false} hover>
                <thead>
                    <tr className="components__table-header">
                        <th>Pedido</th>
                        {/* <th>Mes - Año</th> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        ordersFilter.map( (order, i) => { 
                            return <tr key={i}>
                                        <td>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th className="w75">Producto</th>
                                                        <th>
                                                            Cantidad
                                                            {
                                                                ( sortUp )
                                                                ? <i className='fas fa-arrow-up ms-2 pointer' onClick={ handleSort }></i>
                                                                : <i className='fas fa-arrow-down ms-2 pointer' onClick={ handleSort }></i>
                                                            }                                 
                                                        </th>
                                                        <th className="text-end pe-5">Precio Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                            {   order.productOrder?.map( (p, i ) => {
                                                    return <tr key={i}>

                                                                <td>{ p.product.name }</td>
                                                                <td>{ p.quantity }</td>
                                                                <td className="text-end pe-5">{ p.price?.toLocaleString('es-Ar', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }</td>
                                                            </tr>
                                                })
                                            }
                                                    {   (totalPrice > 0 || total > 0) &&
                                                        (<tr className="estadisticas__totales-table">
                                                            <td>TOTALES</td>
                                                            <td>{ total }</td>
                                                            <td className="text-end pe-5">{ totalPrice?.toLocaleString('es-Ar', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }</td>
                                                        </tr>)
                                                    }
                                                </tbody>
                                            </Table>
                                        </td>
                                        {/* <td>{ order.productOrder?.find( e => e.productId === product.id)?.quantity }</td> */}
                                        {/* <td>{ order.productOrder?.find( e => e.productId === product.id)?.price }</td>                                          */}
                                        {/* <td> { moment(order.createdAt).format('MMMM') + ' - ' + moment(order.createdAt).format('yyyy') }</td> */}
                                    </tr>
                        })
                    }
                </tbody>
            </Table>    
        </div>
    </>
  )
}
