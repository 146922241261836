import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Tab, Table, Tabs} from 'react-bootstrap';
import { confirmBuy } from '../../../actions/cartActions';

import * as moment from 'moment';
import localization from 'moment/locale/es'


export const CartProducto = ({ calcularPrecio }) => {

    const [total, setTotal] = useState(0);

    const { isLoading } = useSelector(state => state.ui);

    const { products } = useSelector( state => state.carts );
    const { user } = useSelector( state => state.auth );

    const dispatch = useDispatch();

    useEffect(() => { 
        let subtotal = 0;
        products.forEach( p => {
            subtotal += calcularPrecio(p.product) * p.cant;
        })
        setTotal(subtotal);
        
    }, [products, calcularPrecio]);

    const handleBuy = () => {
        const order = { order: { status: 'PENDIENTE', userId: user.id, priceTotal: total }, products: products };

        //TODO: VER CAMBIO DE DOLAR CUANDO COMPRA
        dispatch( confirmBuy( order, user ));        

    }

    // const handleBillEvent = (e, p) => {
    //     p.bill = e;
    //     dispatch( updateProductCart( p ));
    // }


  return (
    <>

    <Tabs defaultActiveKey="Pedidos" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="Pedidos" title="Pedido" style={{ color: 'black !important' }}>                    
            <div className="cart__main">
                
                <div className='cart__header-container'>
                    <h6 className="cart__title-total">Total: ${ total?.toLocaleString('es-Ar') }</h6>
                    <button className='cart__button btn btn-outline-primary' 
                        disabled = { !(products.length > 0) || isLoading}
                        onClick={ handleBuy }>
                    
                        <i className="fa-solid fa-cart-arrow-down cart__header-icon"></i>
                        Comprar
                    </button>
                </div>
                
                <Table striped bordered={false} hover className="cart__table">
                    <thead>
                        <tr className="components__table-header">
                            <th style = {{ width: "50%"}}>Nombre</th>
                            <th>Cantidad</th>
                            <th>Sub Total</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        products.map( (p, i) => (
                            <tr className="cart__product" key={i}>
                                <td>{ p.product.name }</td>
                                <td>{ p.cant}</td>
                                <td className='text-end pe-5'>${ (calcularPrecio(p.product) * p.cant)?.toLocaleString('es-Ar', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }</td>                                
                            </tr>

                        ))
                    }
                    </tbody>
                </Table>  
            </div>
        </Tab>
        { user?.order.length > 0 && 
            <Tab eventKey="Pendientes" title="Pedidos Pendientes" style={{ color: 'black !important' }}>     

                { user.order?.map( (order, i) => ( 
                    <div className="cart__main" key={i}>
                
                        <div className='cart__header-container-pendiente'>
                            <p> {moment(order.createdAt).locale('es', localization).format('DD MMMM yyyy')}</p>                           
                            <h6 className="cart__title-total-pendiente">Total: ${ order.priceTotal?.toLocaleString('es-Ar') }</h6> 
                        </div>
                        
                        <Table striped bordered={false} hover className="cart__table">
                            <thead>
                                <tr className="components__table-header">
                                    <th style = {{ width: "50%"}}>Nombre</th>
                                    <th>Cantidad</th>
                                    <th>Sub Total</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                order.productOrder?.map( (p, i) => (
                                    <tr className="cart__product" key={i}>
                                        <td>{ p.product.name }</td>
                                        <td>{ p.quantity}</td>
                                        <td>{ p.price?.toLocaleString('es-Ar') }</td>
                                    </tr>

                                ))
                            }
                            </tbody>
                        </Table>  
                    </div>
                )) } 
                
            </Tab>
            }
    </Tabs>
    </>
  )
}
