import validator from 'validator';

export const isFormValidRegistroUpdate = ( dispatch, values, setError, removeError ) => {

    const { nameMarkets, nameContact, email, street, num, city, cp } = values;

    if( nameMarkets.trim().length === 0 ){
      dispatch(setError( 'Nombre del negocio es requerido' ));
      return false;
    } else if( nameContact.trim().length === 0 ){
      dispatch(setError( 'Nombre del contacto es requerido' ));
      return false;
    } else if( !validator.isEmail(email) ){
      dispatch(setError('Email es requerido'));      
      return false;
    } else if( street.trim().length === 0 ) {
        dispatch(setError('Calle es requerida'));
        return false;
    } else if( num.trim().length === 0 ) {
        dispatch( setError('Altura de la calle es requerida'));
        return false;
    } else if( city.trim().length === 0 ) {
        dispatch( setError('Ciudad es requerida'));
        return false;
    } else if( cp.trim().length === 0 ) {
        dispatch( setError('Código postal es requerido'));
        return false;
    } else if( values.whatsapp.length <= 0 ) {
        dispatch( setError('Debe contener 1 Whatsapp de contacto'));
        return false;
    } else {           
        try {
            values.whatsapp.map( value => {
                if( !value.num.match(/^[0-9]{10}$/) ){
                    dispatch( setError('Debe ser un número de teléfono valido') );
                    throw Error();
                }    
                return true;
            });
            dispatch(removeError());
            return true;    
        }
        catch(e) { 
            return false;
        }
    }
}