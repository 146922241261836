import React, { useEffect, useState } from 'react';
import { Form, FormControl, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories, getAllProducts, getAllProductsStore } from '../../actions/productsActions';
import { ListProducto } from './components/ListProducto';
import { CartProducto } from './components/CartProducto';
import { clearCart } from '../../actions/cartActions';
import { ListNovedades } from './components/ListNovedades';

const baseUrl = process.env.REACT_APP_PHOTO_URL;

export const ProductosScreen = () => {
  const [search, setsearch] = useState('');

  const dispatch = useDispatch();

  const { products } = useSelector( state => state.products);
  const dolar = useSelector( state => state.dolar);
  const { user } = useSelector( state => state.auth);

  const [ category, setCategory] = useState('TODOS');
  const [ productsFilter, setProductsFilter] = useState([]);

  const { categories } = useSelector( state => state.products);

  useEffect(  ( ) => {
    
    dispatch( getAllCategories() );
    dispatch( getAllProducts() );

    return () => {
      dispatch( getAllProductsStore([]));
      dispatch( clearCart());
    }

  }, [ dispatch, setProductsFilter ] );

  useEffect( ( ) => {
    setProductsFilter( products.filter( p => p.priority >=0 ) );
  }, [ products ] );

  

  const calcularPrecio = ( produ ) => {
    
    let precio = 0;
    const p = user?.productPrice.find( price => price.productId === produ.id );
    
    if ( p ) { 
      switch( Number(p.price) ) {
        case -1:
          if( user?.listaDefault) {
            switch( user?.listaDefault ) {
              case 1:
                precio = produ.price1 * produ.quantity;
                break;
              case 2:
                precio = produ.price2 * produ.quantity;
                break;
              case 3:
                precio = produ.price3 * produ.quantity;
                break;
              case 4:
                precio = produ.price4 * produ.quantity;
                break;
              default:
                precio = produ.price4 * produ.quantity;
            }
          }
          break;
        case 0:
          precio = produ.price0 * produ.quantity;
          break;
        case 1:
          precio = produ.price1 * produ.quantity;
          break;
        case 2:
          precio = produ.price2 * produ.quantity;
          break;
        case 3:
          precio = produ.price3 * produ.quantity;
          break;
        case 4:
          precio = produ.price4 * produ.quantity;
          break;
        default:
          precio = produ.price4 * produ.quantity;
      }
    } else if ( user?.listaDefault ) {
      switch( Number(user?.listaDefault) ) {
        case 0:
          precio = produ.price0 * produ.quantity;
          break;
        case 1:
          precio = produ.price1 * produ.quantity;
          break;
        case 2:
          precio = produ.price2 * produ.quantity;
          break;
        case 3:
          precio = produ.price3 * produ.quantity;
          break;
        case 4:
          precio = produ.price4 * produ.quantity;
          break;
        default:
          precio = produ.price4 * produ.quantity;
      }
    } else{
      precio = produ.price4 * produ.quantity;
    }
    if( produ.isDolar) {
      if( dolar ) {
        if( dolar.dolarCustom?.isDolar || false ) {          
          precio *= dolar.dolarCustom?.dolar || dolar.dolarNacion;
        } else {
          precio *= dolar.dolarNacion;
        }
      }
  }
    return precio;
  }

  const handleSelect = (e, b) => {
    setCategory(e);
    if( e === 'TODOS' ) {
      setProductsFilter( products.filter( p => Number( p.priority ) >= 0) );
    } else if ( e === 'NOVEDADES') {
      setProductsFilter( products.filter( p => Number( p.priority ) === -99 ));
    } else {
      setProductsFilter( products.filter( product => product.category.some(c => c.name === e) && Number( product.priority ) >= 0) );
    }
  } 


  return (
    <>
      <Form className="d-flex m-4">
          <FormControl
            type="search"
            placeholder="Buscar producto ...."
            className="me-2"
            aria-label="Search"
            value={ search }
            onChange={ e => setsearch( e.target.value ) }
          />
          <Button variant="outline-success">Buscar</Button>
          
      </Form>
      {/* <h1 className="productos__title">Todos los Productos</h1> */}
      <div>
        <div className='row m-5'>
          <div className='col-md-3 mt-2'>
            <Button className= { (category === 'TODOS')? 'product__botonera-select product__botonera' : 'product__botonera'}
                  onClick={ () => handleSelect('TODOS') }
            >
              Todos
            </Button>
          </div>
          { categories && (
            categories.map(( c,i) => (
              <div className='col-md-3 mt-2' key={i}>
                <Button className={ (category === c.name)? 'product__botonera-select product__botonera' : 'product__botonera'}
                        onClick={ () => handleSelect(c.name) }>
                  { c.name }
                </Button>
              </div>
            ))
          )}
          <div className='col-md-12 mt-2'>
            <Button className= { (category === 'NOVEDADES')? 'product__botonera-select product__botonera' : 'product__botonera'}
                  onClick={ () => handleSelect('NOVEDADES') }
            >
              NOVEDADES
            </Button>
          </div>
        </div>

      </div>

      <hr/>
      <div className="container-fluid">
        <div className="row pe-md-5 ps-md-1">   
        { ( user )
          ? (
            <div className='col-md-4 animate__animated animate__fadeInLeft'>
              <CartProducto calcularPrecio={ calcularPrecio }/>
            </div>
            )
          : ( <div className='col-md-3'>              
            </div>
            )          
        }
        <div className='col-md-8'>      
          
            { 
              (productsFilter?.length > 0) && (        
                productsFilter.filter( p => {
                  let produ = p.name?.toLowerCase().includes( search?.toLowerCase() ) 
                                    
                  return produ;
                }).map( (p, i) => (
                    ( category === 'NOVEDADES') 
                      ? 
                        <ListNovedades 
                          key={ i }
                          product={ p }
                          title={p.name}
                          subtitle = {p.description}
                          image= { p.photo?.length > 0 ? baseUrl +'/' + p.photo[0].url : 'assets/images/no-available.jpg' }
                        />
                      :
                        <ListProducto 
                          key={ i }
                          product={ p }
                          title={p.name}
                          subtitle = {p.description}
                          price={ calcularPrecio(p) }
                          pack = { p.quantity }
                          bill = { user?.productPrice?.find( pp => pp.productId === p.id )?.bill || p.bill || '100' }
                          image= { p.photo?.length > 0 ? baseUrl +'/' + p.photo[0].url : 'assets/images/no-available.jpg' }
                          dolar={ dolar }
                        />
                  
                  ))
                  )
                }
          </div>
        </div>
      </div>
    </>
  )
}

// <CardProducto 
//   title={p.name}
//   subtitle={p.description}
//   priceUnit={ parseFloat(p.price1 / p.quantity).toFixed(2) }
//   pack={ p.quantity }
//   price={ calcularPrecio(p) }
//   image= { p.photo?.length > 0 ? baseUrl +'/' + p.photo[0].url : 'assets/images/no-available.jpg' }
//   key={i}/>