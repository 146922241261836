import React, { useState } from 'react'
import { Button, InputGroup, FormControl, Form} from 'react-bootstrap';

export const FormUsuario = ({ values, handleInputChange, handleArrayChange, handleArrayAdd, handleArrayRemove, handleCheckDeleteWsp, isRegistro = false }) => {
   
    const [showPass1, setshowPass1] = useState(false);
    const [showPass2, setshowPass2] = useState(false);

    const { nameMarkets, nameContact, email, password, password2, send, street, num, city, cp, province, openingsDayHours} =  values;

    const handleSubWsp = ( index ) => {
        if( !isRegistro ) {
            handleCheckDeleteWsp( values.whatsapp[index].num.trim(), index );
        } else {
            handleArrayRemove( index );
        }
    }

  return (
    <>                       
        <Form className='auth__form'>

                <div className="row">
                    <div className="col-md-6">
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                                <i className="fa-solid fa-shop auth__input-icon"></i>
                            </InputGroup.Text>
                            <FormControl
                                placeholder="Nombre del Negocio"
                                aria-label="Nombre-Negocio"
                                aria-describedby="basic-addon1"      
                                className='auth__input-placeholder'
                                name="nameMarkets"  
                                value={ nameMarkets }
                                onChange={ handleInputChange }                                    
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                                <i className="fa-solid fa-user auth__input-icon"></i>
                            </InputGroup.Text>
                            <FormControl
                                placeholder="Nombre del contacto"
                                aria-label="Nombre-Contacto"
                                aria-describedby="basic-addon2"
                                className='auth__input-placeholder'
                                name="nameContact"
                                value={ nameContact }
                                onChange={ handleInputChange }  
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon3">
                                <i className="fa-solid fa-at auth__input-icon"></i>
                            </InputGroup.Text>
                            <FormControl
                                placeholder="Correo"
                                aria-label="Correo"
                                aria-describedby="basic-addon3"
                                className='auth__input-placeholder'
                                name="email"
                                value={email}
                                onChange={ handleInputChange }  
                            />
                        </InputGroup>
                        {(isRegistro) && (
                            <>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon4">
                                        <i className="fa-solid fa-shield-halved auth__input-icon"></i>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Password"
                                        type={ (showPass1) ? "text" : "password" }
                                        aria-label="Password"
                                        aria-describedby="basic-addon4"
                                        className='auth__input-placeholder'
                                        name="password"   
                                        value={password}
                                        onChange={ handleInputChange }                                           
                                    />
                                    <InputGroup.Text id="basic-addon4" className="pointer" onClick={ () => setshowPass1( !showPass1 ) }>
                                        <i className="fa-solid fa-eye auth__input-icon"></i>
                                    </InputGroup.Text>
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon5">
                                        <i className="fa-solid fa-shield-halved auth__input-icon"></i>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Re-Password"
                                        type={ (showPass2) ? "text" : "password" }
                                        aria-label="Password2"
                                        aria-describedby="basic-addon5"
                                        className='auth__input-placeholder'
                                        name="password2"   
                                        value={password2}
                                        onChange={ handleInputChange }                                           
                                    />
                                    <InputGroup.Text id="basic-addon5" className="pointer" onClick={ () => setshowPass2( !showPass2 ) }>
                                        <i className="fa-solid fa-eye auth__input-icon"></i>
                                    </InputGroup.Text>
                                </InputGroup>
                            </>
                        )}
                        
                    </div>
                    <div className="col-md-6">
                    <div className='row'>
                            <div className='col-md-8'>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon6">
                                        <i className="fa-solid fa-road auth__input-icon"></i>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Calle"
                                        type="text"
                                        aria-label="Calle"
                                        aria-describedby="basic-addon6"
                                        className='auth__input-placeholder'
                                        name="street"   
                                        value={street}
                                        onChange={ handleInputChange }                                           
                                    />
                                </InputGroup>
                            </div>
                            <div className='col-md-4'>
                                <InputGroup className="mb-3">
                                    <FormControl
                                        placeholder="Num"
                                        type="text"
                                        aria-label="Num"
                                        aria-describedby="basic-addon7"
                                        className='auth__input-placeholder'
                                        name="num"   
                                        value={num}
                                        onChange={ handleInputChange }                                           
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-8'>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon8">
                                        <i className="fa-solid fa-city auth__input-icon"></i>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Localidad"
                                        type="text"
                                        aria-label="Ciudad"
                                        aria-describedby="basic-addon8"
                                        className='auth__input-placeholder'
                                        name="city"   
                                        value={city}
                                        onChange={ handleInputChange }                                           
                                    />
                                </InputGroup>
                            </div>
                            <div className='col-md-4'>
                                <InputGroup className="mb-3">
                                    <FormControl
                                        placeholder="Cod Postal"
                                        type="text"
                                        aria-label="Codigo Postal"
                                        aria-describedby="basic-addon9"
                                        className='auth__input-placeholder'
                                        name="cp"   
                                        value={cp}
                                        onChange={ handleInputChange }                                           
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon10">
                                <i className="fa-solid fa-earth-americas auth__input-icon"></i>
                            </InputGroup.Text>
                            <FormControl
                                placeholder="Provincia"
                                aria-label="Provincia"
                                aria-describedby="basic-addon10"
                                className='auth__input-placeholder'
                                name="province"
                                value={province}
                                onChange={ handleInputChange }  
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon11">
                                <i className="fas fa-stopwatch auth__input-icon"></i>
                            </InputGroup.Text>
                            <FormControl
                                placeholder="L a V 10:00 a 12:00 y 15:00 a 20:00 - S 10:00 a 13:00"
                                aria-label="Horario"
                                aria-describedby="basic-addon11"
                                className='auth__input-placeholder'
                                name="openingsDayHours"
                                value={openingsDayHours}
                                onChange={ handleInputChange }  
                            />
                        </InputGroup>
                        <InputGroup className="mb-1">
                            <InputGroup.Text id="basic-addon11">
                                <i className="fa-solid fa-truck-fast auth__input-icon"></i>
                            </InputGroup.Text>
                            <FormControl
                                placeholder="* Nombre de expresos, poner 2 o 3"
                                aria-label="Envio"
                                aria-describedby="basic-addon11"
                                className='auth__input-placeholder'
                                name="send"
                                value={send}
                                onChange={ handleInputChange }  
                            />
                        </InputGroup>                        
                                                            
                    </div>
                </div>
                <hr /> 
                <div className='row'>
                        { values.whatsapp?.map((value, index) => (                                                             
                            <div className="col-md-6" key = { index }>
                                <InputGroup className="mb-6 auth__input-wsp">
                                    <InputGroup.Text id="basic-addon11">
                                        <i className="fa-brands fa-whatsapp auth__input-icon"></i>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="** WhatsApp de contacto"
                                        aria-label="Wsp1"
                                        aria-describedby="basic-addon11"
                                        className='auth__input-placeholder'
                                        name="whatsapp"
                                        value = { value.num }
                                        onChange = { (e) => handleArrayChange(index, e.target) }
                                    />
                                    <Button variant='primary' 
                                            onClick = { handleArrayAdd }
                                    >
                                        +
                                    </Button>
                                    <Button variant='danger'
                                            onClick = { () => handleSubWsp( index ) }
                                    >
                                        -
                                    </Button>
                                    <br/>
                                </InputGroup>
                            </div>
                        ))}
                </div>
                
                
        </Form>
    </>
  )
}
