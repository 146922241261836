
import { types } from '../types/types';

const initialState = { 
    products: [],
};

export const CartReducer = ( state = initialState, action ) => { 
    
    switch( action.type ) { 
        case types.cartAddProduct:
            return { 
                ...state,
                products: 
                    ( state.products?.find( p => p.product?.id === action.payload.product.product.id ) ) 
                        ? (                            
                            state.products?.map( pro => {
                                if (pro.product?.id === action.payload.product.product.id) {
                                    return action.payload.product;
                                } else {
                                    return pro
                                }
                            })
                        )
                        : [
                            ...state.products,
                            ( action.payload.product)
                        ]                                   
            }
        case types.cartSubProduct:
            return {
                ...state,
                products: state.products?.map( pro => {
                    if (pro.product?.id === action.payload.product.product.id) {
                        return action.payload.product;
                    } else {
                        return pro
                    }
                })
            }
        case types.cartRemoveProduct:
            return {
                ...state,
                products: state.products?.filter( pro => (pro.product?.id !== action.payload.product.product.id))
            }
        case types.cartUpdateProduct:
            return {
                ...state,
                products: ( state.products?.find( p => p.product?.id === action.payload.product.product.id ) ) 
                ? (                            
                    state.products?.map( pro => {
                        if (pro.product?.id === action.payload.product.product.id) {
                            return action.payload.product;
                        } else {
                            return pro
                        }
                    })
                )
                : [
                    ...state.products,
                    ( action.payload.product)
                ]                                   
            }
        case types.cartClear:
            
            return {
                ...state,
                products: []
            }
        default:
            return state;
    }
}