import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { updateCateInProduct } from '../../../actions/productsActions';

export const SetCategory = ({ show, onHide }) => {

  const dispatch = useDispatch();

  const { categories } = useSelector( state => state.products );
  const { isLoading } = useSelector( state => state.ui );
  const { productSelect } = useSelector( state => state.products );

  const handleClick= ({ target }, id) => {   

    dispatch( updateCateInProduct( productSelect.id, { cateId: id, value: target.checked} ) );

  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Seleccione las categorias</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          categories.map( category => (
            <div key={category.id}>
              <Form>
                <Form.Check 
                  disabled = { isLoading }
                  style = {{ fontSize: '1.2em' }}
                  className = 'mb-3'
                  defaultChecked= { (productSelect?.category?.find( cate => cate.id === category.id )) ? true : false }
                  type="switch"
                  id="custom-switch"
                  label={category.name}
                  onChange = { (e) => handleClick(e, category.id) }
                />
              </Form>
            </div>
          ))
        }
      </Modal.Body>
    </Modal>
  )
}
