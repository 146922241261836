
import { types } from '../types/types';

export const startLoading = () => ({ 
    type: types.uiStartLoading
});

export const finishLoading = () => ({
    type: types.uiFinishLoading
});

export const setError = ( error ) => ({ 
    type: types.uiSetError,
    payload: { error }
});

export const removeError = () => ({ 
    type: types.uiRemoveError
});

export const showModal = ( modal ) => ({ 
    type: types.uiShowModal,
    payload: { modal }
});