
import Swal from 'sweetalert2';
import { fetchSinToken, fetchConToken } from '../helpers/fetch';
import { types } from '../types/types';
import { finishLoading, startLoading } from './uiActions';

export const createUser = (values) => {

    return async( dispatch ) => {
        dispatch( startLoading() );
        let values2 = {...values}; 
        delete values2.password2;       
        const resp = await fetchSinToken(
                'users', values2, 'POST');
        const body = await resp.json();
        if( body.ok ) { 
            await Swal.fire({
                title: 'Usuario creado',
                text: 'Recuerde que para poder loguearse antes debera esperar su activación por parte de un administrador',
                icon: 'success',
            });
        } else {
            Swal.fire({
                title: `Error`,
                text: (body.error?.meta?.target === 'User_email_key')? 'Email ya existe': 'Error desconocido',
                icon: 'error',
            });
        }
        dispatch( finishLoading() );        

    }

}

export const getUsersAll = () => { 
    return async( dispatch ) => { 
        try {
            const result = await fetchConToken('users/');
            const users = await result.json();
           

            dispatch( getUsersAllStore(users) );
        }
        catch (error) { 
            Swal.fire('Error', 'No se pudo cargar la lista de usuarios', 'error')
        }
    }
}

export const getUsersOrders = (status) => { 
    return async( dispatch ) => { 
        try {
            const result = await fetchConToken(`users/orders/${status}`);
            const users = await result.json();

            dispatch( getUsersAllStore( users ))
        }
        catch (error) { 
            Swal.fire('Error', 'No se pudo cargar la lista de usuarios', 'error')
        }
    }
}

const getUsersAllStore = ( users ) => { 
    return {
        type: types.usersGetAll,
        payload: {
            users
        }
    }
}

export const updateUser = (id, values, onHide) => {
    return async( dispatch ) => {
        try {
            const result = await fetchConToken(`users/${id}`, values, 'PUT');
            const body = await result.json();
            
            if( body.ok ) {
                dispatch( updateUserStore(id, body.user) );
                Swal.fire({
                    title: 'Usuario actualizado',
                    text: 'El usuario ha sido actualizado',
                    icon: 'success',
                });
                if(onHide) { onHide() }
            } else {
                Swal.fire({
                    title: `Error`,
                    text: 'Error desconocido',
                    icon: 'error',
                })
            }
        }
        catch (error) { 
            Swal.fire('Error', 'No se pudo actualizar el usuario', 'error')
        }
    }
}

export const updateUserStatus = (id, status) => { 
    return async( dispatch ) => {
        try {             
            const result = await fetchConToken(`users/${ id }/status`, { status }, 'PUT');
            const body = await result.json();
            
            if( body.ok ) { 
                dispatch( updateUserStatusSuccess( body.user ) );
            }
            else { 
                Swal.fire({
                    title: `Error`,
                    text: 'Error desconocido ' + body.error,
                    icon: 'error',
                })
            }
            
        }
        catch (error) {
            Swal.fire('Error', 'No se pudo cambiar el estado del usuario', 'error')
        }
    }
}

export const updateUserStatusAll = (status) => { 
    return async( dispatch ) => {
        dispatch( startLoading() );
        try {             
            const url = `users/status/${ status }`;
            const result = await fetchConToken( url, {}, 'PUT');
            const body = await result.json();            
            if( body.ok ) { 
                dispatch( updateUserStatusSuccessAll( body.users ) );
                Swal.fire({
                    title: `Usuarios`,
                    text: 'Estados cambiados',
                    icon: 'info',
                })
            }
            else { 
                Swal.fire({
                    title: `Error`,
                    text: 'Error desconocido ' + body.error,
                    icon: 'error',
                })
            }
            dispatch( finishLoading() );
        }
        catch (error) {
            Swal.fire('Error', 'No se pudo cambiar el estado de los usuarios', 'error')
            dispatch( finishLoading() );
        }
    }
}

export const updateUserProductList = ( id, listadoActual, data, onHide ) => {
    return async( dispatch ) => {
        try {

            const dataBack = { listadoActual, data};
            const result = await fetchConToken(`users/productlist/${ id }`, dataBack, 'PUT');
            const body = await result.json();

            if( body.ok ) { 
                dispatch( updateUserStore( id, body.user ) );
                onHide();
            } else { 
                Swal.fire({
                    title: `Error`,
                    text: 'Error desconocido ' + body.error,
                    icon: 'error',
                })
            }
        } catch (error) {
            Swal.fire('Error', 'No se pudo cambiar el estado del usuario', 'error')
        }
    }
}

export const changePassword = ( password, id ) => { 
    return async( dispatch ) => { 
        dispatch( startLoading() );
        const endpoint = `users/new-password/${ id }`;
        try{
            const resp = await fetchConToken( endpoint, { password }, 'PUT' );
            const body = await resp.json();            
    
            if( body.ok === true ) { 

                Swal.fire(
                    'El password fue cambiada', 
                    'Ya puede loguear con su nueva password.', 
                    'info'
                );
                
            } else {
                Swal.fire(
                    'Error', 
                    'Hubo un error en el cambio de password.', 
                    'error'
                );
            }
            dispatch( finishLoading() );
        } catch( error) {
            Swal.fire(
                'Error',
                `${error.message}`,
                'error'
            )
            dispatch( finishLoading() );
        }

    }
}

export const deleteUser = ( id ) => {
    return async( dispatch ) => { 
        try {

            const result = await fetchConToken(`users/${id}`, {}, 'DELETE');
            const body = await result.json();
            if( body.ok ) { 
                dispatch( deleteUserStore( id ) );
            }
            else { 
                Swal.fire({
                    title: `Error`,
                    text: 'Error desconocido ' + body.error,
                    icon: 'error',
                })
            }

        }catch( error ) {
            Swal.fire('Error', 'No se pudo eliminar el usuario', 'error')
        }
    }
}

export const deleteUserWsp = (userId, id) => { 
    return async( dispatch ) => { 
        try {

            const result = await fetchConToken(`users/wsp/${id}`, {}, 'DELETE');
            const body = await result.json();
            if( body.ok ) { 
                dispatch( deleteUserWspStore( userId, id ) );
            }
            else { 
                Swal.fire({
                    title: `Error`,
                    text: 'Error desconocido ' + body.error,
                    icon: 'error',
                })
            }

        }catch( error ) {
            Swal.fire('Error', 'No se pudo eliminar el whatsapp', 'error')
        }
    }
}

const updateUserStore = (id, user) => ({ 
    type: types.userUpdate,
    payload: {
        id,
        user
    }
});

export const updateUserSelect = ( user ) => ({
    type: types.userUpdateSelect,
    payload: {
        user
    }
})

const updateUserStatusSuccess = ( user ) => ({ 
    type: types.userUpdateStatus,
    payload: { 
        user
    }
});

const updateUserStatusSuccessAll = ( users ) => ({ 
    type: types.userUpdateStatusAll,
    payload: { 
        users
    }
});

const deleteUserStore = (id) => ({
    type: types.userDelete,
    payload: {
        id
    }
});

const deleteUserWspStore = (userId, id) => ({ 
    type: types.userDeleteWsp,
    payload: {
        userId,
        id 
    }
});

