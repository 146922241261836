
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const OrderTicket = ({show, onHide, print}) => {
    const { orderSelect: order } = useSelector((state) => state.orders);
    
    const handleLoadPrint = () => { 
        //setNoPrint(true);
        if( print ) {
            setTimeout(() => {
                console.log('entra en ticket');
                window.print();
                onHide();
            }, 200);
        }
    }

    useEffect(() => {
        handleLoadPrint();        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ print ] );

  return (
    // width: '10cm', height: '8cm', 
    <Modal show={show} onHide={onHide} size="lg" style={{ maxWidth: '100%', maxHeight: '100%' }}>
        <Modal.Header closeButton className='no-print'>
            <Modal.Title>Pedido</Modal.Title>
        </Modal.Header>
        {/* id="ModalEtiqueta"  */}
        <Modal.Body className="mb-5" style={{ width: '100%' }}>
            <div className="row d-flex justify-content-center align-items-center text-center">
                <h6>{ order?.user.nameMarkets }</h6><br/>
                <small>{ order?.user.street } - Número: { order?.user.num } </small><br/>
                <small>{ order?.user.city } - { order?.user.province }</small><br/>
                <p>{ order?.user.send }</p><br/>
                <hr></hr>
                <h6>Envía Fijamom</h6><br/>
                <small>Teléfono: 1157406507</small><br/>
                <small>Dirección: Angalgala 1666 (Mataderos)</small><br/>
            </div>
        </Modal.Body>
    </Modal>
  )
}
