
import { types } from '../types/types';
const initialState = { 
    orders: [],
    orderSelect: null,
    ordersPrint: [],
    ordersInMap: [],
};

export const OrderReducer = (state = initialState, action) => { 
    switch( action.type ) { 
        case types.ordersGetAll:
            return {
                ...state,
                orders: action.payload.orders,
            }
        case types.ordersUpdateState:
            return {
                ...state,
                orders: state.orders.map( order => ( order.id === action.payload.id ? action.payload.order : order ) )
            }
        case types.orderDelete: 
            return {
                ...state,
                orders: state.orders.filter( order => order.id !== action.payload.id)
            }
        case types.ordersSelectUpdate:
            return {
                ...state,
                orderSelect: action.payload.order,
            }
        case types.ordersClear:
            return { 
                ...state,
                orders: [],
                orderSelect: null,
            }
        case types.ordersPrint:
            return {
                ...state,
                ordersPrint: action.payload.orders
            }
        // In map
        case types.orderInMap:
            //let ordersIn =[...state.ordersInMap, action.payload.order];
            return {
                ...state,
                ordersInMap: [ ...state.ordersInMap, action.payload.order ]   
                // ordersInMap: [ ...state.ordersInMap, action.payload.order ]
            }
        case types.orderInMapClear:
            return {
                ...state,
                ordersInMap: [ ...state.ordersInMap.filter( order => order.id !== action.payload.id ) ]
            }
        case types.ordersInMapClear:
            return {
                ...state,
                ordersInMap: [],
            }
        default: 
            return state;
    }
}