import React from 'react'
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeError } from '../../../actions/uiActions';
import { setError } from './../../../actions/uiActions';
import { Modal, Form, FormControl, InputGroup, Button } from 'react-bootstrap';
import { addStock, subStock } from '../../../actions/productsActions';

export const AddStockProduct = ({show, onHide, isAdd}) => {
  
    const [ stock, setStock ] = useState(0); 
    const { productSelect } = useSelector( state => state.products );

    const dispatch = useDispatch();
    const { isLoading, error }= useSelector( state => state.ui ); 

    const handleSave = () => {         
        if( isAdd ){
            if( isFormValid()) {
                dispatch( addStock( productSelect.id, stock, onHide ) );
                setStock(0);
            }
        } else {
            if( isFormValid()) {
                dispatch( subStock( productSelect.id, stock, onHide ) );
                setStock(0);
            }
        }
    }

    const isFormValid = () => { 
    
        if( stock <= 0 ){            
            dispatch(setError( 'El stock debe ser un campo númerico mayor a 0' ));
            return false;
        } 
        // if( quantity.length === 0 ){            
        //     dispatch(setError( 'La cantidad del producto es requerida' ));
        //     return false;
        // } 
        dispatch(removeError());
        return true;
    }

  return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Stock en { productSelect?.name }</Modal.Title>
        </Modal.Header>


        <Modal.Body>
            {
                error && 
                (<div className='auth__alert-error'>
                    { error }
                </div>)
            }
            <Form className='auth__form'>                
                <hr/>
                <InputGroup className="mb-3">
                    <small className="products__add-title">Stock: </small>
                    <InputGroup.Text id="basic-addon2">
                        <i className="fa-solid fa-file-medical"></i>
                    </InputGroup.Text>
                    <FormControl
                        placeholder="Cantidad"
                        aria-label="Cantidad-Producto"
                        aria-describedby="basic-addon2"      
                        className='auth__input-placeholder'
                        type="number"
                        name="stock"
                        value={ stock }
                        onChange={ ({ target }) => setStock( target.value ) }                                    
                    />
                </InputGroup> 
               
            </Form>
        </Modal.Body>


        <Modal.Footer>
          <Button variant="info" onClick={onHide} tabIndex={9}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleSave} tabIndex={8} type="submit" disabled={isLoading}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
  )
}
