import React from 'react';
import { Provider } from 'react-redux';

import { store } from './store/store';
import { AppRouter } from './routers/AppRouter';

export const Fijamon = () => {
  return (
    <Provider store = { store }>
      {/* <BrowserRouter>     */}
        <AppRouter />
      {/* </BrowserRouter> */}
    </Provider>
  )
}
