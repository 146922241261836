import React, { useRef } from 'react';

import { Modal, Button, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { savePhoto, deletePhoto } from '../../../actions/productsActions';

const baseUrl = process.env.REACT_APP_PHOTO_URL;

export const AddUpdatePhoto = ({ show, onHide }) => {

    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const { productSelect } = useSelector( state => state.products );

    const handleUpload = () => {
        inputRef.current?.click();
      };

    const handleSave = ( file ) => { 
        dispatch( savePhoto( file, productSelect.id ) );
    }

    const handleDeleteImage = ( id ) => { 
        dispatch( deletePhoto( id ) );
    }

  return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Editar fotos del producto</Modal.Title>
        </Modal.Header>


        <Modal.Body>
            <div className='row'>
            <div className="m-3">
                <label className="mx-3">Sube una foto: </label>
                <input 
                    ref={inputRef} 
                    className="d-none" 
                    type="file" 
                    accept="image/gif, image/png, image/jpeg, image/jpg, video/mp4"
                    onChange={(e) => { handleSave(e.target.files[0]) }}
                />
                <button onClick={handleUpload} className="btn btn-outline-primary">
                    Upload
                </button>
            </div>

            </div>
            
                    {
                        (productSelect?.photo?.length > 0)
                        ? ( productSelect.photo.map( (photo, i) => (
                            <div className="row" key={i} >
                                <div className="col-md-10">                  
                                    <div className="products__photo-content">
                                        { ( photo.url.includes('.mp4') )
                                            ? ( <video width="100%" height="100%" autoPlay>
                                                    <source src={ baseUrl + '/' + photo.url } type="video/mp4"/>
                                                </video>)
                                            : 
                                            <Image                                    
                                                src={`${baseUrl}/${ photo.url }`}
                                                rounded
                                                thumbnail fluid
                                            />

                                        }
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <Button className = "m-3" variant="danger" onClick={ () => handleDeleteImage( photo.id ) }>-</Button>
                                </div>
                            </div>
                        )))
                        :(
                            <Image
                                src='assets/images/no-available.jpg'
                                rounded
                                thumbnail
                                />
                        )
                    }                
           
        </Modal.Body>


        <Modal.Footer>
          <Button variant="info" onClick={onHide} tabIndex={9}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
  )
}