import React, { useEffect, useState } from 'react'
import { Modal, InputGroup, Form, FormControl, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addCategory, updateCategory } from '../../../actions/productsActions';
import { useForm } from '../../../hooks/useForm';

const initialValue = {
    name: '',
    priority: 0
}

export const AddUpdateCategory = ({show, onHide}) => {

    const dispatch = useDispatch();

    const [values, handleInputChange, reset] = useForm( initialValue );
    const [ title, setTitle] = useState( 'Nueva Categoria' );

    const { isLoading, error } = useSelector( state => state.ui );
    const { categorySelect } = useSelector( state => state.products );

    useEffect( () => {
        if( categorySelect ) {
            let { name, priority } = categorySelect;
            reset( {name, priority });
            setTitle( 'Editar Categoria' );
            
        } else {
            reset( initialValue );
            setTitle( 'Nueva Categoria' );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    } ,[ categorySelect ] );

    const { name, priority } = values;

    const handleSave = () => {
        
        if( categorySelect ) {
            dispatch( updateCategory( categorySelect.id, values, onHide ) );
        } else {
            dispatch( addCategory( values ) );
            reset();
        }
    }

  return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
        <Modal.Title> { title } </Modal.Title>
        </Modal.Header>
            {
                error && 
                (<div className='auth__alert-error'>
                    { error }
                </div>)
            }
            <Form className='auth__form'>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                        <i className="fas fa-clone"></i>
                    </InputGroup.Text>
                    <FormControl
                        tabIndex={1}
                        placeholder="Nombre de la Categoria"
                        aria-label="Nombre-Categoria"
                        aria-describedby="basic-addon1"      
                        className='auth__input-placeholder'
                        name='name'                          
                        value={ name }
                        onChange={ handleInputChange }                                    
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon2">
                        <i className="fas fa-star"></i>
                    </InputGroup.Text>
                    <FormControl
                        tabIndex={1}
                        placeholder="Prioridad"
                        aria-label="Priority"
                        aria-describedby="basic-addon2"      
                        className='auth__input-placeholder'
                        type = "number"
                        name='priority'                          
                        value={ priority }
                        onChange={ handleInputChange }                                    
                    />
                </InputGroup>
            </Form>
        <Modal.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={onHide} tabIndex={9}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleSave} tabIndex={8} type="submit" disabled={isLoading}>
            Guardar
          </Button>
        </Modal.Footer>
    </Modal>
  )
}
