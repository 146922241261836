import React, { useEffect } from 'react'
import { FormUsuario } from '../../../components/FormUsuario';
import { useForm } from '../../../hooks/useForm';
import { Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { removeError, setError } from '../../../actions/uiActions';
import { deleteUserWsp, updateUser } from '../../../actions/usersActions';
import { isFormValidRegistroUpdate } from '../../../helpers/validarRegistroUpdate';
import Swal from 'sweetalert2';

let initialValue = {
    nameMarkets: '',
    nameContact: '',
    email: '',
    password: '',
    password2: '',
    send: '',
    street: '',
    num: '',
    city: '',
    cp: '',
    province: '',
    whatsapp: [{id: undefined, userId: null, num: ''}]
};

export const UpdateUser = ({ show, onHide }) => {

    const { error, isLoading } = useSelector( state => state.ui ); 

    const dispatch = useDispatch();

    const [ values, 
        handleInputChange, 
        reset,
        handleArrayChange, 
        handleArrayAdd, 
        handleArrayRemove ] = useForm(initialValue);

    let { userSelect: user } = useSelector( state => state.users );

    useEffect(() => {
        if( show ) {
            if( user ) {
                reset({
                    nameMarkets: user.nameMarkets,
                    nameContact: user.nameContact,
                    email: user.email,
                    send: user.send,
                    street: user.street,
                    num: user.num,
                    city: user.city,
                    cp: user.cp,
                    province: user.province,
                    // whatsapp: (user.whatsapp.length > 0)? user.whatsapp.map( c => c.num.toString()) : ['']
                    whatsapp: user.whatsapp.length > 0 ? user.whatsapp : [{ id: undefined, userId: null, num: ''}]
                });
            } else {
                onHide( true );
            }
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [ user ]);

    const handleSubmit = async (e) => { 
        if( isFormValidRegistroUpdate( dispatch, values, setError, removeError )) {            
            dispatch( updateUser(user.id, values, onHide ) );            
        }
    }

    const handleCheckDeleteWsp = (num, index) => {
        if( values.whatsapp.length <= 1) {
            Swal.fire({
                title: "¡Error!",
                text: "No puede eliminar todos los números de Whatsapp",
                icon: 'error',
            })
            return;
        }
        user.whatsapp.forEach( w => {
            if( w.num === num ) {
                dispatch( deleteUserWsp(user.id, w.id) );                
            }

        });
        handleArrayRemove( index );
    }

  return (
    <Modal show={show} onHide={onHide} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Editar usuario</Modal.Title>
    </Modal.Header>


    <Modal.Body>
            {
                    error && 
                    (<div className='auth__alert-error'>
                        { error }
                    </div>)
                }
        <FormUsuario 
                values = {values} 
                handleInputChange = {handleInputChange} 
                handleArrayChange = {handleArrayChange}
                handleArrayAdd = {handleArrayAdd}
                handleArrayRemove = {handleArrayRemove}
                handleCheckDeleteWsp = {handleCheckDeleteWsp}
            />
    </Modal.Body>


    <Modal.Footer className="flex-column">
        <div className='row'>
            <small>* Solo para clientes del interior.</small>
        </div>
        <div className='row'>
            <small>** Registre el Whatsapp sin el 0 y sin el 15.</small>
        </div>
        <div>
            <Button variant="primary" 
                    type="submit" 
                    onClick=  { handleSubmit } 
                    disabled={ isLoading }>
                Guardar
            </Button>
            <Button variant="info" onClick={onHide} tabIndex={9} className='ms-2'>
                Cerrar
            </Button>
        </div>
    </Modal.Footer>
    </Modal>
  )
}
