import { useState } from 'react';


export const useForm = ( initialState = {}, initialArray = [''] ) => {

    const [values, setValues] = useState(initialState);

    const reset = ( newFormState = initialState ) => {
        
        setValues({
            ...newFormState,            
        });
    }


    const handleInputChange = ({ target }) => {
        // Controlo que si es numerico parse el input a number
        if( target?.type === 'number' ) {
            // Si tiene la propiedad step lo parseo a float
            if( target.step ) { 
                setValues({
                    ...values,
                    [ target.name ]: parseFloat(target.value)
                });
            } else {
                setValues({
                    ...values,
                    [ target.name ]: parseInt(target.value)
                });
            }
        }else {
            setValues({
                ...values,
                [ target.name ]: target.value
            });
        }


    }

    const handleArrayChange = ( index, target ) => { 
        setValues({
            ...values,
            [ target.name ] : [...values.whatsapp.map( 
                (w, i) => {
                if(i === index ){
                    w.num = target.value
                    return w;
                }
                else {
                    return w; 
                }
            }
            )]
            // whatsapp: values.whatsapp.map( ( value, i ) => i === index ? target.value : value ) 
        })
    }

    const handleArrayAdd = () => { 
        setValues({
            ...values,  
            whatsapp: [
            ...values.whatsapp,
            {id: undefined, userId: null, num: '' }
        ]});
    }

    const handleArrayRemove = ( index ) => { 
        if( values.whatsapp ) {
            setValues({ 
                ...values,
                whatsapp: (values.whatsapp.length > 1 ) 
                             ? values.whatsapp.filter( ( value, i ) => i !== index ) 
                             : values.whatsapp = [{id: undefined, userId: null, num: ''}]
            });
        }
    }



    return [ values, 
             handleInputChange,
             reset,
             handleArrayChange,
             handleArrayAdd, 
             handleArrayRemove,
              ];

}