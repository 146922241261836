import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getAllCategories, updateCategorySelected } from '../../actions/productsActions';
import { Table } from 'react-bootstrap';
import { AddUpdateCategory } from './components/AddUpdateCategory';

export const CategoriasAdmin = () => {
  const dispatch = useDispatch();

  const { categories } = useSelector( state => state.products);

  const [showCategoryAddUpdate, setShowCategoryAddUpdate] = useState(false);


  const handleCategoryClose = () => {
    dispatch( updateCategorySelected( null ) );
    setShowCategoryAddUpdate(false);
  }

  const handleShowCategory = () => setShowCategoryAddUpdate(true);

  useEffect(  ( ) => {
    dispatch( getAllCategories() );
  }, [ dispatch ] );

  const handleEdit = ( cate ) => { 

    dispatch( updateCategorySelected(cate) );
    setShowCategoryAddUpdate(true);

  } 


  return (
    <>
      <h1 className="usuarios_title">Listado de Categorias</h1>
        <hr/>

        <div className='d-flex flex-row'>
          <div className="components__small-box pointer ml-3 d-flex justify-content-center align-items-center" 
               onClick={handleShowCategory}
               data-bs-toggle="tooltip" data-bs-placement="top" title="Nueva Categoria">
            <i className="fas fa-clone fa-3x"></i>
          </div>
        </div>

        <hr/>

        <Table striped bordered={false} hover>
            <thead>
                <tr className="components__table-header">
                    <th style = {{ width: "50%"}}>Nombre</th>
                    <th>Prioridad</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                { 
                    (categories?.length > 0) 
                        ? categories.map( (c, i) => 
                        (<tr key={i}>
                            <td>
                              <h6>{ c.name }</h6>
                            </td>
                            <td>{ c.priority }</td>                      
                            <td>
                              <div 
                                className="components__boton-action components__boton-action-edit"
                                onClick = { () => handleEdit( c )}>
                                  <i className="fas fa-pen text-inverse"></i>
                              </div>
                            </td>    
                        </tr>))
                        : (<></>)
                }
            </tbody>
        </Table>
        <AddUpdateCategory show={showCategoryAddUpdate} onHide={handleCategoryClose} />
    </>
  )
}
