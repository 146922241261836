import React from 'react'
import { Button, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const OrderDetailsPrint = ({show, onHide, style}) => {

    const { state } = useLocation();

    const { ordersPrint: orders } = useSelector( state => state.orders );

    const handlePrint = async() => { 
        window.print();
        
        // veo si existe porque puedo entrar por el mapa que si va a existir o por la página pedidos
        if( onHide ){
            onHide();
        }
    }
    

  return (
      <div style = {{ display: (show || state?.status ) ?'block': 'none' }} className="print">
            <div className='no-print'>
                <Button
                    className='no-print ms-4'
                    onClick={ handlePrint }
                >Imprimir</Button>
            </div>
            {orders?.map((order, id) => (
                <div key={id} className="print">
                    <div>
                        <div className="row ms-4 mt-4 me-4">
                            <div className="col-9">
                                <h5 className='order-detail__text-markets'>{ order?.user.nameMarkets }</h5>
                            </div>
                            {/* <div className="col-3">
                                <h5 className='order-detail__text-markets'>$ { order?.priceTotal }</h5>
                            </div> */}
                        </div>
                        <hr className='align-self-center order-detail__hr'/>

                        <h5 className="ms-4">Datos del contacto: </h5>
                        <div className="row ms-4 mt-1">
                            <div className="col-6">
                                <div className='row'>
                                    <h6 className='order-detail__text-name'>Nombre: { order?.user.nameContact }</h6>
                                </div>
                                <div className='row'>
                                    <h6 className='order-detail__text-name'>Email: { order?.user.email }</h6>
                                </div>
                                { order?.user.whatsapp?.length > 0 && (<h6>Whatsapp:</h6>)}
                                { order?.user.whatsapp?.map( (w, i) => 
                                    (
                                    <div className='row' key={i}>
                                        <h6 className='order-detail__text-name'>{ w.num }</h6>    
                                    </div>
                                    )        
                                )}
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <h6 className='order-detail__text-name'>Direccción:  { order?.user.street } - { order?.user.num }</h6>
                                </div>
                                <div className="row">
                                    <h6 className='order-detail__text-name'>Localidad: { order?.user.city }</h6>
                                </div>
                                {( order?.user.send.length > 0 ) && (
                                    <div className="row">
                                        <h6 className='order-detail__text-name'>Envio: { order?.user.send }</h6>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='p-3'>
                            <Table className="mb-0 table-b table-borderless">
                                <thead>
                                    <tr className='order__table-head-tr'>
                                        <th className="mb-0 mt-0 pb-0 pt-0 order__table-head w10"><small>Cantidad</small></th>
                                        <th className="mb-0 mt-0 pb-0 pt-0 order__table-head w10"><small>Cant x Pack</small></th>
                                        <th className="mb-0 mt-0 pb-0 pt-0 order__table-head"><small>Producto</small></th>
                                        <th className="mb-0 mt-0 pb-0 pt-0 order__table-head w10 no-print"><small>Precio</small></th>
                                        <th className="mb-0 mt-0 pb-0 pt-0 order__table-head w10 no-print"><small>Total</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                            {
                                order?.productOrder.map( (product, i) => (
                                    <tr key={i}>
                                        <td className="mb-0 mt-0 pb-0 pt-0"><small>{ product.quantity }</small></td>
                                        <td className="mb-0 mt-0 pb-0 pt-0"><small>{ product.product.quantity }</small></td>
                                        <td className="mb-0 mt-0 pb-0 pt-0"><small>{ product.product.name }</small></td>
                                        <td className="mb-0 mt-0 pb-0 pt-0 no-print text-end me-1"><small>{ product.price?.toLocaleString('es-Ar', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }</small></td>
                                        <td className="mb-0 mt-0 pb-0 pt-0 no-print text-end me-1"><small>{ (product.price * product.quantity)?.toLocaleString('es-Ar', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</small></td>
                                    </tr>
                                ))
                            }
                                </tbody>
                            </Table> 
                        </div>   
                    </div>
                    <div className='saltopagina'></div>
                </div>
            ))}        
    </div>
  )
}
