import { fetchConToken } from "../helpers/fetch";
import Swal from 'sweetalert2';
import { types } from '../types/types';


export const getAllOrders = () => {
    return async( dispatch ) => {

        try {
            const response = await fetchConToken('orders/', 'GET');            
            const body = await response.json();

            if( body.ok ) {
                dispatch(getAllOrdersStore( body.orders ));                
            }
            else {
                Swal.fire({
                    title: 'Error',
                    text: `No se pudo obtener los pedidos`,
                    icon: 'error',
                })
            }
        }
        catch( error ) {
            Swal.fire({
                title: 'Error',
                text: `No se pudo obtener los pedidos`,
                icon: 'error',
            })
        }

    }
}
export const getOrdersFilter = ( data ) => {
    return async( dispatch ) => {

        try {
            const response = await fetchConToken('orders/filter', data, 'POST');                      
            const body = await response.json();
            if( body.ok ) { 
                dispatch(getAllOrdersStore( body.orders ));                
            }
            else {
                Swal.fire({
                    title: 'Error',
                    text: `No se pudo obtener los pedidos`,
                    icon: 'error',
                })
            }
        }
        catch( error ) {
            
            Swal.fire({
                title: 'Error',
                text: `No se pudo obtener los pedidos`,
                icon: 'error',
            })
        }

    }
}

export const updateStateOrder = ( status, id) => {
    return async ( dispatch ) => { 
        try {
            const response = await fetchConToken(`orders/status/${ id }`, { status }, 'PUT');            
            const body = await response.json();
            if( body.ok ) {
                dispatch( updateStateOrderStore( body.order, id ) );
                dispatch( clearOneOrdersInMap( id));
            }
            else {
                Swal.fire({
                    title: 'Error',
                    text: body.error,
                    icon: 'error',
                })
            }
        }
        catch( error ) {
            Swal.fire({
                title: 'Error!',
                text: error,
                icon: 'error',
            })
        }
    }
}

export const updateOrdersStatusAll = ( orders, status ) => { 
    return async ( dispatch ) => { 
        try {
            const response = await fetchConToken(`orders/statusall/${ status }`, { orders }, 'PUT');
            
            const body = await response.json();
            
            if( body.ok ) {
                body.orders.map( order => {
                    dispatch( updateStateOrderStore( order, order.id ) );
                    dispatch( clearOneOrdersInMap( order.id ) );
                    return 0;
                 });
            }
            else {
                Swal.fire({
                    title: 'Error',
                    text: `No se pudo actualizar el estado del pedido`,
                    icon: 'error',
                })
            }
        } catch ( error) {
            Swal.fire({
                title: 'Error',
                text: `No se pudo actualizar los estados de los pedidos`,
                icon: 'error',
            })
        }
    }
}

export const deleteOrder = (id) => {
    return async ( dispatch ) => { 
        try {
            const response = await fetchConToken(`orders/${ id }`, '', 'DELETE');
            
            const body = await response.json();
            
            if( body.ok ) {               
                dispatch( deleteOrderStore( id ) );                
            }
            else {
                Swal.fire({
                    title: 'Error',
                    text: `No se pudo borrar el pedido`,
                    icon: 'error',
                })
            }
        } catch ( error) {
            Swal.fire({
                title: 'Error',
                text: `No se pudo borrar el pedido`,
                icon: 'error',
            })
        }
    }
}

export const updateOrder = (order) => { 

};

export const updateStateOrderStore = ( order, id ) => ({
    type: types.ordersUpdateState,
    payload: {
        id,
        order 
    }
});

export const getAllOrdersStore = ( orders ) => ({
    type: types.ordersGetAll,
    payload: {
        orders,
    }
})

export const updateOrderSelect = ( order ) => ({
    type: types.ordersSelectUpdate,
    payload: {
        order 
    }
});

export const clearOrders = () => ({
    type: types.ordersClear
});

export const ordersPrintStore = ( orders ) => ({
    type: types.ordersPrint,
    payload: {
        orders
    }
});

export const setOrdersInMap = ( order ) => {
    return {
        type: types.orderInMap,
        payload: { order }
    }
}

export const clearOneOrdersInMap = (id) => {
    return {    
        type: types.orderInMapClear,
        payload: { id }
    }
}

export const clearOrdersInMap = () => {
    return {
        type: types.ordersInMapClear
    }
}

export const deleteOrderStore = (id) => {
    return {
        type: types.orderDelete,
        payload: { id }
    }
}