import moment from 'moment'
import React, { useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ordersPrintStore, updateOrderSelect } from '../../../actions/orderActions';
import { OrderDetail } from './OrderDetail';

export const OrderSelects = ({ show, onHide, handleSelectListado, handleSelectListados, selected, setSelect}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [ print, setPrint ] = useState(false);
    const [ showDetailOrder, setShowDetailOrder] = useState(false);

    // Cuando cierra el modal de detalle
    const handleShowDetailClose = () => { 
        dispatch( updateOrderSelect( null ));
        setPrint(false);
        setShowDetailOrder(false);  
    }

    // Cuando muestra el modal de detalle
    const handleShowDetail = ( order ) => { 
        dispatch( updateOrderSelect( order ));
        setShowDetailOrder(true);     
        // setPdfViewStatus(true);
    }

    const handleWhatsapp = ( order ) => {
        
        const num = order.user.whatsapp[0].num;
        const text = `Hola, tu pedido ${ String(order.id).padStart(6, '0') } fue ENVIADO
        %0A*Pedido*%0A
        %0A${ order.productOrder.map( product => `${product.quantity} x ${product.product.name} - $${ product.price }` ).join('%0A') }
        %0A*Precio*%0A
        $${ order.priceTotal }    
        `;

        window.open(`https://api.whatsapp.com/send?phone=+549${ num }&text= ${ text }`, '_blank');
    }

     // Mandar a imprimir pedido
     const handlePrint = (order) => { 
        dispatch( updateOrderSelect( order ));
        setShowDetailOrder(true);
        setPrint(true);
    }

    const handleSends = () => {
        handleSelectListados('ENVIADO', selected, setSelect);
        onHide();
    }

    const handleSend = ( order ) => {
        handleSelectListado( 'ENVIADO', order.id);
        //setRedibuja(!redibuja);
        selected.find( o => o.id === order.id ).status = 'ENVIADO';
    }

    const handleImprimirAll = () => { 
        dispatch( ordersPrintStore(selected) );
        //setShowPrintOrders( !showDetailOrder );
        navigate('/orders-print', { state: { status: true }});
    }

  return (
    <Modal show={show} onHide={onHide} size="xl" className={(showDetailOrder)? 'blur no-print' : 'no-print'}>
        <Modal.Header closeButton className='no-print'>
            <Modal.Title>Pedidos Seleccionados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='row justify-content-between'>
                <div className='col-md-2'>
                    Pedidos a enviados
                    <div className="components__small-box pointer ml-3 d-flex justify-content-center align-items-center" 
                        onClick={ handleSends }>
                        <i className="fas fa-file-import fa-3x"></i>
                    </div>
                </div>
                <div className="col-md-2 components__small-box pointer ms-3 d-flex justify-content-center align-items-center" onClick={handleImprimirAll}>
                    <i className="fas fa-print fa-3x"></i>                                
                </div>
            </div>


            {/* TABLA */}

            <Table striped bordered={false} hover>
                    <thead>
                        <tr className="components__table-header">
                            <th>Nº</th>                            
                            <th style = {{ width: "50%"}}>Nombre Cliente</th>
                            <th>Fecha</th>
                            <th>Total</th>
                            <th>Estado</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            (selected?.length > 0) 
                                ? selected.map( (order, i) => 
                                (<tr key={i}>
                                    <td>
                                        { String(order.id).padStart(6, '0') }
                                    </td>                                    
                                    <td>
                                        <h6>{ order.user.nameMarkets }</h6>

                                        {/* TABLA DE SUBTITULO */}

                                        <Table className="mb-0 table-b table-borderless">
                                            <thead>
                                                <tr className='order__table-head-tr'>
                                                    <th className="mb-0 mt-0 pb-0 pt-0 order__table-head w10"><small>Cantidad</small></th>
                                                    <th className="mb-0 mt-0 pb-0 pt-0 order__table-head"><small>Producto</small></th>
                                                    <th className="mb-0 mt-0 pb-0 pt-0 order__table-head w10"><small>Precio</small></th>
                                                    <th className="mb-0 mt-0 pb-0 pt-0 order__table-head w10"><small>Total</small></th>
                                                    <th className="mb-0 mt-0 pb-0 pt-0 order__table-head w10"><small>Facturado</small></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                        {
                                            order.productOrder.map( (product, i) => (
                                                <tr key={i}>
                                                    <td className="mb-0 mt-0 pb-0 pt-0"><small>{ product.quantity }</small></td>
                                                    <td className="mb-0 mt-0 pb-0 pt-0"><small>{ product.product.name }</small></td>
                                                    <td className="mb-0 mt-0 pb-0 pt-0"><small>{ product.price }</small></td>
                                                    <td className="mb-0 mt-0 pb-0 pt-0"><small>{ product.price * product.quantity}</small></td>
                                                    <td className="mb-0 mt-0 pb-0 pt-0"><small>{ product.bill }</small></td>
                                                </tr>
                                            ))
                                        }
                                            </tbody>
                                        </Table>    
                                    </td>
                                    <td>{ moment(order.createdAt).format('DD/MM/YYYY') }</td>
                                    <td>{ order.priceTotal }</td>
                                    <td className='text-center'>
                                        <div className ="row d-flex justify-content-center">
                                            {
                                                (order.status === 'PENDIENTE') 
                                                ?   <Button onClick={ () => handleSend(order) }>
                                                        Poner como Envíado
                                                    </Button>
                                                :   <h5 style={{ color: 'white', backgroundColor: '#445384', width: '10em'}}>Enviado</h5>
                                            }
                                        </div>
                                        <div className ="row mt-1">
                                            <i 
                                                className='offset-md-2 col-md-2 fas fa-eye fa-2x order__action-order pointer' 
                                                onClick = { () => handleShowDetail( order ) }></i>

                                            <i 
                                                className="col-md-2 fa-brands fa-whatsapp order__action-order fa-2x pointer ms-3"
                                                onClick = { () => handleWhatsapp( order ) }></i>
                                                
                                            <i 
                                                className="col-md-2 fas fa-print fa-2x order__action-order pointer ms-3"
                                                onClick = { () => handlePrint( order ) }></i>
                                        </div>
                                    </td>
                                    
                                </tr>))
                                : (<></>)
                        }
                    </tbody>
            </Table>

            <OrderDetail show={showDetailOrder} onHide={handleShowDetailClose} print={ print }/>  
        </Modal.Body>
    </Modal>
  )
}
