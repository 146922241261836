import React from 'react'

export const ListNovedades = ({ title, subtitle, image }) => {

  return (
    <>
        <div className="list__main pointer">
          <div className="list__movie-img pointer">            
            {( image.includes('.mp4')) 
                ? <video className="list__backimg" autoPlay>
                    <source src={image} type="video/mp4" />
                  </video>
                : (<div className="list__backimg" style={{ 
                                backgroundImage: `url("${ image }")`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                }}>

                </div>)
            }
            </div>
            <div className='list__init-card-novedades'>
                <h6 className = "mt-2">{ title }</h6>    
                <p>{ subtitle }</p>   
            </div>            
        </div>
    </>
  )
}