import React from 'react'
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeError } from '../../../actions/uiActions';
import { setError } from './../../../actions/uiActions';
import { Modal, Form, FormControl, InputGroup, Button } from 'react-bootstrap';
import { updateProduct } from './../../../actions/productsActions';

export const MaxMinStockProduct = ({show, onHide }) => {
  
    const [ stockMin, setStockMin ] = useState(0); 
    const [ stockMax, setStockMax ] = useState(0); 
    const { productSelect } = useSelector( state => state.products );

    const dispatch = useDispatch();
    const { isLoading, error }= useSelector( state => state.ui ); 
    
    useEffect( () => {
        setStockMax( () => productSelect?.maxStock );
        setStockMin( () => productSelect?.minStock );
    }, [ productSelect ])

    const handleSave = () => {                 
        if( isFormValid()) {
            productSelect.minStock = stockMin;
            productSelect.maxStock = stockMax;
            dispatch( updateProduct( productSelect.id, productSelect, onHide ) );
        }
    }

    const isFormValid = () => { 
    
        if( stockMin <= 0 ){            
            dispatch(setError( 'El stock min debe ser un campo númerico mayor a 0' ));
            return false;
        } 
        if( stockMax <= 0 ){            
            dispatch(setError( 'El stock max debe ser un campo númerico mayor a 0' ));
            return false;
        } 
        if( stockMin > stockMax ){            
            dispatch(setError( 'El stock min no puede ser mayor que el stock max' ));
            return false;
        } 
        
        dispatch(removeError());
        return true;
    }

  return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Stock Ideal y Mínimo de { productSelect?.name }</Modal.Title>
        </Modal.Header>


        <Modal.Body>
            {
                error && 
                (<div className='auth__alert-error'>
                    { error }
                </div>)
            }
            <Form className='auth__form'>                
                <hr/>
                <InputGroup className="mb-3">
                    <small className="products__add-title">Stock Mínimo: </small>
                    <InputGroup.Text id="basic-addon2">
                        <i className="fa-solid fa-minus"></i>
                    </InputGroup.Text>
                    <FormControl
                        placeholder="Cantidad"
                        aria-label="Cantidad-Producto"
                        aria-describedby="basic-addon2"      
                        className='auth__input-placeholder'
                        type="number"
                        name="stockMin"
                        value={ stockMin || '' }
                        onChange={ ({ target }) => setStockMin( target.value ) }                                    
                    />
                </InputGroup> 
                <InputGroup className="mb-3">
                    <small className="products__add-title">Stock Ideal:   </small>
                    <InputGroup.Text id="basic-addon2">
                        <i className="fa-solid fa-plus"></i>
                    </InputGroup.Text>
                    <FormControl
                        placeholder="Cantidad"
                        aria-label="Cantidad-Producto"
                        aria-describedby="basic-addon2"      
                        className='auth__input-placeholder'
                        type="number"
                        name="stockMax"
                        value={ stockMax || '' }
                        onChange={ ({ target }) => setStockMax( target.value ) }                                    
                    />
                </InputGroup> 
               
            </Form>
        </Modal.Body>


        <Modal.Footer>
          <Button variant="info" onClick={onHide} tabIndex={9}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleSave} tabIndex={8} type="submit" disabled={isLoading}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
  )
}
