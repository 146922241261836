
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LoginScreen } from '../auth/LoginScreen';
import { RegisterScreen } from '../auth/RegisterScreen';
import { useSelector } from 'react-redux';
import { ForgetPassword } from '../auth/ForgetPassword';
import { NewPassword } from '../auth/NewPassword';

export const AuthRouter = () => {

  const { isAuth } = useSelector( state => state.auth );
  if( isAuth ) {
    return <Navigate to="/productos" />
  }

  return (
      <Routes>
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/new-password/:token" element={<NewPassword />} />
          <Route index element={<LoginScreen />} />
      </Routes>
  )
}
