
const baseUrl = process.env.REACT_APP_URL_BACKEND;

export const fetchSinToken = ( endpoint, data, method = 'GET' ) => { 
    const url = `${ baseUrl }/${ endpoint }`;

    const dato = JSON.stringify( data );

    if( method === 'GET') {
        return fetch( url );
    } else {
        return fetch( url, {
            method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: dato,
        });
    }

}

export const fetchConToken = ( endpoint, data, method = 'GET' ) => { 
    const url = `${ baseUrl }/${ endpoint }`;        
    const token = 'Bearer ' + localStorage.getItem( 'token' ) || '';
    const dato = JSON.stringify( data );
    if( method === 'GET') {
        return fetch( url, {
            method,
            headers: {
                'Authorization': token,
            }
        });
    } else if ( method === 'DELETE' ) {
        return fetch( url, {
            method,
            headers: {
                'Authorization': token,
            },
            body: dato,
        });
    } else {
        return fetch( url, {
            method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: dato,
        });
    }
}

export const fetchConResetToken = ( endpoint, data, resetToken, method = 'GET' ) => { 
    const url = `${ baseUrl }/${ endpoint }`;    
    const dato = JSON.stringify( data );
    
    if( method === 'GET') {
        return fetch( url, {
            method,
            headers: {
                'reset': resetToken,
            }
        });
    } else if ( method === 'DELETE' ) {
        return fetch( url, {
            method,
            headers: {
                'reset': resetToken,
            },
            body: dato,
        });
    } else {
        return fetch( url, {
            method,
            headers: {
                'Content-Type': 'application/json',
                'reset': resetToken,
            },
            body: dato,
        });
    }
}

export const fetchConTokenImg = ( endpoint, data, method = 'PUT' ) => { 
    const url = `${ baseUrl }/${ endpoint }`;
    const token = 'Bearer ' + localStorage.getItem( 'token' ) || '';
    const formData = new FormData();
    formData.append( 'file', data );

    return fetch( url, { method, headers: { 'Authorization': token }, body: formData });
}