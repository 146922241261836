
import { types } from '../types/types';
const initialState = {
    users: [],
    userSelect: null,
 }

export const userReducer = ( state = initialState, action ) => {
    
    switch( action.type ) {
        case types.usersGetAll:
            return {
                ...state,
                users: action.payload.users
            }
        case types.userUpdateStatus:
            return {
                ...state,
                users: state.users.map( u => (u.id === action.payload.user.id)
                    ? action.payload.user
                    : u )
            }
        case types.userUpdateStatusAll:
            return {
                ...state,
                users: action.payload.users            
            }
        case types.userUpdateSelect:
            return {
                ...state,
                userSelect: action.payload.user
            }
        case types.userUpdate: 
            
            return { 
                ...state,
                users: state.users.map( u => (u.id === action.payload.id)? action.payload.user : u ),
            }
        case types.userDelete: 
            return {
                ...state,
                users: state.users.filter( u => u.id !== action.payload.id )
            }
        case types.userDeleteWsp: 
            return {
                ...state,
                users: state.users.map( u => (u.id === action.payload.userId)
                            ? { ...u, whatsapp: u.whatsapp.filter( wsp => wsp.id !== action.payload.id ) } 
                            : u )
            }        
        default:
            return state;
    }
}